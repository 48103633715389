import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Modal, message } from "antd";
import { AddAnnual } from "../../actions/annualAdmin";
import Annual_list from "./annual_List";

const AnnualAdmin = () => {
  const dispatch = useDispatch();
  const { successMessage, errorMessage } = useSelector((state) => state.annual);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
    form.resetFields();
  };

  const handleAdd = async () => {
    try {
      const values = await form.validateFields();
      await dispatch(AddAnnual(values));
      message.success("Annual data added successfully");
      setIsAddModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Error adding annual data:", error);
      message.error("Failed to add annual data");
    }
  };

  return (
    <>
    {successMessage && <div className="alert alert-success">{successMessage}</div>}
    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      <div className="d-flex justify-content-center align-items-center">
        
        <div className="w-50" style={{ backgroundColor: "" }}>
          <div style={{ margin: "" }}>
            <h2 className="text-center mb-4">Add Annual Data</h2>
            
            <div className="text-center">
             
            </div>
          </div>
        </div>
      </div>
      
      <Modal
        title="Add Annual Data"
        visible={isAddModalVisible}
        onCancel={handleAddCancel}
        footer={[
          <Button key="cancel" onClick={handleAddCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleAdd} loading={false}>
            Add
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="year"
            label="Year"
            rules={[{ required: true, message: "Please input the year!" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="target"
            label="Target"
            rules={[{ required: true, message: "Please input the target!" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="planted"
            label="Planted"
            rules={[{ required: true, message: "Please input the planted amount!" }]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
      <Button type="primary" onClick={showAddModal}>
                Add Data
              </Button>
      <div>
        <Annual_list />
      </div>
    </>
  );
};

export default AnnualAdmin;
