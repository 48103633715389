import axios from "axios";

export const SET_VALUE = 'SET_VALUE';
export const ADD_SUCCESS = 'ADD_SUCCESS';
export const ADD_ERROR = 'ADD_ERROR';
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';

export const SET_NAME = 'SET_NAME';
export const SET_ID = 'SET_ID';
export const SET_USERS = 'SET_USERS';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_SHOW_DATA = 'SET_SHOW_DATA';
export const SET_CLICKED_DATA = 'SET_CLICKED_DATA';
export const SET_EDITING_USER = 'SET_EDITING_USER';
export const SET_VISIBLE = 'SET_VISIBLE';
export const ADD_ANNUAL_SUCCESS = 'ADD_ANNUAL_SUCCESS';
export const ADD_ANNUAL_DATA_ERROR = 'ADD_ANNUAL_DATA_ERROR';

export const FETCH_IMAGES_REQUEST = 'FETCH_IMAGES_REQUEST';
export const FETCH_IMAGES_SUCCESS = 'FETCH_IMAGES_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';

export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS';
export const UPDATE_VIDEO_SUCCESS = 'UPDATE_VIDEO_SUCCESS';

export const ADD_IMAGE_SUCCESS = 'ADD_IMAGE_SUCCESS';
export const ADD_IMAGE_ERROR = 'ADD_IMAGE_ERROR';


const Server_URL = process.env.REACT_APP_SERVER_URL;
export const deleteImageSuccess = (imageId) => ({
  type: DELETE_IMAGE_SUCCESS,
  payload: imageId,
});
export const deleteVideoSuccess = (videoId) => ({
  type: DELETE_VIDEO_SUCCESS,
  payload: videoId,
});

export const deleteImage = (imageId) => async (dispatch) => {
  // console.log("ii",imageId);
  
  try {
    await axios.delete(`${Server_URL}/media/${imageId}`);
    dispatch(deleteImageSuccess(imageId));  
  } catch (error) {
    console.error('Error deleting Image:', error);
  }
};
export const deleteVideo = (videoId) => async (dispatch) => {
  // console.log("ii",imageId);
  
  try {
    await axios.delete(`${Server_URL}/mediaVideo/${videoId}`);
    dispatch(deleteVideoSuccess(videoId));  
  } catch (error) {
    console.error('Error deleting Video:', error);
  }
};

export const fetchUsersSuccess = (users) => ({
  type: FETCH_IMAGES_SUCCESS,
  payload: users,
});
export const fetchImage = () => async (dispatch) => {
  dispatch({ type: FETCH_IMAGES_REQUEST });
  try {
    const response = await axios.get(`${Server_URL}/media`);
    dispatch(fetchUsersSuccess(response.data));

  } catch (error) {
    dispatch({ type: FETCH_FAILURE, error });
  }
};
export const fetchVideo = () => async (dispatch) => {
  dispatch({ type: FETCH_IMAGES_REQUEST });
  try {
    const response = await axios.get(`${Server_URL}/mediaVideo`);
    dispatch(fetchUsersSuccess(response.data));

  } catch (error) {
    dispatch({ type: FETCH_FAILURE, error });
  }
};
export const addSuccess = (message) => ({
  type: ADD_SUCCESS,
  payload: message,
});
export const addError = (message) => ({
  type: ADD_ERROR,
  payload: message,
});

  export const setValue = (value) => ({
      type: SET_VALUE,
      payload: value,
  });
  export const updateImageSuccess = (user,id) => ({
    type: UPDATE_IMAGE_SUCCESS,
    payload: { id, user } });
    export const updateVideoSuccess = (user,id) => ({
      type: UPDATE_VIDEO_SUCCESS,
      payload: { id, user } });
  export const updateImageTitle = (user) => async (dispatch) => {
    // console.log("ooo",user);
    
    try {
      const response = await axios.put(`${Server_URL}/media/${user.id}`, user);
      
      dispatch(updateImageSuccess(response.data));
    } catch (error) {
      console.error('Error updating region:', error);
    }
  };
  export const updateVideoTitle = (user) => async (dispatch) => {
    // console.log("ooo",user);
    
    try {
      const response = await axios.put(`${Server_URL}/mediaVideo/${user.id}`, user);
      
      dispatch(updateVideoSuccess(response.data));
    } catch (error) {
      console.error('Error updating region:', error);
    }
  };
  // export const addImage = (userData) => async (dispatch) => {
  //     try {
  //       //   const formData = new FormData();
  //       //   formData.append('region', userData.region);
  //       //   formData.append('site', userData.site);
  //       //   formData.append('image', userData.image);
  //       //   for (let pair of formData.entries()) {
  //       //     console.log(`${pair[0]}: ${pair[1]}`);
  //       //     console.log(userData.image);

  //       // }
  //         const res = await axios.post(`${Server_URL}/media`, userData);
  //         dispatch({
  //             type: ADD_SUCCESS,
  //             payload: "Image added successfully!",
  //         });
  //     } catch (err) {
  //         dispatch({
  //             type: ADD_ERROR,
  //             payload: "Invalid or empty data provided",
  //         });
  //         console.error("Image add error!", err);
  //     }
  // };
  export const addUserError = (message) => ({
    type: ADD_IMAGE_ERROR,
    payload: message,
  });
  export const addUserSuccess = (message) => ({
    type: ADD_IMAGE_SUCCESS,
    payload: message,
  });
  
  export const addImage = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`${Server_URL}/media`, formData, {

            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        dispatch(addUserSuccess("Image uploaded successfully!"));
    } catch (err) {
        dispatch(addUserError("Failed to upload image"));
        console.error("Image upload error:", err);
    }
};

export const addVideo = (formData) => async (dispatch) => {
  try {
      const res = await axios.post(`${Server_URL}/mediaVideo`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      });

      dispatch(addUserSuccess("Video uploaded successfully!"));
  } catch (err) {
      dispatch(addUserError("Failed to upload video"));
      console.error("Video upload error:", err);
  }
};
