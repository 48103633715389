import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto"; // Import Chart.js
import axios from "axios";
import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import Map from "ol/Map";
import View from "ol/View";
import target from "../../asset/images/target.png";
import planted_1 from "../../asset/images/planted_1.png";
import seed from "../../asset/images/green_legacy_logo.png";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import { XYZ } from "ol/source";
import LayerSwitcher from "ol-layerswitcher";
import { FullScreen, defaults as defaultControls } from "ol/control.js";
import TileWMS from "ol/source/TileWMS";
import "ol/ol.css";
import ImageWMS from "ol/source/ImageWMS.js";
import { RegionData } from "../../data/RegionData";
import { OneDayData } from "../../data/onedayData";

const mapURL = process.env.REACT_APP_MAP_URL;
const Server_URL = process.env.REACT_APP_SERVER_URL;

const wmsSource = new ImageWMS({
  url: `${mapURL}`,
  params: { LAYERS: "Green_Legacy:one day planted" },
  ratio: 1,
  serverType: "geoserver",
});
const OneDayMap = () => {
  const [metadatas, setMetadatas] = useState([]);
  const [chartInstance, setChartInstance] = useState(null);
  const chartRef = useRef(null);
  const mapRef = useRef(null);
  useEffect(() => {
    if (!mapRef.current) return;

    const map = new Map({
      controls: defaultControls().extend([new FullScreen()]),

      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
          title: "OSM",
          visible: false,
        }),
        new TileLayer({
          source: new XYZ({
            url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          }),
          title: "Satelite",
          visible: true,
          title: "satilite",
        }),
        new TileLayer({
          source: new TileWMS({
            url: `${mapURL}`,
            params: { LAYERS: "Green_Legacy:ethiopia_new_2016_all_regions", TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: "Ethiopia",
          title: "Ethiopia",
        }),
        new TileLayer({
          source: new TileWMS({
            url: `${mapURL}`,
            params: { LAYERS: "Green_Legacy:one day planted", TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: "One Day Planted Site",
          title: "One Day Planted Site",
        }),
      ],
      view: new View({
        center: fromLonLat([38.972261469308, 8.748207216169536]),
        zoom: 6,
      }),
    });

    var layerSwitcher = new LayerSwitcher({
      reverse: true,
      activationMode: "click",
      groupSelectStyle: "children",
    });
    map.addControl(layerSwitcher);

    // var full_sc = new FullScreen({ label: 'F' });
    // map.addControl(full_sc);
    map.on("singleclick", function (evt) {
      const features = map.getFeaturesAtPixel(evt.pixel);
      const clickedCoordinate = evt.coordinate;
      clickedFeature(evt, map);
      map.getView().animate({
        center: clickedCoordinate,
        // zoom: 12, // Adjust zoom level as needed
        duration: 500 // Animation duration in milliseconds
      });
    });

    return () => map.dispose();
  }, []);
  const tt=OneDayData[0].title
  function clickedFeature(evt, map) {
    const viewResolution = map.getView().getResolution();
    const viewProjection = map.getView().getProjection();
    const coordinate = evt.coordinate;
    const url = wmsSource.getFeatureInfoUrl(
      coordinate,
      viewResolution,
      viewProjection,
      {
        INFO_FORMAT: "application/json",
        FEATURE_COUNT: 2,
      }
    );

    if (url) {
      fetch(url)
        .then((response) => response.json())
        .then(function (json) {
          // Parse JSON response
          const country = {};
          const zone = {};

          const region = {};
          const woreda = {};
          const kebele = {};
          const site_name = {};
          const area_ha = {};
          const seedling = {};
          const participan = {};
          const type = {};
          


          const id = {};
          console.log("ooooooo",json);
          country[""] = "Green Legacy Oneday Plantation Site";
          region[""] = json["features"][0]["properties"]["region"];
          zone[""] = json["features"][0]["properties"]["zone"];
          woreda[""] = json["features"][0]["properties"]["woreda"];
          kebele[""] = json["features"][0]["properties"]["kebele"];
          site_name[""] = json["features"][0]["properties"]["site_name"];
          area_ha[""] = json["features"][0]["properties"]["area_ha"];
          seedling[""] = json["features"][0]["properties"]["seedling"];
          participan[""] = json["features"][0]["properties"]["participan"];
          type[""] = json["features"][0]["properties"]["type"];


          const years = [""];
          const response1 = axios
            // .get(`${Server_URL}/regional_data/${id["id"]}`)
            // .then((response) => {
            //   years.forEach((year) => {
            //     const data_2019 = response.data[0];
              
            //     //2019
            //     document.getElementById("container_clicked_map-target-years_2019").innerHTML ='<b style="background-color:green;color:white;font-size:18px"> ' +data_2019["year"] +"</b>";
            //     document.getElementById("container_clicked_map-target_2019").innerHTML = "<b> Total Target:</b>   " + data_2019["target"];
            //     document.getElementById("container_clicked_map-planted_2019" ).innerHTML = "<b> Total Planted:</b>   " +data_2019["planted"];
            //     document.getElementById("container_clicked_map-area_covarage_2019" ).innerHTML = "Area Covarage in hectare:";
            //     document.getElementById( "container_clicked_map-achieved_2019").innerHTML = "Achieved in % :";
            //     });
            // })
            // .catch((error) => {
            //   console.error("Error fetching data:", error);
            // });

          //   <div id="container_clicked_map-title"></div>        
          //   <div id="container_clicked_map-region" className="container_list"   ></div>
          //   <div id="container_clicked_map-zone" className="container_list"></div>
          //   <div id="container_clicked_map-wereda"></div>
          //  <div id="container_clicked_map-kebele" ></div>
          //  <div id="container_clicked_map-site_name" ></div>
          //  <div id="container_clicked_map-area_ha" ></div>
          const container = document.getElementById( "container_clicked_map-region"
          );
          const country_name = country[""];
          const region_name = region[""];

          const zone_name = zone[""];
          const wereda_name = woreda[""];
          const kebele_name = kebele[""];
          const site_name_name = site_name[""];
          const area_ha_name = area_ha[""];

          const seedling_name = seedling[""];
          const participan_name = participan[""];
          const type_name = type[""];



          document.getElementById("container_clicked_map-title").innerHTML =  " " +  country_name + '</b>';
          // document.getElementById("container_clicked_map-region").innerHTML =  " " +  country_name + '</b>';
         
          document.getElementById("container_clicked_map-region").innerHTML = "<b> Region:</b>  " + '<span style="background-color:#209540;color:white;">'+ region_name + '</b>';
          document.getElementById("container_clicked_map-zone").innerHTML = "<b> Zone:</b>  " +  zone_name + '</b>';
          // document.getElementById("container_clicked_map-woreda").innerHTML = "<b> Wereda:</b>  " + wereda_name + '</b>';
          document.getElementById("container_clicked_map-kebele").innerHTML = "<b> Kebele:</b>  "  + kebele_name + '</b>';
          document.getElementById("container_clicked_map-site_name").innerHTML = "<b> Site Name:</b>  " + site_name_name + '</b>';
          document.getElementById("container_clicked_map-area").innerHTML = "<b> Area Coverage:</b>   " + area_ha_name + '</b>';

          document.getElementById("container_clicked_map-seedling").innerHTML = "<b> Seedling:</b>  "  + seedling_name + '</b>';
          document.getElementById("container_clicked_map-participan").innerHTML = "<b> participant:</b>  " + participan_name + '</b>';
          document.getElementById("container_clicked_map-type").innerHTML = "<b> Seedling Type:</b>   "  + type_name + '</b>';

       
       
       
        })
        .catch((err) => {
          document.getElementById("container_clicked_map").style.display = "none";
          // console.log(err);
        });
    }

    var modal = document.getElementById("container_clicked_map");
    modal.style.display = "block";
    document.getElementById("container_clicked_map").style.width = "420px";
    document.getElementById("container_clicked_map-title").style.width = "420px";
    document.getElementById("close-icon1").style.display ="inline";
    window.onclick = function (event) {
      var modal = document.getElementById("container_clicked_map");
      if (event.target == modal) {
        modal.style.display = "none";
      }
    };
  }
  function container_clicked_map() {
    document.getElementById("container_clicked_map").style.display = "none";
    document.getElementById("container_clicked_map-title").style.width = "0";
  }
  
    


  return (
    <>
       
       
    </>
  );
};

export default OneDayMap;
