import axios from "axios";

// Action types
export const SET_VALUE = 'SET_VALUE';
export const SET_NAME = 'SET_NAME';
export const SET_ID = 'SET_ID';
export const SET_USERS = 'SET_USERS';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_SHOW_DATA = 'SET_SHOW_DATA';
export const SET_CLICKED_DATA = 'SET_CLICKED_DATA';
export const SET_EDITING_USER = 'SET_EDITING_USER';
export const SET_VISIBLE = 'SET_VISIBLE';
export const ADD_OneDay_SUCCESS = 'ADD_OneDay_SUCCESS';
export const ADD_OneDay_DATA_ERROR = 'ADD_OneDay_DATA_ERROR';

export const FETCH_ONEDAY_REQUEST = 'FETCH_ONEDAY_REQUEST';
export const FETCH_ONEDAY_SUCCESS = 'FETCH_ONEDAY_SUCCESS';
export const FETCH_ONEDAY_FAILURE = 'FETCH_ONEDAY_FAILURE';
export const DELETE_ONEDAY_SUCCESS = 'DELETE_ONEDAY_SUCCESS';
export const UPDATE_ONEDAY_SUCCESS = 'UPDATE_ONEDAY_SUCCESS';
const Server_URL = process.env.REACT_APP_SERVER_URL;


// Action creators
export const fetchUsersRequest = () => ({
  type: FETCH_ONEDAY_REQUEST,
  type: FETCH_ONEDAY_REQUEST,
});

export const fetchUsersSuccess = (oneDay) => ({
  type: FETCH_ONEDAY_SUCCESS,
  type: FETCH_ONEDAY_SUCCESS,
  payload: oneDay,
});

export const fetchUsersFailure = (error) => ({
  type: FETCH_ONEDAY_FAILURE,
  type: FETCH_ONEDAY_FAILURE,
  payload: error,
});

export const deleteUserSuccess = (userId) => ({
  type: DELETE_ONEDAY_SUCCESS,
  type: DELETE_ONEDAY_SUCCESS,
  payload: userId,
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_ONEDAY_SUCCESS,
  type: UPDATE_ONEDAY_SUCCESS,
  payload: user,
});

// Thunks
export const fetchOneDayData = () => async (dispatch) => {
  dispatch(fetchUsersRequest());
  try {
    const response = await axios.get(`${Server_URL}/oneDay`);
    dispatch(fetchUsersSuccess(response.data));
  } catch (error) {
    dispatch(fetchUsersFailure(error.message));
  }
};



export const setValue = (value) => ({
  type: SET_VALUE,
  payload: { value },
});
// export const setLoggedIn = (login) => ({
//     type: SET_LOGGED_IN,
//     payload: login,
//   });
// export const setName = (value) => ({
//   type: SET_NAME,
//   payload: value,
// });
// export const setUserType = (usertype) => ({
//     type: SET_USER_TYPE,
//     payload: usertype,
//   });
// export const setId = (id) => ({
//   type: SET_ID,
//   payload: id,
// });
// export const setUsers = (users) => ({
//   type: SET_USERS,
//   payload: users,
// });
// export const setShowData = (showData) => ({
//   type: SET_SHOW_DATA,
//   payload: showData,
// });
// export const setClickedData = (clickedData) => ({
//   type: SET_CLICKED_DATA,
//   payload: clickedData,
// });
// export const setEditingUser = (editingUser) => ({
//   type: SET_EDITING_USER,
//   payload: editingUser,
// });
// export const setVisible = (visible) => ({
//   type: SET_VISIBLE,
//   payload: visible,
// });
export const addUserSuccess = (data) => ({
    type: ADD_OneDay_SUCCESS,
    payload: data,
  });
export const addUserError = (message) => ({
    type: ADD_OneDay_DATA_ERROR,
    payload: message,
  });

  export const UpdateOneDay = (value) => async (dispatch) => {
    // Validate input
    if (!value.planted) {
      console.error("Invalid or empty data provided:", value);
      dispatch(addUserError("Invalid or empty data provided"));
      return;
    }
  
    try {
      const res = await axios.put(`${Server_URL}/oneDay`, value);
      // console.log("Response:", res);
      dispatch(addUserSuccess(value, "Data updated successfully!"));
    } catch (err) {
      console.error("Error:", err);
      alert("Error updating data!");
    }
  };
  

// export const fetchToken = () => async (dispatch) => {
//   const token = localStorage.getItem("token");

//   if (token) {
//     try {
//       const response = await axios.get(`${SERVER_URL}/verify`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       const { valid, fname, id } = response.data;

//       if (valid && fname && id) {
//         dispatch(setName(fname));
//         dispatch(setId(id));
//       } else {
//         window.location.href = "/login";
//       }
//     } catch (error) {
//       console.error("Error verifying token:", error);
//       dispatch(addUserSuccess("User added successfully!"));
//       window.location.href = "/login";
//     }
//   } else {
//     window.location.href = "/login";
//   }
// };
