import axios from "axios";

// Action types
export const SET_VALUE = 'SET_VALUE';
export const SET_Participation = 'SET_USERS';
export const SET_SHOW_DATA = 'SET_SHOW_DATA';
export const ADD_Participation_SUCCESS = 'ADD_ANNUAL_SUCCESS';
export const ADD_Participation_ERROR = 'ADD_ANNUAL_DATA_ERROR';

export const FETCH_Participation_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_Participation_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_Participation_FAILURE = 'FETCH_USERS_FAILURE';

const Server_URL = process.env.REACT_APP_SERVER_URL;


// Action creators
export const fetchParticipationRequest = () => ({
  type: FETCH_Participation_REQUEST,
});

export const fetchParticipationSuccess = (participant) => ({
  type: FETCH_Participation_SUCCESS,
  payload: participant,
});

export const fetchParticipationFailure = (error) => ({
  type: FETCH_Participation_FAILURE,
  payload: error,
});





// Thunks
export const fetchParticipation = () => async (dispatch) => {
  dispatch(fetchParticipationRequest());
  try {
    const response = await axios.get(`${Server_URL}/participation`);
    dispatch(fetchParticipationSuccess(response.data));
  } catch (error) {
    dispatch(fetchParticipationFailure(error.message));
  }
};




export const setValue = (name, value) => ({
  type: SET_VALUE,
  payload: { name, value },
});


export const setShowData = (showData) => ({
  type: SET_SHOW_DATA,
  payload: showData,
});


export const addParticipationSuccess = (message) => ({
    type: ADD_Participation_SUCCESS,
    payload: message,
  });
export const addParticipationError = (message) => ({
    type: ADD_Participation_ERROR,
    payload: message,
  });

  export const AddParticipation = (value) => async (dispatch) => {
    // Validate input
    if (!value || !value.male || !value.female ) {
      // console.log(value);
      console.error("Invalid or empty Participation provided:", value);
      dispatch(addParticipationError("Invalid or empty Participation provided"));
      return;
    }
  
    try {
      const res = await axios.put(`${Server_URL}/participation/`, value);
      // console.log("Response:", res);
      dispatch(addParticipationSuccess("Data added successfully!"));
    } catch (err) {
      console.error("Error:", err);
      alert("Error adding data!");
    }
  };

// export const fetchToken = () => async (dispatch) => {
//   const token = localStorage.getItem("token");

//   if (token) {
//     try {
//       const response = await axios.get(`${server_URL}/verify`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       const { valid, fname, id } = response.data;

//       if (valid && fname && id) {
//         dispatch(setName(fname));
//         dispatch(setId(id));
//       } else {
//         window.location.href = "/login";
//       }
//     } catch (error) {
//       console.error("Error verifying token:", error);
//       dispatch(addUserSuccess("User added successfully!"));
//       window.location.href = "/login";
//     }
//   } else {
//     window.location.href = "/login";
//   }
// };
