import React, { useState } from 'react';
import axios from 'axios';
import { AddOneDay, UpdateOneDay } from '../../actions/oneDay';
import { useDispatch } from 'react-redux';
import { Button, Input, Modal } from 'antd';
import OneDayList from './oneDayList';

const NumberForm = () => {
  const [inputNumber, setInputNumber] = useState('');
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    planted: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleChange = (e) => {
    setInputNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const handleConfirm = async () => {
    setIsModalVisible(false);
    dispatch(UpdateOneDay({ planted: value.planted, year: '2024' }));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex">
            <div className="w-100" style={{ backgroundColor: "white" }}>
              <div style={{ margin: "50px" }}>
                <h2 className="text-center mb-4">Add One Day Data</h2>
                <div className="form-group">
                  <p>Planted</p>
                  <Input
                    type="number"
                    className="form-control form-control-lg"
                    name="planted"
                    value={value.planted}
                    onChange={(e) => setValue({ ...value, planted: e.target.value })}
                  />
                </div>
                <div className="text-center">
                  <Button type="primary" onClick={handleSubmit}>
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <OneDayList />
        </div>
      </div>

      <Modal
        title="Confirm Action"
        visible={isModalVisible}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to add this data?</p>
      </Modal>
    </>
  );
};

export default NumberForm;
