

import userReducer from '../reducers/users/reducers';
import annualReducer from '../reducers/annualAdmin/reducer';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import regionReducer from '../reducers/regionalAdmin/reducer';
import authReducer from '../reducers/auth/reducer';
import OneDayReducer from '../reducers/oneDayreducer';
import OneDayLastHoureReducer from '../reducers/oneDayLastHourereducer';
import ImageReducer from '../reducers/media/reducer';
import participationReducer from '../reducers/participation/reducers';
import metadataReducer from '../reducers/metadataReducer';
import tokenReducer from '../reducers/tokenReducer';
import mediaReducer from '../reducers/media/reducer';

const rootReducer = combineReducers({
    user: userReducer,
    annual: annualReducer,
    region:regionReducer,
    authReducer:authReducer,
    oneDay:OneDayReducer,
    lastHoure:OneDayLastHoureReducer,
    media:ImageReducer,
    participation:participationReducer,
    metadata: metadataReducer,
    token:tokenReducer,
    media:mediaReducer

    
  });

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;