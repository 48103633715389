import axios from "axios";

// Action types



export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
const Server_URL = process.env.REACT_APP_SERVER_URL;



// Action creators
export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = (lastHoure) => ({
  type: FETCH_USERS_SUCCESS,
  payload: lastHoure,
});

export const fetchUsersFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  payload: error,
});





// Thunks

export const fetchOneDayDataLastHoure = () => async (dispatch) => {
    dispatch(fetchUsersRequest());
    try {
      const response = await axios.get(`${Server_URL}/oneDayLastHoure`);
      dispatch(fetchUsersSuccess(response.data));
    } catch (error) {
      dispatch(fetchUsersFailure(error.message));
    }
  };










  


