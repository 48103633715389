import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form, Input, Select, message } from "antd";
import { addRegionalData } from "../../actions/regionalAdmin";
import Region_list from "./region_List";

const RegionAdmin = () => {
  const dispatch = useDispatch();
  const { loading, error, successMessage, errorMessage } = useSelector((state) => state.region);
  const [form] = Form.useForm();
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
    form.resetFields();
  };

  const handleAdd = async () => {
    try {
      const values = await form.validateFields();
      await dispatch(addRegionalData(values));
      message.success("Region added successfully");
      setIsAddModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Error adding region:", error);
      message.error("Failed to add region");
    }
  };

  return (
    <>
      {successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}
            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}
      <div className="d-flex justify-content-center align-items-center">
        <div className="w-50" style={{ backgroundColor: "" }}>
          <div style={{ margin: "20px" }}>
            <h2 className="upload-form__title center" style={{ margin: "" }}>Upload Regional Data</h2>
          
           
          </div>
        </div>
      </div>
      <Button type="primary" onClick={showAddModal}>
              Add Data
            </Button>
      <Region_list />

      <Modal
        title="Add Regional Data"
        visible={isAddModalVisible}
        onCancel={handleAddCancel}
        footer={[
          <Button key="cancel" onClick={handleAddCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleAdd} loading={loading}>
            Add
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="region"
            label="Region"
            rules={[
              { required: true, message: "Please select the region!" },
            ]}
          >
            <Select placeholder="Select Region">
              <Select.Option value="Addis Ababa">Addis Ababa</Select.Option>
              <Select.Option value="Afar">Afar</Select.Option>
              <Select.Option value="Amhara">Amhara</Select.Option>
              <Select.Option value="Benshangule">Benshangule</Select.Option>
              <Select.Option value="Diredawa">Diredawa</Select.Option>
              <Select.Option value="Genbala">Genbala</Select.Option>
              <Select.Option value="Hareri">Hareri</Select.Option>
              <Select.Option value="Oromia">Oromia</Select.Option>
              <Select.Option value="Snnp">Snnp</Select.Option>
              <Select.Option value="Somalia">Somalia</Select.Option>
              <Select.Option value="Tigray">Tigray</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="year"
            label="Year"
            rules={[{ required: true, message: "Please input the year!" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="target"
            label="Target"
            rules={[{ required: true, message: "Please input the target number!" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="planted"
            label="Planted"
            rules={[{ required: true, message: "Please input the planted number!" }]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RegionAdmin;
