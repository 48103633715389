import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, setValue } from '../actions/auth';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Input, Button } from 'antd';
import Login_image from '../asset/images/login_side.png';
import Logo from '../asset/images/green_legacy_logo.png';
const Login = () => {
  const navigate = useNavigate();
  const { value, loading, error } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
console.log('bbbbbbbbb',error);

  const handleChange = (e) => {
    dispatch(setValue(e.target.name, e.target.value));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(login(value, navigate));
  };

  return (
    <div>
      <div className="row" style={{ backgroundColor: '' }}>
        <div className="col-lg-6 " style={{ marginTop: '50px' }}>
          <a href="/">
            <img src={Logo} className="loginImageLogo" alt="Logo" />
          </a>
          <a href="/">
            <img src={Login_image} className="loginImagePlaning" alt="Login Illustration" />
          </a>
        </div>
        <div className="col-lg-6">
          <div className="loginform">
            <div className="bg-white p-3 rounded login">
              <h3 className="center">Login</h3>
              
              {error && <div className="error" style={{ color: 'white', textAlign: 'center' }}>{error}</div>}
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <form onSubmit={handleLogin}>
                  <div className="mb-3">
                    <label htmlFor="email"><strong>Email</strong></label>
                    <Input
                      prefix={<UserOutlined />}
                      type="email"
                      id="email"
                      name="email"
                      value={value.email}
                      onChange={handleChange}
                      required
                      placeholder="Email"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password"><strong>Password</strong></label>
                    <Input
                      prefix={<LockOutlined />}
                      type="password"
                      id="password"
                      name="password"
                      value={value.password}
                      onChange={handleChange}
                      required
                      placeholder="Password"
                    />
                  </div>
                  <div className="center">
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Login
                    </Button>
                    <br />
                  </div>
                </form>
              </div>
              <div className="line line-sm"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
