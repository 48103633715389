import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import ReactDOM from "react-dom";

import "ol-layerswitcher/dist/ol-layerswitcher.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import { XYZ } from "ol/source";
import LayerSwitcher from "ol-layerswitcher";
import {
  ScaleLine,
  FullScreen,
  defaults as defaultControls,
} from "ol/control.js";

import TileWMS from "ol/source/TileWMS";
import "ol/ol.css";
import Metadata_pie_chart_2023 from "./metadata_pie_chart_2023";
import Metadata_pie_chart_2024 from "./metadata_pie_chart_2024";
import Metadata_pie_chart_2022 from "./metadata_pie_chart_2022";
import Metadata_pie_chart_2021 from "./metadata_pie_chart_2021";
import Metadata_pie_chart_2020 from "./metadata_pie_chart_2020";
import Metadata_pie_chart_2019 from "./metadata_pie_chart_2019";
import Map2024 from "../map2024";
import Table_data1 from "./table_data";

const Metadata_pie_chart = () => {
  const mapRef = useRef(null);
  const [showdata, setdata] = useState(false);
  const [clickedData, setClickedData] = useState(null);

  const [selectedYear, setSelectedYear] = useState(""); // State to manage the selected year


  const handleYearChange = (item) => {
    // console.log(item);
    let componentToRender;

    switch (item) {
      case "2024":
        componentToRender = <Metadata_pie_chart_2024 />;
        break;
      case "2023":
        componentToRender = <Metadata_pie_chart_2023 />;
        break;
      case "2022":
        componentToRender = <Metadata_pie_chart_2022 />;
        break;
      case "2021":
        componentToRender = <Metadata_pie_chart_2021 />;
        break;
      case "2020":
        componentToRender = <Metadata_pie_chart_2020 />;
        break;
      case "2019":
        componentToRender = <Metadata_pie_chart_2019 />;
        break;
    }

    ReactDOM.render(componentToRender, document.getElementById("chart_year"));
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-2">
          <div className="view_type_select">
            <h3>select Years</h3>

            <select
              style={{
                padding: "10px,50px",
                backgroundColor: "#fff ",
                color: "#000",
                width:"100%"
              }}
              defaultValue="2023"

              onChange={(e) => handleYearChange(e.target.value)}
            >
              {" "}
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            
            </select>
          </div>
        </div>
        <div className="col-lg-10" >
          <div id="chart_year"style={{width:"100%"}}>
            <Metadata_pie_chart_2023 />
       

          {showdata && <Metadata_pie_chart data={clickedData} />}
          </div>
        </div>
        {/* <div className="col-lg-4 ">
              <Map2024/>
        </div> */}
      </div>
      <Table_data1/>
    </>
  );
};

export default Metadata_pie_chart;
