

function Contact() {
  return (
    <>

<section id="map-overlay">
			<div className="container">

				<div className="row">
				
					<div className="contact-form-overlay col-md-8 offset-md-2 p-5">

						<div className="fancy-title title-border">
							<h3>Send us an Email</h3>
						</div>

						<div className="form-widget">

							<div className="form-result"></div>

					
							<form className="row mb-0" id="template-contactform" name="template-contactform" action="summit" method="post">

								<div className="col-md-6 form-group">
									<label for="template-contactform-name">Name <small>*</small></label>
									<input type="text" id="template-contactform-name" name="template-contactform-name" value="" className="sm-form-control required" />
								</div>

								<div className="col-md-6 form-group">
									<label for="template-contactform-email">Email <small>*</small></label>
									<input type="email" id="template-contactform-email" name="template-contactform-email" value="" className="required email sm-form-control" />
								</div>

								<div className="w-100"></div>

								<div className="col-md-6 form-group">
									<label for="template-contactform-phone">Phone</label>
									<input type="text" id="template-contactform-phone" name="template-contactform-phone" value="" className="sm-form-control" />
								</div>

								<div className="col-md-6 form-group">
									<label for="template-contactform-service">Services</label>
									<select id="template-contactform-service" name="template-contactform-service" className="sm-form-control">
										<option value="">-- Select One --</option>
										<option value="Wordpress">Wordpress</option>
										<option value="PHP / MySQL">PHP / MySQL</option>
										<option value="HTML5 / CSS3">HTML5 / CSS3</option>
										<option value="Graphic Design">Graphic Design</option>
									</select>
								</div>

								<div className="w-100"></div>

								<div className="col-12 form-group">
									<label for="template-contactform-subject">Subject <small>*</small></label>
									<input type="text" id="template-contactform-subject" name="subject" value="" className="required sm-form-control" />
								</div>

								<div className="col-12 form-group">
									<label for="template-contactform-message">Message <small>*</small></label>
									<textarea className="required sm-form-control" id="template-contactform-message" name="template-contactform-message" rows="6" cols="30"></textarea>
								</div>

								<div className="col-12 form-group d-none">
									<input type="text" id="template-contactform-botcheck" name="template-contactform-botcheck" value="" className="sm-form-control" />
								</div>

								<div className="col-12 form-group">
									<button className="button button-3d m-0" type="submit" id="template-contactform-submit" name="template-contactform-submit" value="submit">Send Message</button>
								</div>

								<input type="hidden" name="prefix" value="template-contactform-"/>

							</form>
						</div>

						<div className="line"></div>

						<div className="row col-mb-50">
						
							<div className="col-md-4">
								{/* <address>
									<strong>Headquarters:</strong><br/>
									795 Folsom Ave, Suite 600<br>
									San Francisco, CA 94107<br>
								</address> */}
								<abbr title="Phone Number"><strong>Phone:</strong></abbr> (251) 00 0000000<br/>
								<abbr title="Fax"><strong>Fax:</strong></abbr> (251) 00 0000000<br/>
								<abbr title="Email Address"><strong>Email:</strong></abbr> info@ssgi.gov.et
							</div>

			
							<div className="col-md-8" id="test">
								<div className="fslider customjs testimonial twitter-scroll twitter-feed" data-username="envato" data-count="4" data-animation="slide" data-arrows="false">
									<i className="i-plain color icon-twitter mb-0" style={{marginRight: "15px;"}}></i>
									<div className="flexslider" style={{width: "auto;"}}>
										<div className="slider-wrap">
											<div className="slide"></div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>

			
			<section className="gmap" data-address="Melbourne, Australia" data-scrollwheel="false" data-markers='[{address: "Melbourne, Australia", html: "<div className=\"p-2\" style=\"width: 300px;\"><h4 className=\"mb-2\">Hi! We are <span>Envato!</span></h4><p className=\"mb-0\" style=\"font-size:1rem;\">Our mission is to help people to <strong>earn</strong> and to <strong>learn</strong> online. We operate <strong>marketplaces</strong> where hundreds of thousands of people buy and sell digital goods every day.</p></div>", icon:{ image: "images/icons/map-icon-red.png", iconsize: [32, 39], iconanchor: [32,39] } }]'></section>
		</section>
    </>
  );
}

export default Contact;
