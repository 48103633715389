// src/Annual_list.js

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneDayData } from "../../actions/oneDay";

const ParticipationList = () => {
  const dispatch = useDispatch();
  const { oneDay, loading, error } = useSelector((state) => state.oneDay);
  const usersArray = oneDay || [];
  useEffect(() => {
      dispatch(fetchOneDayData());

  }, [dispatch]);

  const totalMale = usersArray.reduce((total, user) => total + (user.male || 0), 0);
  const totalFemale = usersArray.reduce((total, user) => total + (user.female || 0), 0);
  const total = usersArray.reduce((total, user) => total + (user.totalParticipation || 0), 0);
  return (
    <>
      <div className="row">
        <div className="">
          <div style={{ textAlign: "center" }}>
            <table
              style={{
                borderCollapse: "collapse",
                width: "80%",
                margin: "auto",
              }}
            >
              <thead>
                 <tr>
                 <th style={{ border: "1px solid black", padding: "8px" }}>
                    year
                  </th>
                 
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    male
                  </th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    female
                  </th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    total
                  </th>
                </tr>
              </thead>
              <tbody>
              {oneDay && oneDay.length > 0 ? (
                oneDay.map((user, index) => (
                  <tr key={index}>
                   
                   <td style={{ border: "1px solid black", padding: "8px" }}>
                      {user.year}
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {totalMale.toLocaleString()}

                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {totalFemale.toLocaleString()}

                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {total.toLocaleString()}

                    </td>
                  </tr>
                ))): (
                  <tr>
                    <td colSpan="4" style={{ border: "1px solid black", padding: "8px" }}>
                      Loading ...
                    </td>
                  </tr>
                )
              }
              </tbody>
            </table>
          </div>
        
        </div>
      </div>
    </>
  );
};

export default ParticipationList;
