import React, { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  DatabaseOutlined,
  CaretDownOutlined
} from "@ant-design/icons";
import {
  Button,
  Menu,
  Layout,
  Dropdown,
  message,
  Form,
  Input,
  Modal,
} from "antd";
import axios from "axios";
import Logo from "../asset/images/green_legacy_logo.png";
import { useNavigate } from "react-router-dom";
import Registor from "./users/Registor";
import UploadRegionalData from "./regionalAdmin/upload_regional_data";
import Users_list from "./users/users_List";
import Region_list from "./regionalAdmin/region_List";
import Annual_list from "./annualAdmin/annual_List";
import Cookies from "js-cookie"; // Import the cookies library
import RegionAdmin from "./regionalAdmin/regionAdmin";
import SiteAdmin from "./siteAdmin/siteAdmin";
import AnnualAdmin from "./annualAdmin/annualAdmin";
import NumberForm from "./oneday/oneDay";
import ImageUpload from "./media/imageUpload";
import Participation from "./oneday/participation";
import { Logout, logout } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import VideoUpload from "./media/videoUpload";
import { isTokenExpired, logout1 } from "./Auth/tokencomp";
const Server_URL = process.env.REACT_APP_SERVER_URL;

const { Header, Sider, Content } = Layout;

const Upload_form = () => {
  const navigate = useNavigate();
  const { name,usertype,email,fname,lname} = useSelector((state) => state.user);

  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  // const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(<Registor />); // State to manage the currently displayed component
  const [value, setValue] = useState({
    target: "",
    planted: "",
    region1: "",
  });
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authReducer);
  // useEffect(() => {
  //   if (!token || isTokenExpired(token)) {
  //     dispatch(logout1());
  //     navigate('/login');
  //   }
  // }, [token, dispatch, navigate]);
  // // useEffect(() => {
  // //   const fetchToken = async () => {
  // //     const token = localStorage.getItem("token");

  // //     if (token) {
  // //       try {
  // //         const response = await axios.get(`${server_URl}/verify`, {
  // //           headers: { Authorization: `Bearer ${token}` },
  // //         });

  // //         console.log("Token response:", response.data);

  // //         const { valid, fname, id } = response.data;

  // //         if (valid && fname && id) {
  // //           setName(fname);
  // //           setId(id);
  // //         } else {
  // //           window.location.href = "/";
  // //         }
  // //       } catch (error) {
  // //         console.error("Error verifying token:", error);
  // //         window.location.href = "/";
  // //       }
  // //     } else {
  // //       window.location.href = "/";
  // //     }
  // //   };

  // //   fetchToken();
  // // }, []);

  const handleClick = (item) => {
    let componentToRender;

    switch (item) {
      case "Image Upload":
        componentToRender = <ImageUpload />;
        break;
        
        case "Participation":
          componentToRender = <Participation />;
          break;
      case "Add One Day Data":
        componentToRender = <NumberForm />;
        break;
      case "Add New User":
        componentToRender = <Registor />;
        break;
      case "Upload Regional Data":
        componentToRender = <RegionAdmin />;
        break;
      case "Upload Site Data":
        componentToRender = <SiteAdmin />;
        break;
      case "Upload Years Data":
        componentToRender = <AnnualAdmin />;
        break;
        case "Image Upload":
          componentToRender = <ImageUpload />;
          break;
          case "Video Upload":
            componentToRender = <VideoUpload />;
            break;
      // case "Users":
      //   componentToRender = <Users_list />;
      //   break;
      // case "Regional_Data":
      //   componentToRender = <Region_list />;
      //   break;
      // case "Years_Data":
      //   componentToRender = <Annual_list />;
      //   break;
      default:
        componentToRender = <UploadRegionalData />;
        break;
    }

    setCurrentComponent(componentToRender); // Update state with the new component
  };

  const ModelhandleChangePassword = (user) => {
    // console.log(name);
    setEditingUser(user);
    setVisible(true);
    form.setFieldsValue({
      name: name,
      id: id,
      Current_password: "Current_password",
      New_password: "New_password",
    });
  };

  const handleChangePassword = async () => {
    console.log(name, id);
    try {
      const oldPassword = form.getFieldValue("oldPassword");

      const NewPassword = form.getFieldValue("newPassword");
      // console.log(oldPassword, NewPassword);
      if (!NewPassword && oldPassword) {
        console.error("No password provided for updating");
        return;
      }
      const values = await form.validateFields();
      const updatedUser = { ...editingUser, ...values };
      // console.log("ggggg", values);
      // Assuming your backend has an endpoint for updating user passwords
      const response = await axios.put(`${Server_URL}/user/${id}`, values);
      // console.log("d", response);
      message.success("Password updated successfully");
      form.resetFields();
      setUsers(response.data);
    } catch (error) {
      console.error("Error updating password:", error);
      message.error("Failed to update password");
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setEditingUser(null);
    form.resetFields();
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
    window.location.reload();
    window.location.reload();

  };
 
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={ModelhandleChangePassword}>
             {/* profile */}
             </Menu.Item>
      <Menu.Item key="2" onClick={ModelhandleChangePassword}>
        Change Password
      </Menu.Item>
      <Menu.Item key="3" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ width: "50px", backgroundColor: "white" }}
        >
          <div className="d-flex">
            <a href="/">
              {" "}
              <img src={Logo} style={{ width: "200px", float: "left" }} />
            </a>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
                float: "right",
              }}
            />
          </div>
          <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
          {/* <Menu.Item
              key="1"
              icon={<UploadOutlined />}
              onClick={(e) => handleClick("Image Upload")}
            >
              Image Upload
            </Menu.Item> */}
             <Menu.Item
              key="1"
              icon={<UploadOutlined />}
              onClick={(e) => handleClick("Add New User")}
            >
              Add New User
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<UploadOutlined />}
              onClick={(e) => handleClick("Participation")}
            >
              Participation
            </Menu.Item>
          <Menu.Item
              key="3"
              icon={<UploadOutlined />}
              onClick={(e) => handleClick("Add One Day Data")}
            >
              Add One Day Data
            </Menu.Item>
           
            <Menu.Item
              key="4"
              icon={<UploadOutlined />}
              onClick={(e) => handleClick("Upload Regional Data")}
            >
              Upload Regional Data
            </Menu.Item>
            {/* <Menu.Item
              key="6"
              icon={<UploadOutlined />}
              onClick={(e) => handleClick("Upload Site Data")}
            >
              Upload Site Data
            </Menu.Item> */}
            <Menu.Item
              key="5"
              icon={<UploadOutlined />}
              onClick={(e) => handleClick("Upload Years Data")}
            >
              Upload Years Data
            </Menu.Item>
            <Menu.Item
              key="6"
              icon={<UploadOutlined />}
              onClick={(e) => handleClick("Image Upload")}
            >
             Image Upload
            </Menu.Item>
            <Menu.Item
              key="7"
              icon={<UploadOutlined />}
              onClick={(e) => handleClick("Video Upload")}
            >
             Video Upload
            </Menu.Item>
            <hr />
            {/* <Menu.Item
              key="8"
              icon={<UserOutlined />}
              onClick={(e) => handleClick("Users")}
            >
              Users
            </Menu.Item>
            <Menu.Item
              key="9"
              icon={<DatabaseOutlined />}
              onClick={(e) => handleClick("Regional_Data")}
            >
              Regional Data
            </Menu.Item>
            <Menu.Item
              key="10"
              icon={<DatabaseOutlined />}
              onClick={(e) => handleClick("Years_Data")}
            >
              Years Data
            </Menu.Item> */}
          </Menu>
        </Sider>

        <Layout>
          <Header
            style={{
              padding: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button type="text" style={{ color: "white" }}>
                  Admin{name} <CaretDownOutlined />
                </Button>
              </Dropdown>
            </div>
          </Header>

          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
            }}
          >
            <div className="row">
              <div className="col-lg-12">
                <div id="upload_form">
                  {currentComponent}
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
      <Modal
        title="Edit password"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleChangePassword}>
            Update
          </Button>,
        ]}
      >
        {name}
        <Form form={form} layout="vertical">
          <Form.Item name="oldPassword" label="Current Password">
            <Input
              value={value.old}
              onChange={(e) => setValue({ ...value, old: e.target.value })}
            />
          </Form.Item>
          <Form.Item name="newPassword" label="New Password">
            <Input
              value={value.new}
              onChange={(e) => setValue({ ...value, new: e.target.value })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Upload_form;
