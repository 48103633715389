import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie"; // Import the cookies library

import {
  Button,
  Menu,
  Layout,
  Dropdown,
  message,
  Form,
  Input,
  Modal,
} from "antd";
import UploadRegionalData1 from "./upload_regional_data1";
import Region_list from "./region_List";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import Logo from "../../asset/images/green_legacy_logo.png";
import { useNavigate } from "react-router-dom";
import RegionAdmin from "./regionAdmin";
import { useDispatch, useSelector } from "react-redux";
import { fetchTokenAndUpdateUser } from "../../actions/token";
import { logout } from "../../actions/auth";
const Server_URL = process.env.REACT_APP_SERVER_URL;

const { Header, Sider, Content } = Layout;

const UploadRegionalData = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [id, setId] = useState("");
  const [currentComponent, setCurrentComponent] = useState(<RegionAdmin />); // State to manage the currently displayed component

  const [showdata, setdata] = useState(false);
  const [clickedData, setClickedData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false); // State for profile modal visibility
  const [form] = Form.useForm();
  const [profileForm] = Form.useForm(); // Form instance for profile modal
  const [value, setValue] = useState({
    target: "",
    planted: "",
    area: "",
    region: "",
    siteName: "",
  });

  const { name } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTokenAndUpdateUser());
  }, [dispatch]);
  const handleChange = (name, e) => {
    setValue({ ...value, [name]: e.target.value });
  };

  const [selectedComponent, setSelectedComponent] = useState("Upload Regional Data");

  const handleClick = (item) => {
    setSelectedComponent(item);
  };

  let componentToRender;
  switch (selectedComponent) {
    case "Upload Regional Data":
      componentToRender = <RegionAdmin />;
      break;
    case "View Regional data":
      componentToRender = <Region_list />;
      break;
    default:
      componentToRender = <RegionAdmin />;
      break;
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
    window.location.reload();

  };

  const ModelhandleChangePassword = (user) => {
    setEditingUser(user);
    setVisible(true);
    form.setFieldsValue({
      name: name,
      oldPassword: "",
      newPassword: "",
    });
  };

  const Profile = (user) => {
    setEditingUser(user);
    setProfileVisible(true); // Set profile modal visibility to true
    profileForm.setFieldsValue({
      name: name,
      // Add other profile fields here if necessary
    });
  };

  const AddMetadata = (e) => {
    e.preventDefault();
    axios
      .put(`${Server_URL}/metadata/${value.region}`, value)
      .then((res) => {
        window.location.href = "/metadata";
        alert("User added successfully!");
      })
      .catch((err) => console.log(alert("User added error!", err)));
  };

  const handleChangePassword = async () => {
    try {
      const oldPassword = form.getFieldValue("oldPassword");
      const newPassword = form.getFieldValue("newPassword");
      if (!newPassword || !oldPassword) {
        console.error("No password provided for updating");
        return;
      }
      const values = await form.validateFields();
      const updatedUser = { ...editingUser, ...values };

      const response = await axios.put(`${Server_URL}/getuserusersadmin@639adminUpdatePassword/${id}`, values);
      message.success("Password updated successfully");
      form.resetFields();
      setUsers(response.data);
    } catch (error) {
      console.error("Error updating password:", error);
      message.error("Failed to update password");
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setEditingUser(null);
    form.resetFields();
  };

  const handleProfileCancel = () => {
    setProfileVisible(false);
    setEditingUser(null);
    profileForm.resetFields();
  };

  const handleProfileSave = async () => {
    try {
      const values = await profileForm.validateFields();
      const updatedUser = { ...editingUser, ...values };
      const response = await axios.put(`${Server_URL}/getuserusersadmin@639adminUpdateProfile/${id}`, values);
      message.success("Profile updated successfully");
      setUsers(response.data);
      setProfileVisible(false);
      profileForm.resetFields();
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Failed to update profile");
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="profile" onClick={Profile}>
        Profile
      </Menu.Item>
      <Menu.Item key="password" onClick={ModelhandleChangePassword}>
        Change Password
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ width: "50px", backgroundColor: "white" }}
        >
          <div className="d-flex">
            <a href="/">
              <img src={Logo} style={{ width: "200px", float: "left" }} alt="Logo" />
            </a>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
                float: "right",
              }}
            />
          </div>
       
        </Sider>

        <Layout>
          <Header style={{ padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button type="text" style={{ color: "white" }}>
                  {name} <CaretDownOutlined />
                </Button>
              </Dropdown>
            </div>
          </Header>

          <Content style={{ margin: "24px 16px", padding: 24, minHeight: 280 }}>
            <div className="row">
              <div className="col-lg-12">
                <div id="upload_form">{componentToRender}</div>
                {showdata && <UploadRegionalData1 data={clickedData} />}
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>

      <Modal
        title="Edit password"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleChangePassword}>
            Update
          </Button>,
        ]}
      >
        {name}
        <Form form={form} layout="vertical">
          <Form.Item name="oldPassword" label="Current Password" rules={[{ required: true, message: 'Please input your current password!' }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item name="newPassword" label="New Password" rules={[{ required: true, message: 'Please input your new password!' }]}>
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Profile"
        visible={profileVisible}
        onCancel={handleProfileCancel}
        footer={[
          <Button key="cancel" onClick={handleProfileCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleProfileSave}>
            Save
          </Button>,
        ]}
      >
        {name}
        <Form form={profileForm} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input your name!' }]}>
            <Input />
          </Form.Item>
          {/* Add other profile fields here as necessary */}
        </Form>
      </Modal>
    </>
  );
};

export default UploadRegionalData;
