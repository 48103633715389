import {

SET_VALUE,
SET_Participation,
ADD_Participation_SUCCESS,
ADD_Participation_ERROR,


    
  } from '../../actions/participation';

  
  const initialState = {
    loading: false,
    users: [],
    error: null,
    value: {
      male: "",
      female: "",
    },
    successMessage: "",
    errorMessage: "",
  };
 
  const participationReducer = (state = initialState, action) => {
    switch (action.type) {

   
      case SET_VALUE:
        return {
          ...state,
          value: { 
            ...state.value, 
            [action.payload.name]: action.payload.value },
        };
    case ADD_Participation_SUCCESS:
            return {
              ...state,
              successMessage: action.payload,
              errorMessage: '',
            };
  case ADD_Participation_ERROR:
            return {
              ...state,
              successMessage: '',
              errorMessage: action.payload,
            };
          default:
            return state;
    }
  };
  
  export default participationReducer;
  