import React, { useEffect, useRef } from "react";
import "ol/ol.css";
import "../../css/App.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import LayerSwitcher from "ol-layerswitcher";
import logo from "../../asset/images/green_legacy_logo.png";
import ImageWMS from "ol/source/ImageWMS.js";
import { XYZ } from "ol/source";
import {ScaleLine,defaults as defaultControls, FullScreen,} from "ol/control.js";
import seed from "../../asset/images/green_legacy_logo.png";
import TileWMS from "ol/source/TileWMS";

import { Table } from "antd";
const mapURL = process.env.REACT_APP_MAP_URL;

const columns = [
  {
    title: '',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Forest tree',
    dataIndex: 'Forest tree',
    key: 'Forest tree',
  },
  {
    title: 'Bamboo',
    dataIndex: 'Bamboo',
    key: 'Bamboo',
  },
  {
    title: 'Agro Forest',
    dataIndex: 'Agro Forest',
    key: 'Agro Forest',
  },
  {
    title: 'Omament',
    dataIndex: 'Omament',
    key: 'Omament',
  },

];

const tableData = [
  {
    key: '1',
    category: 'Planted',
    'Forest tree': '',
    'Bamboo': '',
    'Agro Forest': '',
    'Omament': '',
   
  },
 
];
function Annual_map({ data }) {
  const wmsSource = new ImageWMS({
    url: `${mapURL}`,
    params: { LAYERS: `Green_Legacy:${data.params}` },
    ratio: 1,
    serverType: "geoserver",
  });
  // console.log(wmsSource);
  // console.log(wmsSource);
  const updateLegend = function (resolution) {
    const graphicUrl = wmsSource.getLegendUrl(resolution);
    const img = document.getElementById("legend1");
    img.src = graphicUrl;
  };

  const mapRef = useRef(null);
  useEffect(() => {
    if (!mapRef.current) return;
    const map = new Map({
      controls: defaultControls().extend([new FullScreen()]),
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
          title: "OSM",
          visible: true,
          // type: "base",
          // group: "base",
        }),
        new TileLayer({
          source: new XYZ({
            url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          }),
          title: "Satelite",
          visible: false,
          title: "satilite",
          // type: "base",
          // group: "base",
        }),
        new TileLayer({
          source: new TileWMS({
            url: data.url,
            params: { LAYERS: `Green_Legacy:${data.params1}`, TILED: true },
            serverType: "geoserver",
            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: `${data.title_eth}`,
          title: `${data.title_eth}`,
        }),
        new TileLayer({
          source: new TileWMS({
            url: data.url,
            params: { LAYERS: `Green_Legacy:${data.params}`, TILED: true },
            serverType: "geoserver",
            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: `${data.title}`,
        }),
              ],
      view: new View({
        center: fromLonLat([data.lng, data.lat]),
        zoom: data.zoom,
      }),
    });
    // Initial legend
    const resolution = map.getView().getResolution();
    updateLegend(resolution);

    // Update the legend when the resolution changes
    map.getView().on("change:resolution", function (event) {
      const resolution = event.target.getResolution();
      updateLegend(resolution);
    });

    var layerSwitcher = new LayerSwitcher({
      reverse: false,
      activationMode: "click",
      groupSelectStyle: "children",
    });
    map.addControl(layerSwitcher);

    //Scale view
    var scaleLineMetric = new ScaleLine({
      units: "imperial",
      minWidth: 100,
      target: document.getElementById("scaleline-imperial"),
      text: true,
      steps: 4, // Number of steps
      bar: true, // Render the scale as a bar
      text: true, // Render the text indicating the scale
      className: "ol-scale-line", // CSS class name
      minWidth: 120, // Minimum width in pixels
    });
    map.addControl(scaleLineMetric);

    map.on("singleclick", function (evt) {
      const clickedCoordinate = evt.coordinate;

      clickedFeature(evt, map);
      map.getView().animate({
        center: clickedCoordinate,
        zoom: 10, // Adjust zoom level as needed
        zoom: 10, // Adjust zoom level as needed
        duration: 500 // Animation duration in milliseconds
      })
    });
    return () => map.dispose();
  }, [data]);
  function clickedFeature(evt, map) {
    const viewResolution = map.getView().getResolution();
    const viewProjection = map.getView().getProjection();
    const coordinate = evt.coordinate;
    const url = wmsSource.getFeatureInfoUrl(coordinate,viewResolution,viewProjection,
              {
               INFO_FORMAT: "application/json",
                FEATURE_COUNT: 2,
              });
  if (url) {
          fetch(url)
             .then((response) => response.json())
             .then(function (json) {
                    // Parse JSON response
                    const region = {};
                    const zone = {};
                    const wereda = {};
                    const kebele = {};
                    const site = {};
                    const Area = {};
                    region[""] = json["features"][0]["properties"]["region"];
                    zone[""] = json["features"][0]["properties"]["zone"];
                    wereda[""] = json["features"][0]["properties"]["wereda"];
                    kebele[""] = json["features"][0]["properties"]["kebele"];
                    site[""] = json["features"][0]["properties"]["site_name"];
                    Area[""] = json["features"][0]["properties"]["area_ha"];
          
                    // Handle the response as per your requirement
                    const container = document.getElementById( "container_clicked_map-region");
                    const Name_region = region[""];
                    const Name_zone = zone[""];
                    const Name_wereda = wereda[""];
                    const Name_kebele = kebele[""];
                    const Name_site = site[""];
                    const Name_Area = Area[""];
                    document.getElementById(
                      "container_clicked_map-image"
                    ).innerHTML = `<img src='${seed}'  width=50%/>`+ "<b><br/> Ethiopia Green Legacy Initiative</b>   ";
          
                    document.getElementById("container_clicked_map-region").innerHTML = "<b> Region:</b>  " + '<span style="background-color:#209540;color:white;">'+ Name_region + '</b>';

                    document.getElementById("container_clicked_map-wereda" ).innerHTML = "<b> Wereda:</b> " + Name_wereda;
                    document.getElementById( "container_clicked_map-kebele" ).innerHTML = "<b> Kebele:</b>  " + Name_kebele;
                    document.getElementById("container_clicked_map-site" ).innerHTML = "<b> Site:</b> " + Name_site;
                    document.getElementById("container_clicked_map-area").innerHTML ="<b> Total Area of coverage</b>   " +"<br/>" + " &nbsp;&nbsp;&nbsp; " +Name_Area +"   hectare ";
                    document.getElementById("container_clicked_map-zone").innerHTML = "<b> Zone:</b>   "  +Name_zone + "</b>";
                    document.getElementById("container_clicked_map-wereda" ).innerHTML = "<b> Wereda:</b>  " + Name_wereda;
                    document.getElementById( "container_clicked_map-kebele" ).innerHTML = "<b> Kebele:</b>   " + Name_kebele;
                    document.getElementById("container_clicked_map-site" ).innerHTML = "<b> Site:</b>   " + Name_site;
                    document.getElementById("container_clicked_map-area").innerHTML ="<b> Total Area of coverage:</b>   " +"<br/>" + " &nbsp;&nbsp;&nbsp; " +Name_Area +"   hectare ";
                    // document.getElementById("container_clicked_map-target").innerHTML ="<b> Total Target:</b>   " + "----";
                    // document.getElementById("container_clicked_map-planted").innerHTML ="<b> Total Planted:</b>   " + "-----";         
                           })
                  .catch((err) => {
                     document.getElementById("container_clicked_map" ).style.display = "none";
                    console.log(err);
                  });
              }

              var modal = document.getElementById("container_clicked_map");
              modal.style.display = "block";
              document.getElementById("container_clicked_map").style.width ="420px";
              document.getElementById("container_clicked_map-content").style.width = "420px";
              document.getElementById("close-icon1").style.display ="inline";
              window.onclick = function (event) {
                  var modal = document.getElementById("container_clicked_map");
                if (event.target == modal) {
                  modal.style.display = "none";
                }
    };
  }
  function container_clicked_map() {
    document.getElementById("container_clicked_map" ).style.display = "none";
    document.getElementById("container_clicked_map-content").style.width = "0";
  }

  return (
    <>
      <div>
        <div className="row main_body title_region">
          <h3 style={{ marginLeft: "25px", marginTop:"10px",color: "black" }}>Year-<strong style={{ marginLeft: "5px" }}>{data.title}</strong></h3>
          <div className="col-lg-9 seedings_year_img">
            <div ref={mapRef} className="home-map map_container_region" style={{ width: "100%", height: "450px" }}></div>
            {/* <div className="map_info">  <hr />
               <div className="map_info_detail">
                  <p> <h2>Seedling Planted detail {data.title}</h2></p>
                </div>
                <Table columns={columns} dataSource={tableData} pagination={false} bordered />
            </div> */}
          </div>
          <div className="col-lg-3">
            <div className="sidenav_right"><hr />
              <h5><a href="/metadata_detail" style={{ color: "white" }}> </a></h5><hr />
              <h5><a href="/metadata_detail" style={{ color: "white" }}> </a>{" "} </h5> <hr />
              <h4>Legend</h4>
              <div><img id="legend1" /></div><hr />
              <p style={{fontSize:"25px",backgroundColor:"#05376b",color:"#fff",fontWeight:"bold",textAlign:"center"}}> {data.title}</p>
              <p><h4>Ethiopia</h4></p>
              <p>Total Planted:<b>{data.total_planted}</b></p>
              <h4 style={{ fontWeight: "bold" }}>About</h4>
              <p>Owner,point of Contact,Metadata Author</p>
              <div className="map_info">
                <ul>
                  <p><img src={logo} className="circle-image" /></p>
                </ul>
                <p> Green Legacy Initiative(GLI)</p>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>

      <div id="container_clicked_map" className="container_clicked_map">
        <div className="close-icon1"id="close-icon1"onClick={container_clicked_map}> &times; </div>
        <div id="container_clicked_map-content">
          <div id="container_clicked_map-region"  className="container_list" ></div><hr/>
          <div  id="container_clicked_map-zone"className="container_list"  ></div>
          <div id="container_clicked_map-wereda"className="container_list" ></div>
          <div id="container_clicked_map-kebele"  className="container_list"  ></div>
          <div id="container_clicked_map-site" className="container_list"></div><hr/>
          <div id="container_clicked_map-area" className="container_list"  ></div><br/>
          <div id="container_clicked_map-image"></div>

        </div>        <hr />
        <div id="container_clicked_map-target"></div>
        <div id="container_clicked_map-planted"></div>
      </div>
    </>
  );
}
export default Annual_map;
