import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setValue, addUser } from '../../actions/users';
import { Modal, Button } from 'antd';
import Users_list from './users_List';

const Register = () => {
  const dispatch = useDispatch();
  const { value, successMessage, errorMessage } = useSelector((state) => state.user);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const usertypeOptions = ["Super Admin", "Site Admin", "Regional Admin", "National Admin", "oneday","participant"];
  const genderOptions = ["Male", "Female"];

  const handleChange = (e) => {
    dispatch(setValue(e.target.name, e.target.value));
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    dispatch(addUser(value));
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div id="result">
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      </div>

      <div id="result"></div>
      <Button type="primary" onClick={showModal}>
              Add User
            </Button>
      <div className="d-flex justify-content-center align-items-center ">
        <div className="w-50" style={{ backgroundColor: "" }}>
          <div style={{ margin: "50px" }}>
           
            <Modal
              title="Create New User"
              visible={isModalVisible}
              onOk={handleAddUser}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleAddUser}>
                  Add
                </Button>,
              ]}
            >
              <div className="upload-form">
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                <div className="form-container">
                  <div className="form-column">
                    <div className="form-group">
                      <p>First name</p>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="fname"
                        value={value.fname}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group">
                      <p>Last name</p>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="lname"
                        value={value.lname}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group">
                      <p>Email</p>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        name="email"
                        value={value.email}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group">
                      <p>Password</p>
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        name="password"
                        value={value.password}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-column">
                    <div className="form-group">
                      <p>Phone Number</p>
                      <input
                        type="phone"
                        className="form-control form-control-lg"
                        name="phone"
                        value={value.phone}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group">
                      <p>User Type</p>
                      <select
                        className="form-control form-control-lg"
                        name="usertype"
                        value={value.usertype}
                        onChange={handleChange}
                      >
                        <option value="">Type</option>
                        {usertypeOptions.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <p>Gender</p>
                      <select
                        className="form-control form-control-lg"
                        name="gender"
                        value={value.gender}
                        onChange={handleChange}
                      >
                        <option value="">Gender</option>
                        {genderOptions.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
      <Users_list/>
    </>
  );
};

export default Register;
