import React, { useState } from "react";
import ImageGallery from "../components/media/image";

import {
  Button,
  Menu,
  Layout,
  Dropdown,
  message,
  Form,
  Input,
  Modal,
} from "antd";
import VideoGallery from "../components/media/video";
const Gallery = () => {
  const { Header, Sider, Content } = Layout;
  const [currentComponent, setCurrentComponent] = useState(<ImageGallery />); 
  const handleClick = (item) => {
    let componentToRender;

    switch (item) {
      case "Image":
        componentToRender = <ImageGallery />;
        break;
        
        case "Video":
          componentToRender = < VideoGallery/>;
          break;
     
   
      default:
        componentToRender = <ImageGallery />;
        break;
    }

    setCurrentComponent(componentToRender); // Update state with the new component
  };
  return (
    <>
    <div className="row">
    <div className="col-lg-2 Region_sidebar_region">
    <div className="Region_sidebar_region">
    <h4 >Gallery</h4>
    <div className="Region_sidebar">
              <div>
          
                     <ul>
                        <li onClick={(e) => handleClick("Image")}  ><span>Image</span></li><br/>
                       <li onClick={(e) => handleClick("Video")}><span>Video</span> </li>
            
                      </ul>
                      </div>
            </div>

 
            </div>
            </div>
            <div className='col-lg-10'>
            {currentComponent}
            </div>
    </div>
     


    </>
  );
};

export default Gallery;
