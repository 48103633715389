import {
    SET_VALUE,
    SET_NAME,
    SET_ID,
    SET_USERS,
    SET_SHOW_DATA,
    SET_CLICKED_DATA,
    SET_EDITING_USER,
    SET_VISIBLE,
    ADD_ANNUAL_SUCCESS,
    ADD_ANNUAL_DATA_ERROR,

    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    DELETE_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    
  } from '../../actions/annualAdmin';

  
  const initialState = {
    loading: false,
    users: [],
    error: null,
    value: {
      year: "",
      target: "",
      planted: "",
    },
    name: "",
    id: "",
    users: [],
    showData: false,
    clickedData: null,
    editingUser: null,
    visible: false,
    successMessage: "",
    errorMessage: "",
  };
 
  const annualReducer = (state = initialState, action) => {
    switch (action.type) {

      case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
      
      case SET_VALUE:
        return {
          ...state,
          value: { ...state.value, [action.payload.name]: action.payload.value },
        };
      case SET_NAME:
        return {
          ...state,
          name: action.payload,
        };
      case SET_ID:
        return {
          ...state,
          id: action.payload,
        };
      case SET_USERS:
        return {
          ...state,
          users: action.payload,
        };
      case SET_SHOW_DATA:
        return {
          ...state,
          showData: action.payload,
        };
      case SET_CLICKED_DATA:
        return {
          ...state,
          clickedData: action.payload,
        };
      case SET_EDITING_USER:
        return {
          ...state,
          editingUser: action.payload,
        };
      case SET_VISIBLE:
        return {
          ...state,
          visible: action.payload,
        };
        case ADD_ANNUAL_SUCCESS:
            return {
              ...state,
              successMessage: action.payload,
              errorMessage: '',
            };
        case ADD_ANNUAL_DATA_ERROR:
            return {
              ...state,
              successMessage: '',
              errorMessage: action.payload,
            };
          default:
            return state;
    }
  };
  
  export default annualReducer;
  