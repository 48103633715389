import * as FaIcons from "react-icons/fa";
const mapURL = process.env.REACT_APP_MAP_URL;

      
export const OneDayData=[
       
    {
        title:'one day 2024',
        title_2024:'2024 Site',
        sites:'sites',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        params2:'',        url:`${mapURL}`,
        params:'addis ababa',
        params1:'addis abeba Plantation',
        lng:'38.736926',
        lat:'8.995017',
        zoom:9.5,
        target_2019:'No_Data',
        planted_2019:'4.06',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'2,000,000',
        planted_2020:'2.35',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'1,000,000',
        planted_2021:'11.44',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2022:'No_Data',
        planted_2022:'10.65',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2023:'1,650,000',
        planted_2023:'15.59',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        // total_planted: (2095110 + 1763938 + 2177635).toString()
        // .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        total_planted:"44.09 Million"
               
  

               
    },

   

]


