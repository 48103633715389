// src/reducers/userReducer.js
import { SET_LOGGED_IN, SET_NAME, SET_USER_TYPE } from '../actions/token';

const initialState = {
  isLoggedIn: false,
  name: '',
  userType: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
      
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
