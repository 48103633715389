import axios from 'axios';

export const ADD_REGIONAL_DATA_REQUEST = 'ADD_REGIONAL_DATA_REQUEST';
export const ADD_REGIONAL_DATA_SUCCESS = 'ADD_REGIONAL_DATA_SUCCESS';
export const ADD_REGIONAL_DATA_FAILURE = 'ADD_REGIONAL_DATA_FAILURE';

export const FETCH_REGIONS_REQUEST = 'FETCH_REGIONS_REQUEST';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAILURE = 'FETCH_REGIONS_FAILURE';
export const DELETE_REGION_SUCCESS = 'DELETE_REGION_SUCCESS';
export const UPDATE_REGION_SUCCESS = 'UPDATE_REGION_SUCCESS';
const Server_URL = process.env.REACT_APP_SERVER_URL;

// Action creators

export const fetchRegionsRequest = () => ({
  type: FETCH_REGIONS_REQUEST,
});

export const fetchRegionsSuccess = (regions) => ({
  type: FETCH_REGIONS_SUCCESS,
  payload: regions,
});

export const fetchRegionsFailure = (error) => ({
  type: FETCH_REGIONS_FAILURE,
  payload: error,
});

export const deleteRegionSuccess = (regionId) => ({
  type: DELETE_REGION_SUCCESS,
  payload: regionId,
});

export const updateRegionSuccess = (region) => ({
  type: UPDATE_REGION_SUCCESS,
  payload: region,
});

// Thunks
export const fetchRegions = () => async (dispatch) => {
  dispatch(fetchRegionsRequest());
  try {
    const response = await axios.get(`${Server_URL}/regional_data`);
    dispatch(fetchRegionsSuccess(response.data));
  } catch (error) {
    dispatch(fetchRegionsFailure(error.message));
  }
};

export const deleteRegion = (regionId) => async (dispatch) => {
  try {
    await axios.delete(`${Server_URL}/regional_data/${regionId}`);
    dispatch(deleteRegionSuccess(regionId));
  } catch (error) {
    console.error('Error deleting region:', error);
  }
};

export const updateRegion = (region) => async (dispatch) => {
  
  try {
    const response = await axios.put(`${Server_URL}/regional_data/${region.id}`, region);
    dispatch(updateRegionSuccess(response.data));
  } catch (error) {
    console.error('Error updating region:', error);
  }
};



export const addDataSuccess = (message) => ({
    type: ADD_REGIONAL_DATA_SUCCESS,
    payload: message,
  });
  export const addDataError = (message) => ({
    type: ADD_REGIONAL_DATA_FAILURE,
    payload: message,
  });
export const addRegionalData = (value) => async (dispatch) => {
     // Validate input
     if (!value || !value.year || !value.region || !value.target || !value.planted) {
        console.error("Invalid or empty data provided:", value);
        dispatch(addDataError("Invalid or empty data provided"));
        return;
      }
  dispatch({ type: ADD_REGIONAL_DATA_REQUEST });

  try {
    const res = await axios.post(`${Server_URL}/regional_data/`, value);
    dispatch(addDataSuccess("Data added successfully!"));

} catch (err) {
    console.error("Error:", err);
      alert("Error adding data!");
  }
};
