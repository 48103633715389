import React, { useEffect, useRef } from "react";
import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { XYZ } from "ol/source";
import logo from "../../asset/images/green_legacy_logo.png";
// import mapURL from "";
import { fromLonLat } from "ol/proj";
import LayerSwitcher from "ol-layerswitcher";
import {ScaleLine,defaults as defaultControls, FullScreen,} from "ol/control.js";
import target from "../../asset/images/target.png";
import planted_1 from "../../asset/images/planted_1.png";
import seed from "../../asset/images/green_legacy_logo.png";
import TileWMS from "ol/source/TileWMS";
import ImageWMS from "ol/source/ImageWMS.js";
import Fill from "ol/style/Fill.js";
import Stroke from "ol/style/Stroke.js";
import Style from "ol/style/Style.js";
import axios from "axios";
import { RegionData } from "../../data/RegionData";
import { Table } from "antd";
const mapURL = process.env.REACT_APP_MAP_URL;


const Server_URL = process.env.REACT_APP_SERVER_URL;

const wmsSource = new ImageWMS({
  url: `${mapURL}`,
  params: { LAYERS: "Green_Legacy:ethiopia_new_2016" },
  ratio: 1,
  serverType: "geoserver",
});
const updateLegend = function (resolution) {
  const graphicUrl = wmsSource.getLegendUrl(resolution);
  const img = document.getElementById("legend");
  img.src = graphicUrl;
};
const Regions_map = (data) => {
  const columns = [
    
    {
      title: '',
      dataIndex: 'category',
      key: 'category',
    },
  

    {
      title: 'Addis_Ababa',
      dataIndex: 'Addis_Ababa',
      key: 'Addis_Ababa',
     
    },
    {
      title: 'Afar',
      dataIndex: 'Afar',
      key: 'Afar',
     
    },
    {
      title: 'Amhara',
      dataIndex: 'Amhara',
      key: 'Amhara',
     
    },
    {
      title: 'Benshangule',
      dataIndex: 'Benshangule',
      key: 'Benshangule',
     
    },
    {
      title: 'Dire_dawa',
      dataIndex: 'Dire_dawa',
      key: 'Dire_dawa',
     
    },
    {
      title: 'Gambela',
      dataIndex: 'Gambela',
      key: 'Gambela',
     
    },
    {
      title: 'Hareri',
      dataIndex: 'Hareri',
      key: 'Hareri',
     
    },
    {
      title: 'Oromia',
      dataIndex: 'Oromia',
      key: 'Oromia',
     
    },
    {
      title: 'SNNP',
      dataIndex: 'SNNP',
      key: 'SNNP',
     
    },
    {
      title: 'Somalia',
      dataIndex: 'Somalia',
      key: 'Somalia',
     
    },
    {
      title: 'Tigray',
      dataIndex: 'Tigray',
      key: 'Tigray',
     
    },
  ];
  
  const tableData = [
  
  {
    key: '1',
    category: 'Planted',
    'Addis_Ababa': `${RegionData[0].total_planted}`,
    'Afar': `${RegionData[1].total_planted}`,
    'Amhara': `${RegionData[2].total_planted}`,
    'Benshangule': `${RegionData[3].total_planted}`,
    'Dire_dawa': `${RegionData[5].total_planted}`,
    'Gambela': `${RegionData[6].total_planted}`,
    'Hareri': `${RegionData[7].total_planted}`,
    'Oromia': `${RegionData[8].total_planted}`,

    'Somalia': `${RegionData[10].total_planted}`,
    'SNNP': `${RegionData[14].total_planted}`,
    'Tigray': `${RegionData[13].total_planted}`,
  },


  ];
  const mapRef = useRef(null);
  useEffect(() => {
    if (!mapRef.current) return;
    const map = new Map({
      controls: defaultControls().extend([new FullScreen()]),
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
          title: "OSM",
          visible: true,
        }),
        new TileLayer({
          source: new XYZ({
            url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          }),
          title: "Satelite",
          visible: false,
          title: "satilite",
        }),
        // }),
        
        new TileLayer({
          source: new TileWMS({
            url: `${mapURL}`,
            params: { LAYERS: "Green_Legacy:ethiopia_new_2016", TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: "Region",
        }),
        new TileLayer({
          source: new TileWMS({
            url: `${mapURL}`,
            params: { LAYERS: "Green_Legacy:ethiopia_new_2016_all_regions", TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: "Region",
        }),
      ],
      view: new View({
        center: fromLonLat([38.972261469308, 8.748207216169536]),
        zoom: 5.5,
      }),
    });

    var layerSwitcher = new LayerSwitcher({
      reverse: true,
      activationMode: "click",
      groupSelectStyle: "children",
    });
    map.addControl(layerSwitcher);

    // Initial legend
    const resolution = map.getView().getResolution();
    updateLegend(resolution);

    // Update the legend when the resolution changes
    map.getView().on("change:resolution", function (event) {
      const resolution = event.target.getResolution();
      updateLegend(resolution);
    });

    //Scale view
    var scaleLineMetric = new ScaleLine({
      units: "imperial",
      minWidth: 100,
      target: document.getElementById("scaleline-imperial"),
      text: true,
      steps: 4,
      bar: true,
      text: true,
      className: "ol-scale-line",
      minWidth: 120,
    });
    map.addControl(scaleLineMetric);
    const selectStyle = new Style({
      fill: new Fill({
        color: "#eeeeee",
      }),
      stroke: new Stroke({
        color: "rgba(255, 255, 255, 0.7)",
        width: 2,
      }),
    });
    var selected = null;
    const status = document.getElementById("coordinates");
    map.on("singleclick", function (evt) {
      const features = map.getFeaturesAtPixel(evt.pixel);
      const clickedCoordinate = evt.coordinate;
      clickedFeature(evt, map);
      map.getView().animate({
        center: clickedCoordinate,
        // zoom: 12, // Adjust zoom level as needed
        duration: 500 // Animation duration in milliseconds
      });
    });

    return () => map.dispose();
  }, []);
  const tt=RegionData[0].title
  function clickedFeature(evt, map) {
    const viewResolution = map.getView().getResolution();
    const viewProjection = map.getView().getProjection();
    const coordinate = evt.coordinate;
    const url = wmsSource.getFeatureInfoUrl(
      coordinate,
      viewResolution,
      viewProjection,
      {
        INFO_FORMAT: "application/json",
        FEATURE_COUNT: 2,
      }
    );

    if (url) {
      fetch(url)
        .then((response) => response.json())
        .then(function (json) {
          // Parse JSON response
          const country = {};
          const region = {};
          const id = {};
          console.log(json);
          country[""] = "Ethiopia";
          region[""] = json["features"][0]["properties"]["r_name"];
          id["id"] = json["features"][0]["properties"]["r_code"];
          const years = [""];
          const response1 = axios
            .get(`${Server_URL}/regional_data/${id["id"]}`)
            .then((response) => {
              years.forEach((year) => {
                const data_2019 = response.data[0];
                const data_2020 = response.data[1];
                const data_2021 = response.data[2];
                const data_2022 = response.data[3];
                const data_2023 = response.data[4];
                //2019
                document.getElementById("container_clicked_map-target-years_2019").innerHTML =`<img src='${logo}' width=120px/>`+'<b style="background-color:green;color:white;font-size:18px"> ' +data_2019["year"] +"</b>";
                document.getElementById("container_clicked_map-target_2019").innerHTML = "<b> Total Target:</b>   " + data_2019["target"];
                document.getElementById("container_clicked_map-planted_2019" ).innerHTML = "<b> Total Planted:</b>   " +data_2019["planted"];
                document.getElementById("container_clicked_map-area_covarage_2019" ).innerHTML = "Area Covarage in hectare:";
                //2020
                document.getElementById("container_clicked_map-target-years_2020").innerHTML =`<img src='${logo}' width=120px/>`+'<b style="background-color:green;color:white;font-size:18px"> ' +data_2020["year"] + "</b>";document.getElementById("container_clicked_map-target_2020").innerHTML = `<img src='${target}' width=20px />` + "<b> Total Target:</b>   " +data_2020["target"];
                document.getElementById( "container_clicked_map-planted_2020").innerHTML =`<img src='${planted_1}' width=20px />` +"<b> Total Planted:</b>   " + data_2020["planted"];
                document.getElementById( "container_clicked_map-area_covarage_2020").innerHTML = "Area Covarage in hectare:";
                //2021
                document.getElementById("container_clicked_map-target-years_2021").innerHTML =`<img src='${logo}' width=120px/>`+'<b style="background-color:green;color:white;font-size:18px"> ' +data_2021["year"] +"</b>";
                document.getElementById("container_clicked_map-target_2021").innerHTML =`<img src='${target}' width=20px />` +"<b> Total Target:</b>   " +data_2021["target"];
                document.getElementById("container_clicked_map-planted_2021").innerHTML =`<img src='${planted_1}' width=20px />` +"<b> Total Planted:</b>   " +data_2021["planted"];
                document.getElementById("container_clicked_map-area_covarage_2021").innerHTML = "Area Covarage in hectare:";
                //2022
                document.getElementById("container_clicked_map-target-years_2022").innerHTML =`<img src='${logo}' width=120px/>`+'<b style="background-color:green;color:white;font-size:18px"> ' +data_2022["year"] +"</b>";
                document.getElementById("container_clicked_map-target_2022").innerHTML = `<img src='${target}' width=20px />` +"<b> Total Target:</b>   " +data_2022["target"];
                document.getElementById( "container_clicked_map-planted_2022").innerHTML =`<img src='${planted_1}' width=20px />` + "<b> Total Planted:</b>   " + data_2022["planted"];
                document.getElementById("container_clicked_map-area_covarage_2022" ).innerHTML = "Area Covarage in hectare:";
                //2023
                document.getElementById("container_clicked_map-target-years_2023").innerHTML =`<img src='${logo}' width=120px/>`+'<b style="background-color:green;color:white;font-size:18px"> ' + data_2023["year"] +"</b>";
                document.getElementById("container_clicked_map-target_2023").innerHTML = `<img src='${target}' width=20px />` +"<b> Total Target:</b>   " +data_2023["target"];
                document.getElementById("container_clicked_map-planted_2023").innerHTML =`<img src='${planted_1}' width=20px />` +"<b> Total Planted:</b>   " +data_2023["planted"];
                document.getElementById("container_clicked_map-area_covarage_2023").innerHTML = "Area Covarage in hectare:";
                document.getElementById("container_clicked_map-image" ).innerHTML = `<img src='${logo}' width=120px/>`+ "<b><br/> Ethiopia Green Legacy Initiative</b>   ";

              });
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
          const container = document.getElementById( "container_clicked_map-region"
          );
          const country_nmae = country[""];
          const region_nmae = region[""];
        })
        .catch((err) => {
          document.getElementById("container_clicked_map").style.display = "none";
          console.log(err);
        });
    }

    var modal = document.getElementById("container_clicked_map");
    modal.style.display = "block";
    document.getElementById("container_clicked_map").style.width = "420px";
    document.getElementById("container_clicked_map-content").style.width ="420px";
    document.getElementById("close-icon1").style.display ="inline";
    window.onclick = function (event) {
      var modal = document.getElementById("container_clicked_map");
      if (event.target == modal) {
        modal.style.display = "none";
      }
    };
  }
  function container_clicked_map() {
    document.getElementById("container_clicked_map").style.display = "none";
    document.getElementById("container_clicked_map-content").style.width = "0";
  }
  return (
    <>
      <div className="scaleline-metric"></div>
      <div className="row main_body title_region">
          <h3 style={{ marginLeft: "25px",marginTop:"10px", color: "black" }}>Ethiopia - Green Legacy<strong style={{ marginLeft: "5px" }}>{data.title}</strong></h3>
          <div className="col-lg-9 seedings_year_img">
            <div ref={mapRef} className="home-map map_container_region" style={{ width: "100%", height: "450px" }}></div>
            <div className="map_info"> </div>
         

        </div>

        <div className="col-lg-3">
        <div className="sidenav_right"> <hr />
            <h5><a href="/metadata_detail" style={{ color: "white" }}> </a></h5><hr />
            <h5><a href="/metadata_detail" style={{ color: "white" }}></a> </h5><hr />
            <h4>Legend</h4>
            <div><img id="legend" /></div> 
            {/* <h4 style={{ fontWeight: "bold" }}>About</h4>
            <p>Owner,point of Contact,Metadata Author</p>
            <div className="map_info">
              <ul>
                <p><img src={logo} className="circle-image" /></p>
              </ul>
              <p>Ethiopia Green Legacy</p>
            </div> <hr /> */}
          </div>
        </div>
      </div>
<div className="row">
<div className="col-lg-12"> 
  <div >
<div >

<p>
   <h2 className="center"> 2019-2023 Regions Seedling Planted detail</h2>
  </p>
  </div>
<Table className="yy"columns={columns} dataSource={tableData} pagination={false} bordered />
</div></div>
</div>
      <div id="container_clicked_map" className="container_clicked_map">
        <div
          className="close-icon1"
          id="close-icon1"
          onClick={container_clicked_map}
        >
          &times;
        </div>

        <div className="popup_side_bar">

        <div id="container_clicked_map-content" >
         
        

          {/* <div className="target_planted_2024">
            <div id="container_clicked_map-target-years_2024"></div>
            <div id="container_clicked_map-target_2024"></div>
            <div id="container_clicked_map-planted_2024"></div>
            <div id="container_clicked_map-area_covarage_2024"></div>
          </div> */}
          <div className="target_planted_2023">
            <div id="container_clicked_map-target-years_2023"></div>

            <div id="container_clicked_map-target_2023"></div>
            <div id="container_clicked_map-planted_2023"></div>
            <div id="container_clicked_map-area_covarage_2023"></div>
          </div>

          <hr/>
          <div className="target_planted_2022">
            <div id="container_clicked_map-target-years_2022"></div>

            <div id="container_clicked_map-target_2022"></div>
            <div id="container_clicked_map-planted_2022"></div>
            <div id="container_clicked_map-area_covarage_2022"></div>
          </div>  <hr/>
          <div className="target_planted_2021">
            <div id="container_clicked_map-target-years_2021"></div>

            <div id="container_clicked_map-target_2021"></div>
            <div id="container_clicked_map-planted_2021"></div>
            <div id="container_clicked_map-area_covarage_2021"></div>
            </div> <hr/>
          <div className="target_planted_2020">
            <div id="container_clicked_map-target-years_2020"></div>

            <div id="container_clicked_map-target_2020"></div>
            <div id="container_clicked_map-planted_2020"></div>
            <div id="container_clicked_map-area_covarage_2020"></div>
          </div>  <hr/>
          <div className="target_planted_2019">
            <div id="container_clicked_map-target-years_2019"></div>

            <div id="container_clicked_map-target_2019"></div>
            <div id="container_clicked_map-planted_2019"></div>
            <div id="container_clicked_map-area_covarage_2019"></div>
          </div><hr/>
          <div id="container_clicked_map-image"></div>

        </div>

        </div>

      </div>
    </>
  );
};

export default Regions_map;
