import React, { useRef } from 'react';
import axios from "axios";
import { Carousel } from "antd";
import logo_slide from "../../asset/images/green_legacy_logo1.jpg"
// import Leadership  from "../../asset/images/aaa.jpg";
import Leadership  from "../../asset/images/PMO11.jpg";


import NurseryManagement from "../../asset/images/IMG-20240812-WA0003.jpg";
import LandscapeRestoration from "../../asset/images/IMG-20240812-WA0004.jpg";
import FoodandWoodSecurity  from "../../asset/images/photo_2024-08-12_08-59-58.jpg";

import WaterResourceManagement  from "../../asset/images/IMG-20240812-WA0001.jpg";
import UrbanRegreening from "../../asset/images/corrider.jpg";
import Participation  from "../../asset/images/photo_2024-07-29_17-11-28.jpg";



const ImageSlide = () => {
  const carouselRef = useRef(null);

  const handleImageClick = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  return (
    <div className="">
      <div className=" image_Slide">
        <Carousel autoplay  pauseOnHover={false} ref={carouselRef}>
          <div className=' logo_slide'>
          <img
  src={logo_slide}
  alt="Slide 1"
 
  onClick={handleImageClick}
/>
            
            <h2 className="text-overlay">
              #GreenLegacy <br />
              The Green Legacy Initiative is a flagship programme aiming at creating green and resilient society in the horn of Africa and beyond.
            </h2>
          </div>
          <div className='slide_image' >
            <img src={Leadership} alt="Slide 1" onClick={handleImageClick}/>
            <h2 className="text-overlay">
              #Leadership <br />
              Ethiopia's Green Legacy Initiative is a bold demonstration of the government's commitment to addressing climate change, land and water degradation, and biodiversity loss.

            </h2>
          </div>
          <div className='slick-slide'>
            <img src={NurseryManagement}onClick={handleImageClick} />
            <div>
              <h2 className="text-overlay">
                #NurseryManagement <br />
                The Green Legacy Initiative has significantly increased the number of nurseries and enhanced the raising of high-quality seedlings.
                </h2>
            </div>
          </div>
          <div className='slick-slide'>
            <img src={LandscapeRestoration} onClick={handleImageClick}/>

            <h2 className="text-overlay">
              #LandscapeRestoration <br />
              The Green Legacy Initiative has catalyzed the mapping, prioritization, and implementation of soil and water conservation measures across millions of hectares.

            </h2>
          </div>
          <div className='slick-slide'>
            <img src={FoodandWoodSecurity } onClick={handleImageClick}/>
            <h2 className="text-overlay">
              #FoodandWoodSecurity <br />
              The Green Legacy Initiative enabled plating of millions of forest and agro-forest seedlings that contribute to food and nutrition security and improved supply chain of forest products.

            </h2>
          </div>
          <div className='slick-slide'>
            <img src={WaterResourceManagement} alt="Slide 1"onClick={handleImageClick} />
            <h2 className="text-overlay">
              #WaterResourceManagement<br />
              The Green Legacy Initiative has been rehabilitating degraded watersheds to safeguard water resource quantity and quality.

            </h2>
          </div>
          <div className='slick-slide'>
            <img src={UrbanRegreening} onClick={handleImageClick}/>
            <div>
              <h2 className="text-overlay">
                #UrbanRegreening <br />
                Every year millions of seedlings are planted to increase the resilience of urban areas.

              </h2>
            </div>
          </div>
         
          <div className='slick-slide'>
            <img src={Participation} onClick={handleImageClick}/>
            <h2 className="text-overlay">
              #Participation&Ownershipe <br />
              Soil and Water conservation, seedling planting and post management practices are becoming the culture of the society.
            </h2>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default ImageSlide;
