import {
  SET_VALUE,
  SET_NAME,
  SET_ID,
  SET_USERS,
  SET_SHOW_DATA,
  SET_CLICKED_DATA,
  SET_EDITING_USER,
  SET_VISIBLE,
  ADD_OneDay_SUCCESS,
  ADD_OneDay_DATA_ERROR,
  FETCH_ONEDAY_REQUEST,
  FETCH_ONEDAY_SUCCESS,
  FETCH_ONEDAY_FAILURE,
  DELETE_ONEDAY_SUCCESS,
  UPDATE_ONEDAY_SUCCESS,
} from '../actions/oneDay';

const initialState = {
  loading: false,
  users: [],
  error: null,
  value: {
    planted: "",
  },
  successMessage: "",
  errorMessage: "",
};

const OneDayReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ONEDAY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ONEDAY_SUCCESS:
      return {
        ...state,
        loading: false,
        oneDay: action.payload,
      };
    case FETCH_ONEDAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_ONEDAY_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    case UPDATE_ONEDAY_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
    case SET_VALUE:
      return {
        ...state,
        value: { ...state.value, [action.payload.name]: action.payload.value },
      };
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_SHOW_DATA:
      return {
        ...state,
        showData: action.payload,
      };
    case SET_CLICKED_DATA:
      return {
        ...state,
        clickedData: action.payload,
      };
    case SET_EDITING_USER:
      return {
        ...state,
        editingUser: action.payload,
      };
    case SET_VISIBLE:
      return {
        ...state,
        visible: action.payload,
      };
    case ADD_OneDay_SUCCESS:
      return {
        ...state,
        successMessage: action.payload,
        errorMessage: '',
      };
    case ADD_OneDay_DATA_ERROR:
      return {
        ...state,
        successMessage: '',
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default OneDayReducer;
