import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Button, Menu, Layout, Dropdown, message, Form, Input, Modal } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, CaretDownOutlined } from "@ant-design/icons";
import Logo from "../../asset/images/green_legacy_logo.png";
import { useNavigate } from "react-router-dom";
import SiteAdmin from "../siteAdmin/siteAdmin";
import AnnualAdmin from "./annualAdmin";
import AnnualList from "./annual_List";
import { useDispatch, useSelector } from "react-redux";
import { AddAnnual } from "../../actions/annualAdmin";
import UploadRegionalData from "../regionalAdmin/upload_regional_data";
import { fetchTokenAndUpdateUser } from "../../actions/token";
import { logout } from "../../actions/auth";
import RegionAdmin from "../regionalAdmin/regionAdmin";
const Server_URL = process.env.REACT_APP_SERVER_URL;

const { Header, Sider, Content } = Layout;

const UploadAnnualData = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [id, setId] = useState("");
  const [showdata, setdata] = useState(false);
  const [clickedData, setClickedData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [value, setValue] = useState({
    target: "",
    planted: "",
    area: "",
    region: "",
    siteName: "",
  });
  const dispatch = useDispatch();

  const [selectedComponent, setSelectedComponent] = useState("Upload Site Data");

  const { name } = useSelector((state) => state.user);


  useEffect(() => {
    dispatch(fetchTokenAndUpdateUser());
  }, [dispatch]);

  const handleChange = (name, e) => {
    setValue({ ...value, [name]: e.target.value });
  };

  const handleClick = (item) => {
    setSelectedComponent(item);
  };

  let componentToRender;
  switch (selectedComponent) {
    case "Upload Site Data":
      componentToRender = <AnnualAdmin />;
      break;
    case "View site data":
      componentToRender = <RegionAdmin />;
      break;
    default:
      componentToRender = <SiteAdmin />;
      break;
  }

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
    window.location.reload();

  };
  const ModelhandleChangePassword = () => {
    setVisible(true);
    form.setFieldsValue({
      name: name,
      oldPassword: "",
      newPassword: "",
    });
  };


  const handleAddMetadata = (e) => {
    e.preventDefault();
    dispatch(AddAnnual(value));
  };

  const handleChangePassword = async () => {
    try {
      const oldPassword = form.getFieldValue("oldPassword");
      const newPassword = form.getFieldValue("newPassword");

      if (!newPassword || !oldPassword) {
        console.error("Both current and new password must be provided");
        return;
      }

      const values = await form.validateFields();
      const updatedUser = { ...editingUser, ...values };

      const response = await axios.put(`${Server_URL}/getuserusersadmin@639adminUpdatePassword/${id}`, values);
      message.success("Password updated successfully");
      form.resetFields();
      setUsers(response.data);
    } catch (error) {
      console.error("Error updating password:", error);
      message.error("Failed to update password");
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setEditingUser(null);
    form.resetFields();
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={ModelhandleChangePassword}>
        Change Password
      </Menu.Item>
      <Menu.Item key="2" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ width: "50px", backgroundColor: "white" }}
        >
          <div className="d-flex">
            <a href="/">
              <img src={Logo} style={{ width: "200px", float: "left" }} alt="Logo" />
            </a>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
                float: "right",
              }}
            />
          </div>
        
        </Sider>

        <Layout>
          <Header style={{ padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button type="text" style={{ color: "white" }}>
                  {name} <CaretDownOutlined />
                </Button>
              </Dropdown>
            </div>
          </Header>

          <Content style={{ margin: "24px 16px", padding: 24, minHeight: 280 }}>
            <div className="row">
              <div className="col-lg-12">
               <AnnualAdmin/>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>

      <Modal
        title="Edit Password"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleChangePassword}>
            Update
          </Button>,
        ]}
      >
        {name}
        <Form form={form} layout="vertical">
          <Form.Item
            name="oldPassword"
            label="Current Password"
            rules={[{ required: true, message: "Please input your current password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[{ required: true, message: "Please input your new password!" }]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UploadAnnualData;
