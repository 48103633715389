import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import UserTypeRoute from './UserTypeRoute';
import LoadingToRedirect from './LodingToRedirect';

const ALLOWED_USER_TYPES = [
  'National Admin',

];

const AnnualPrivateRoute = ({ children }) => {
  const { usertype, token } = useSelector((state) => ({
    usertype: state.authReducer.usertype,
    token: state.authReducer.token,
  }));

  if (!token) {
    return <LoadingToRedirect />;
  }

  return ALLOWED_USER_TYPES.includes(usertype) ? (
    children
  ) : (
    <UserTypeRoute allowedUserTypes={ALLOWED_USER_TYPES} />
  );
};

AnnualPrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnnualPrivateRoute;
