import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const SET_VALUE = 'SET_VALUE';
export const LOGOUT = 'LOGOUT';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

const Server_URL = process.env.REACT_APP_SERVER_URL;

// Action to set form values
export const setValue = (name, value) => ({
  type: SET_VALUE,
  payload: { name, value },
});

// Action to handle login request
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

// Action to handle successful login
export const loginSuccess = (usertype, token) => ({
  type: LOGIN_SUCCESS,
  payload: { usertype, token },
});

// Action to handle login failure
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});
export const login = (value, navigate) => {
  return (dispatch) => {
    dispatch(loginRequest());
    axios
      .post(`${Server_URL}/login`, value)
      .then((res) => {
        if (res.data.Login) {
          const { usertype, token } = res.data;
          localStorage.setItem('usertype', usertype);
          localStorage.setItem('token', token);
          dispatch(loginSuccess(usertype, token));

          switch (usertype) {
            case 'admin':
            case 'Super Admin':
              navigate('/upload');
              break;
            case 'Site Admin':
              navigate('/uploadSiteData');
              break;
            case 'Regional Admin':
              navigate('/uploadRegionalData');
              break;
            case 'National Admin':
              navigate('/UploadNationalData');
              break;
            case 'oneday':
              navigate('/oneday');
              break;
            case 'participant':
              navigate('/participant');
              break;
            default:
              navigate('/'); // Fallback to home or other appropriate route
              break;
          }
        } else {
          dispatch(loginFailure('Username or password is incorrect'));
        }
      })
      .catch(() => {
        dispatch(loginFailure('Username or password is incorrect'));
      });
  };
};


// Action to handle logout
export const logout = () => {
  localStorage.removeItem('usertype');
  localStorage.removeItem('token');
  return {
    type: LOGOUT,
  };
};
