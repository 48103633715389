import * as FaIcons from "react-icons/fa";
const mapURL = process.env.REACT_APP_MAP_URL;

      
export const RegionData=[
    
      
    {
        title:'Addis Ababa City',
        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        region:'Addis Ababa ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        params2:'',        url:`${mapURL}`,
        params:'addis ababa',
        params1:'addis abeba Plantation',
        lng:'38.736926',
        lat:'8.995017',
        zoom:9.5,
        target_2019:'No_Data',
        planted_2019:'4.06',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'2,000,000',
        planted_2020:'2.35',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'1,000,000',
        planted_2021:'11.44',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2022:'No_Data',
        planted_2022:'10.65',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2023:'1,650,000',
        planted_2023:'15.59',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        // total_planted: (2095110 + 1763938 + 2177635).toString()
        // .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        total_planted:"44.09 Million"
               
  

               
    },

    {
        title:'Afar ',
        title_2023:'2023 Site',
        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        title_2024:'2024 Site',
sites:'sites',
        region:'Afar',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        path:'/Afar',
        params2:'',        url:`${mapURL}`,
        params:'afar',
        params1:'afar platation', 

        lng:'40.438706',
        lat:'12.108111',
       zoom:6.5, 
        target_2019:'No_Data',
        planted_2019:'0.59',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'6,000,000',
        planted_2020:'0',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'No_Data',
        planted_2021:'5.4',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2022:'No_Data',
        planted_2022:'4.09',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2023:'170,000',
        planted_2023:'0.66',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        total_planted: "10.74 Million"
    },

    {
        title:'Amhara ',
        title_2023:'2023 Site',
        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        title_2024:'2024 Site',
sites:'sites',
        region:'Amhara ',
        datatype:'vector data',
        owner:"#GreenLegacy", 
        owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,
        params2:'',        params:'amhara',
        params1:'amhara__plantation',
        lng:'37.380435',
        lat:'11.093420',
       zoom:6.5,
        
     target_2019:'No_Data',
        planted_2019:'1568',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'250,000,000',
        planted_2020:'1503.02',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'247,800,000',
        planted_2021:'1372.77',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2022:'250,000,000',
        planted_2022:'1370.87',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2023:'142,800,000',
        planted_2023:'1424.52',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        total_planted: "7.239 Billion"
               
    },

    {
        title:'Benshangule ',
        title_2023:'2023 Site',
        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        title_2024:'2024 Site',
sites:'sites',
        region:'Benshangule ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,
        params2:'',        params:'benshangul-gumaz',
        params1:'benishangul gumuz_plantation',
        lng:'36.122598',
        lat:'10.681689',
       zoom:6.5,
        
     target_2019:'No_Data',
        planted_2019:'14',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'5,000,000	',
        planted_2020:'39.33',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'7,000,000	',
        planted_2021:'17.59',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2022:'7,500,000	',
        planted_2022:'41.05',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2023:'5,490,000	',
        planted_2023:'39.72',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',

        total_planted: "151.69 Million"
    },
    {
        title:'Central Ethiopia ',

        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        region:'central ethiopia ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,

        params2:'',        params:'central ethiopia',
        params1:'centeral ethiopia region_plantation_site',
        lng:'37.917317',
        lat:'7.404123',
        zoom:7.5,
        
     target_2019:'No_Data',
        planted_2019:'No_Data',
        target_2020:'',
        planted_2020:'',
        target_2021:'',
        planted_2021:'',
        target_2022:'',
        planted_2022:'',
        target_2023:'',
        planted_2023:'',
        target_2024:'',
        planted_2024:'',

               
    },

    {
        title:'Diredawa city ',

        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        region:'dire dawa ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,
        params2:'',        params:'dire dawa',
        lng:'41.993275',
        lat:'9.606269',
       zoom:9,
       
     target_2019:'No_Data',
        planted_2019:'0.3',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'200,000',
        planted_2020:'1.16',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'500,000',
        planted_2021:'0.79',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2022:'No_Data',
        planted_2022:'0.15',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2023:'380,000',
        planted_2023:'1.31',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        total_planted:"3.71 Million" 
        
    },

    {
        title:'Gambela ',

        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        region:'Gambela ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,
        params2:'',        params:'gambela',
        params1:'gambela_plantation',
        lng:'34.911776',
        lat:'7.874042',
       zoom:7.5,
       
     target_2019:'No_Data',
        planted_2019:'2.13',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'1,060,390',
        planted_2020:'10.86',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'1,500,000',
        planted_2021:'5.67	',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2022:'No_Data',
        planted_2022:'8.43',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2023:'1,650,000',
        planted_2023:'15.54',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        total_planted: "42.63 Million"  
    },
    {
        title:'Hareri ',

        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        region:'Hareri ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,
        params2:'',        params:'harari people',
        params1:'harari_platation',
        lng:'42.159754',
        lat:'9.315854',
       zoom:10,
        
     target_2019:'No_Data',
        planted_2019:'0.88',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'300,000',
        planted_2020:'2.05',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'350,000',
        planted_2021:'0.58',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2022:'No_Data',
        planted_2022:'2.7',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2023:'190,000',
        planted_2023:'1.05',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        total_planted: "7.26 Million"   
    },
    {
        title:'Oromia ',
        title_2023:'2023 Site',

        title_2023:'2023 Site',


        title_2024:'2024 Site',
         sites:'sites',
        title_2024:'2024 Site',
         sites:'sites',
        region:'Oromia ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,
         params:'oromia',
        params1:'oromia plantation site 2016',
        params2:'oromia_site',
        lng:'39.727319',
        lat:'8.408117',
       zoom:6,
       
     target_2019:'No_Data',
        planted_2019:'18.84',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'304,400,000',
        planted_2020:'2786.44',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'351,735,001',
        planted_2021:'3667.19',
        target_2022:'400,000,000',
        total_site:'',
        area:'',
        one_day_planted:'',
        planted_2022:'4404.81',
        target_2023:'263,620,000',
        planted_2023:'4600.13',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        total_planted: "17.342 Billion"   
    },
    {
        title:'Sidama	 ',

        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        region:'sidama	 ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,

        params2:'',        params:'sidama	',
        params1:'sidama_plantation_site_polygon',
        lng:'38.522981',
        lat:'7.046679',
        zoom:7.5,
        
     target_2019:'No_Data',
        planted_2019:'0',
        target_2020:'',
        planted_2020:'0',
        target_2021:'',
        planted_2021:'274.05',
        target_2022:'',
        planted_2022:'274.1',
        target_2023:'',
        planted_2023:'305.9',
        target_2024:'',
        planted_2024:'',
        total_planted: "854.05"   

               
    },
    {
        title:'Somalia ',

        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        region:'somali ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,
        params2:'',        params:'somali',
        params1:'somalia_plantation',
        lng:'44.283895',
        lat:'7.455',
       zoom:6,
        
     target_2019:'No_Data',
        planted_2019:'1.2',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'880,357',
        planted_2020:'2.41',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'2,591,309',
        planted_2021:'7.06',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2022:'No_Data',
        planted_2022:'2.44',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2023:'2,750,000',
        planted_2023:'9.3',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        total_planted: "22.41 Million"      
    },
    {
        title:'South Ethiopia ',

        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        region:'South ethiopia ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,

        params2:'',        params:'south ethiopia',
        params1:'south_ethiopia_plantation_site',
        lng:'36.279404',
        lat:'5.142291',
        zoom:7,
         
     target_2019:'No_Data',
        planted_2019:'No_Data',
        target_2020:'',
        planted_2020:'',
        target_2021:'',
        planted_2021:'',
        target_2022:'',
        planted_2022:'',
        target_2023:'',
        planted_2023:'',
        target_2024:'',
        planted_2024:'',

               
    },
    {
        title:'South West Ethiopia ',

        title_2023:'2023 Site',

        title_2024:'2024 Site',
        region:'south west ethiopia ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,

        params2:'',        params:'south west ethiopia',
        params1:'southwest_ethiopia_region_plantation_site',
        lng:'35.640600',
        lat:'6.095882',
        zoom:7,
        
     target_2019:'No_Data',
        planted_2019:'0',
        target_2020:'',
        planted_2020:'0',
        target_2021:'',
        planted_2021:'0',
        target_2022:'',
        planted_2022:'168.29',
        target_2023:'',
        planted_2023:'161.21',
        target_2024:'',
        planted_2024:'',
        total_planted: "329.50"   

               
    },
 
    {
        title:'Tigray ',

        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        region:'Tigray ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,

        params2:'',        params:'tigray',
        params1:'tigray_plantation_site',
        lng:'38.271230',
        lat:'13.905121',
        zoom:7.5,
         
        target_2019:'',
        planted_2019:'39',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'',
        planted_2020:'80.36',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'',
        planted_2021:'0',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2022:'',
        planted_2022:'0',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2023:'',
        planted_2023:'5.77',
        total_site:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        total_planted:"125.13 Million",
    },
     {
        title:'SNNP ',

        title_2023:'2023 Site',

        title_2024:'2024 Site',
sites:'sites',
        region:'SSNP ',
        datatype:'vector data',
       owner:"#GreenLegacy", 
        language:"English",
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        url:`${mapURL}`,
        params2:'',        params:'snnp',
        lng:'38.77325783876083',
        lat:'8.987126236329033',
       zoom:5.5,

     target_2019:'No_Data',
        planted_2019:'1238',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2020:'60,000,000',
        planted_2020:'1498.05',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2021:'80,300,542',
        planted_2021:'1422.59',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2022:'No_Data',
        planted_2022:'930.33',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2023:'60,410,000',
        planted_2023:'876.02',
        total_site:'',
        area:'',
        one_day_planted:'',
        target_2024:'',
        planted_2024:'',
        total_site:'',
        area:'',
        one_day_planted:'',
        total_planted: "5.964 Billion"      
    },
   
]


