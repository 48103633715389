import React, { useState, useEffect } from 'react';
import { Spin, Pagination, Modal } from 'antd';
import { fetchImage, fetchVideo } from '../../actions/media';
import { useDispatch, useSelector } from "react-redux";

const ITEMS_PER_PAGE = 30; // Adjusted value for demonstration

const VideoGallery = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const { users = [], loading } = useSelector((state) => state.media);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVideo());
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedVideo(null);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentVideos = users.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <>
      <div className='center mediaTitle'>
        <h2 className='center'>Media</h2>
      </div>
      <div className='center spin'>
        {loading ? (
          <Spin tip="Loading" size="large" />
        ) : (
          <div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {currentVideos.map((video, index) => (
                <div key={index} style={{ margin: 10 }}>
                  <video
                    src={video.path}
                    alt={`Video ${index + 1}`}
                    style={{ width: 200, height: 200, objectFit: 'cover', cursor: 'pointer' }}
                    onClick={() => handleVideoClick(video)}
                  />
                  <p style={{ width: 200, objectFit: 'cover', cursor: 'pointer' }}>
                    {video.filename}
                  </p>
                </div>
              ))}
            </div>
            <Pagination
              current={currentPage}
              pageSize={ITEMS_PER_PAGE}
              total={users.length}
              onChange={handlePageChange}
              style={{ marginTop: 20, textAlign: 'center' }}
            />
          </div>
        )}
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleModalClose}
        centered
        bodyStyle={{ padding: 0 }}
        width={1000}
      >
        {selectedVideo && (
          <video
            src={selectedVideo.path}
            controls
            autoPlay
            style={{ width: '100%', height: 'auto', maxHeight: '80vh' }}
          >
            Your browser does not support the video tag.
          </video>
        )}
      </Modal>
    </>
  );
};

export default VideoGallery;
