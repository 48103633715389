import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

const contentStyle = {
  padding: 150,
  borderRadius: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const SteeringCommittee = () => {
  const [loading, setLoading] = useState(true);

  // Simulate data loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Stop loading after 2 seconds
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className='center mediaTitle'>
        <h2 className='center'>Steering Committee</h2>
      </div>
      <div className='center spin'>
        {loading ? (
          <Spin tip="Loading" size="large" />
        ) : (
          <div style={contentStyle}></div>
        )}
      </div>
    </>
  );
};

export default SteeringCommittee;
