import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto"; // Import Chart.js
import "chartjs-plugin-datalabels"; // Import datalabels plugin
import axios from "axios";
import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import { XYZ } from "ol/source";
import LayerSwitcher from "ol-layerswitcher";
import { FullScreen, defaults as defaultControls } from "ol/control.js";
import TileWMS from "ol/source/TileWMS";
import "ol/ol.css";
const Server_URL = process.env.REACT_APP_SERVER_URL;

const Metadata_pie_chart_2023 = () => {
  const [metadatas, setMetadatas] = useState([]);
  const [targetChartInstance, setTargetChartInstance] = useState(null);
  const [plantedChartInstance, setPlantedChartInstance] = useState(null);
  const targetChartRef = useRef(null);
  const plantedChartRef = useRef(null);
  const mapRef = useRef(null);
  const mapRef1 = useRef(null);

  useEffect(() => {
    const getMetadatas = async () => {
      try {
        const response = await axios.get(`${Server_URL}/regional_data`);
        setMetadatas(response.data);

        // console.log(response.data);
        // console.log(response.data);
      } catch (error) {
        console.error("Error fetching metadata:", error);
      }
    };

    getMetadatas();
  }, []);

  useEffect(() => {
    if (!metadatas || metadatas.length === 0) return;

    const eth_adm1_gid = metadatas
      .filter((metadata) => metadata.year === 2023)
      .map((metadata) => metadata.region);

    const targetData = metadatas
      .filter((metadata) => metadata.year === 2023)
      .map((metadata) => metadata.target);
    const plantedData = metadatas
      .filter((metadata) => metadata.year === 2023)
      .map((metadata) => metadata.planted);

    // console.log(targetData);
    // console.log(plantedData);
    // console.log(targetData);
    // console.log(plantedData);

    // Destroy existing chart instances if they exist
    if (targetChartInstance) {
      targetChartInstance.destroy();
    }

    if (plantedChartInstance) {
      plantedChartInstance.destroy();
    }

    if (targetChartRef.current) {
      // Create Target chart instance
      const ctx = targetChartRef.current.getContext("2d");
      const newTargetChartInstance = new Chart(ctx, {
        type: "pie",
        data: {
          labels: eth_adm1_gid,
          datasets: [
            {
              label: "Target",
              data: targetData,
              backgroundColor: generateRandomColors(targetData.length), // Generate random colors
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              color: "#fff",
              formatter: (value, ctx) => {
                return ctx.chart.data.labels[ctx.dataIndex] + "\n" + value;
              },
            },
          },
        },
      });

      setTargetChartInstance(newTargetChartInstance);
    }

    if (plantedChartRef.current) {
      // Create Planted chart instance
      const ctx = plantedChartRef.current.getContext("2d");
      const newPlantedChartInstance = new Chart(ctx, {
        type: "pie",
        data: {
          labels: eth_adm1_gid,
          datasets: [
            {
              label: "Planted",
              data: plantedData,
              backgroundColor: generateRandomColors(plantedData.length), // Generate random colors
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              color: "#fff",
              formatter: (value, ctx) => {
                return ctx.chart.data.labels[ctx.dataIndex] + "\n" + value;
              },
            },
          },
        },
      });

      setPlantedChartInstance(newPlantedChartInstance);
    }
  }, [metadatas]);

  // Function to generate random colors
  const generateRandomColors = (count) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      colors.push(color);
    }
    return colors;
  };

  const [selectedYear, setSelectedYear] = useState(""); // State to manage the selected year

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
    const dd = event.target.value;
    // Update selected year
  };

  return (
    <>
      <div className="all_pie">
        <div className="target_pie">
          <h3 className="Target_title">Target 2023</h3>
          <div>
            <canvas ref={targetChartRef} style={{ width: "400px", height: "400px" }}></canvas>
          </div>
        </div><br/>
        <div className="planted_pie">
          <h3 className="Target_title">Planted 2023</h3>
          <div>
            <canvas ref={plantedChartRef} style={{ width: "400px", height: "400px" }}></canvas>
          </div>
        </div>
      </div>
    </>
  );
};

export default Metadata_pie_chart_2023;
