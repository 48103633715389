import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const UserTypeRoute = ({ children, allowedUserTypes }) => {
  const token = useSelector((state) => state.authReducer.token);
  const usertype = useSelector((state) => state.authReducer.usertype);
  
  const location = useLocation();

  if (!token) {
    
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (!allowedUserTypes.includes(usertype)) {
    return <Navigate to="/unauthorized" state={{ from: location }} />;
  }

  return children;
};

UserTypeRoute.propTypes = {
  children: PropTypes.node.isRequired,
  allowedUserTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UserTypeRoute;
