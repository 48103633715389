import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoadingToRedirect = () => {
  const [countdown, setCountdown] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          navigate('/login');
          clearInterval(interval);
        }
        return prev - 1;
      });
    }, 100);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className="container text-center">
      <p> Loading...</p>
    </div>
  );
};

export default LoadingToRedirect;
