// src/actions/userActions.js
import axios from 'axios';

export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_NAME = 'SET_NAME';
export const SET_USER_TYPE = 'SET_USER_TYPE';
const Server_URL = process.env.REACT_APP_SERVER_URL;

export const setLoggedIn = (isLoggedIn) => ({
  type: SET_LOGGED_IN,
  payload: isLoggedIn,
});

export const setName = (name) => ({
  type: SET_NAME,
  payload: name,
});

export const setUserType = (userType) => ({
  type: SET_USER_TYPE,
  payload: userType,
});

// Async action to fetch token and update user state
export const fetchTokenAndUpdateUser = () => async (dispatch) => {
  // const token = localStorage.getItem("token");
  // if (token) {
  //   try {
  //     const response = await axios.get(`${Server_URL}/verify`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
     
  //     const { valid, fname, usertype } = response.data;
  //     if (valid && fname) {
  //       localStorage.setItem("usertype", usertype); // Store the user role
  //       dispatch(setLoggedIn(true));
  //       dispatch(setName(fname));
  //       dispatch(setUserType(usertype));
  //     } else {
  //       dispatch(setLoggedIn(false));
  //     }
  //   } catch (error) {
  //     console.error("Error verifying token:", error);
  //     dispatch(setLoggedIn(false));
  //   }
  // } else {
  //   dispatch(setLoggedIn(false));
  // }
};


