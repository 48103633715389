import React, { useEffect, useRef } from "react";
import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import LayerSwitcher from "ol-layerswitcher";
import logo from "../../asset/images/green_legacy_logo.png";
import ImageWMS from "ol/source/ImageWMS.js";
import { XYZ } from "ol/source";
import target from "../../asset/images/target.png";
import planted_1 from "../../asset/images/planted_1.png";
import {
  ScaleLine,
  defaults as defaultControls,
  FullScreen,
} from "ol/control.js";
import seed from "../../asset/images/green_legacy_logo.png";
import TileWMS from "ol/source/TileWMS";
import Fill from "ol/style/Fill.js";
import Stroke from "ol/style/Stroke.js";
import Style from "ol/style/Style.js";
import { createFromIconfontCN } from "@ant-design/icons";
import { Axios } from "axios";
import { Table } from "antd";
const mapURL = process.env.REACT_APP_MAP_URL;



function Region_map({ data }) {
  const columns = [
    {
      title: '',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: '2019',
      dataIndex: '2019',
      key: '2019',
    },
    {
      title: '2020',
      dataIndex: '2020',
      key: '2020',
    },
    {
      title: '2021',
      dataIndex: '2021',
      key: '2021',
    },
    {
      title: '2022',
      dataIndex: '2022',
      key: '2022',
    },
    {
      title: '2023',
      dataIndex: '2023',
      key: '2023',
    },
    {
      title: '2024',
      dataIndex: '2024',
      key: '2024',
    },
    {
      title: 'Total',
      dataIndex: 'Total',
      key: 'Total',
    },
  ];
  
  const tableData = [
    // {
    //   key: '1',
    //   category: 'Target',
    //   '2019': `${data.target_2019}`,
    //   '2020': `${data.target_2020}`,
    //   '2021': `${data.target_2021}`,
    //   '2022': `${data.target_2022}`,
    //   '2023': `${data.target_2023}`,
    //   '2024': `${data.target_2024}`,
    // },
    {
      key: '2',
      category: 'Planted',
      '2019': `${data.planted_2019}`,
      '2020': `${data.planted_2020}`,
      '2021': `${data.planted_2021}`,
      '2022': `${data.planted_2022}`,
      '2023': `${data.planted_2023}`,
      '2024': `${data.planted_2024}`,
      'Total':`${data.total_planted}`
    },
    
   
    // {
    //   key: '5',
    //   category: 'One Day Planted',
    //   '2019': '',
    //   '2020': '',
    //   '2021': '',
    //   '2022': '',
    //   '2023': '',
    //   '2024': '',
    // },
  ];
  // console.log(data);
  const wmsSource = new ImageWMS({
    url: `${mapURL}`,
    params: { LAYERS: `Green_Legacy:${data.params1}` },

    ratio: 1,
    serverType: "geoserver",
  });
  // console.log(wmsSource);
  // console.log(wmsSource);
  const updateLegend = function (resolution) {
    const graphicUrl = wmsSource.getLegendUrl(resolution);
    const img = document.getElementById("legend1");
    img.src = graphicUrl;
  };

  const mapRef = useRef(null);
  const IconFont = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
  });

  // console.log(data);
  // console.log(data);
  useEffect(() => {
    if (!mapRef.current) return;

    const map = new Map({
      controls: defaultControls().extend([new FullScreen()]),

      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
          title: "OSM",
          visible: true,
          // type: "base",
          // group: "base",
        }),
        new TileLayer({
          source: new XYZ({
            url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          }),
          title: "Satelite",
          visible: false,
          title: "satilite",
          // type: "base",
          // group: "base",
        }),
        new TileLayer({
          source: new TileWMS({
            url: `${mapURL}`,
            params: { LAYERS: "Green_Legacy:ethiopia_new_2016_all_regions", TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: "Ethiopia",
          title: "Ethiopia",
        }),
        new TileLayer({
          source: new TileWMS({
            url: data.url,
            params: { LAYERS: `Green_Legacy:${data.params}`, TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: `${data.title}`,
        }),
        new TileLayer({
          source: new TileWMS({
            url: data.url,
            params: { LAYERS: `Green_Legacy:${data.params2}`, TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: `${data.title_2023}`,
        }),
        new TileLayer({
          source: new TileWMS({
            url: data.url,
            params: { LAYERS: `Green_Legacy:${data.params1}`, TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: `${data.title_2024}`,
        }),
        // new TileLayer({
        //     source: new TileWMS({
        //       url: data.url,
        //       params: { LAYERS: `Green_Legacy:${data.region_site}`, TILED: true },
        //       serverType: "geoserver",

        //       type: "base",
        //       group: "base",
        //       visible: true,
        //       transition: 0,
        //     }),
        //     title: `${data.sites}`,
        //   }),
        // new TileLayer({
        //   source: new TileWMS({
        //     url: data.url,
        //     params: { LAYERS: `Green_Legacy:${data.params2}`, TILED: true },
        //     serverType: "geoserver",

        //     type: "base",
        //     group: "base",
        //     visible: true,
        //     transition: 0,
        //   }),
        //   title: `${data.title_2023}`,
        // }),
        // new TileLayer({
        //   source: new TileWMS({
        //     url: data.url,
        //     params: { LAYERS: `Green_Legacy:${data.params1}`, TILED: true },
        //     serverType: "geoserver",

        //     type: "base",
        //     group: "base",
        //     visible: true,
        //     transition: 0,
        //   }),
        //   title: `${data.title_2024}`,
        // }),
        // new TileLayer({
        //     source: new TileWMS({
        //       url: data.url,
        //       params: { LAYERS: `Green_Legacy:${data.region_site}`, TILED: true },
        //       serverType: "geoserver",

        //       type: "base",
        //       group: "base",
        //       visible: true,
        //       transition: 0,
        //     }),
        //     title: `${data.sites}`,
        //   }),
      ],
      view: new View({
        center: fromLonLat([data.lng, data.lat]),
        zoom: data.zoom,
      }),
    });
    // Initial legend
    const resolution = map.getView().getResolution();
    updateLegend(resolution);

    // Update the legend when the resolution changes
    map.getView().on("change:resolution", function (event) {
      const resolution = event.target.getResolution();
      updateLegend(resolution);
    });

    var layerSwitcher = new LayerSwitcher({
      reverse: false,
      activationMode: "click",
      groupSelectStyle: "children",
    });
    map.addControl(layerSwitcher);

    //Scale view
    var scaleLineMetric = new ScaleLine({
      units: "imperial",
      minWidth: 100,
      target: document.getElementById("scaleline-imperial"),
      text: true,
      steps: 4, // Number of steps
      bar: true, // Render the scale as a bar
      text: true, // Render the text indicating the scale
      className: "ol-scale-line", // CSS class name
      minWidth: 120, // Minimum width in pixels
    });
    map.addControl(scaleLineMetric);

    //full screen
    // var full_sc = new FullScreen({ label: "F" });
    // map.addControl(full_sc);

    const selectStyle = new Style({
      fill: new Fill({
        color: "#eeeeee",
      }),
      stroke: new Stroke({
        color: "rgba(255, 255, 255, 0.7)",
        width: 2,
      }),
    });
    var selected = null;
    const status = document.getElementById("coordinates");

    map.on("singleclick", function (evt) {
      const features = map.getFeaturesAtPixel(evt.pixel);
      const clickedCoordinate = evt.coordinate;
      clickedFeature(evt, map);
      map.getView().animate({
        center: clickedCoordinate,
        zoom: `${parseFloat(data.zoom) + 1}`, // Adjust zoom level as needed
        zoom: `${parseFloat(data.zoom) + 1}`, // Adjust zoom level as needed
        duration: 500 // Animation duration in milliseconds
      });
    });

    return () => map.dispose();
  }, [data]);
  function clickedFeature(evt, map) {
    const viewResolution = map.getView().getResolution();
    const viewProjection = map.getView().getProjection();
    const coordinate = evt.coordinate;
    const url = wmsSource.getFeatureInfoUrl(
      coordinate,
      viewResolution,
      viewProjection,
      {
        INFO_FORMAT: "application/json",
        FEATURE_COUNT: 2,
      }
    );

    if (url) {
      fetch(url)
        .then((response) => response.json())
        .then(function (json) {
          // Parse JSON response
          const name = {};
          const zone = {};
          const wereda = {};
          const kebele = {};
          const site = {};


          const Area = {};

          // console.log(json);
          name[""] = json["features"][0]["properties"]["region"];
          // console.log(json);
          name[""] = json["features"][0]["properties"]["region"];
          zone[""] = json["features"][0]["properties"]["zone"];
          wereda[""] = json["features"][0]["properties"]["wereda"];
          kebele[""] = json["features"][0]["properties"]["kebele"];
          site[""] = json["features"][0]["properties"]["site_name"];

          Area[""] = json["features"][0]["properties"]["area_ha"];

          // console.log(engtype)

          // Handle the response as per your requirement
          const container = document.getElementById(
            "container_click_popup_selected_region-region"
          );

          const regionName = name[""];
          const zoneName = zone[""];
          const weredaName = wereda[""];
          const kebeleName = kebele[""];
          const siteName = site[""];

          const AreaName = Area[""];

          document.getElementById(
            "container_click_popup_selected_region-title"
          ).innerHTML = "<b></b>";
          document.getElementById(
            "container_click_popup_selected_region-image"
          ).innerHTML = `<img src='${seed}' width=20px />`+ "<b><br/> Ethiopia Green Legacy Initiative</b>   ";

     
          document.getElementById("container_click_popup_selected_region-region").innerHTML = "<b> Region:</b>  " + '<span style="background-color:#209540;color:white;">'+ regionName + '</b>';

          document.getElementById(
            "container_click_popup_selected_region-zone"
          ).innerHTML =
            "<b> Zone:</b> " +
            zoneName +
            "</b>";
          document.getElementById(
            "container_click_popup_selected_region-wereda"
          ).innerHTML = "<b> Wereda:</b>  " + weredaName;
          document.getElementById(
            "container_click_popup_selected_region-kebele"
          ).innerHTML = "<b> Kebele:</b>  " + kebeleName;
          document.getElementById(
            "container_click_popup_selected_region-site"
          ).innerHTML = "<b> Site:</b>  " + siteName;
          document.getElementById(
            "container_click_popup_selected_region-area"
          ).innerHTML =
            "<b> Total Area of coverage:</b>   " +
            "<br/>" +
            " &nbsp;&nbsp;&nbsp; " +
            AreaName +
            "   hectare ";

          // document.getElementById(
          //   "container_click_popup_selected_region-target"
          // ).innerHTML =
          //   "<b> Total Target:</b>   " +
          //   "----";
          // document.getElementById(
          //   "container_click_popup_selected_region-planted"
          // ).innerHTML =
          //   "<b> Total Planted:</b>   " +
          //   "-----";
        })
        .catch((err) => {
          document.getElementById(
            "container_click_popup_selected_region"
          ).style.display = "none";

          // console.log(err);
        });
    }

    var modal = document.getElementById(
      "container_click_popup_selected_region"
    );
    modal.style.display = "block";
    document.getElementById(
      "container_click_popup_selected_region"
    ).style.width = "420px";
    document.getElementById(
      "container_click_popup_selected_region-title"
    ).style.width = "420px";
    document.getElementById(
      "container_click_popup_selected_region-content"
    ).style.width = "420px";
    document.getElementById("close-icon").style.display =
    "inline";
    window.onclick = function (event) {
      var modal = document.getElementById(
        "container_click_popup_selected_region"
      );
      if (event.target == modal) {
        modal.style.display = "none";
      }
    };
  }
  function container_click_popup_selected_region() {
    document.getElementById(
      "container_click_popup_selected_region"
    ).style.display = "none";
    document.getElementById(
      "container_click_popup_selected_region-title"
    ).style.width = "0";
    document.getElementById(
      "container_click_popup_selected_region-content"
    ).style.width = "0";
  }

  return (
    <>
      <div>
        <div className="row main_body title_region">
          <h3 style={{ marginLeft: "25px",marginTop:"10px", color: "black" }}>
            Region-
            <strong style={{ marginLeft: "5px" }}>{data.title}</strong>
          </h3>
          <div className="col-lg-9 seedings_year_img">
            <div
              ref={mapRef}
              className="home-map map_container_region"
              style={{ width: "100%", height: "450px" }}
            ></div>
            <div className="map_info">
              {/* <ul>
           <p><InfoCircleFilled /></p> <li>info</li>
           <p><UnorderedListOutlined /></p><li>Attribute</li>
           <p><ShareAltOutlined /></p><li>Share</li>
           <p> <MessageOutlined /> </p><li>Comments</li>

</ul> */}
              <hr />
           <div className="map_info_detail">

              <p>
                 <h2>{data.title} Seedling Planted detail (in Million) </h2>
                </p>
                </div>
                <Table columns={columns} dataSource={tableData} pagination={false} bordered />

              {/* <table style={{width:"100%"}}>
                  <tr>
                  <th>
                    <td></td>
                    <td>2019</td>
                    <td>2020</td>
                    <td>2021</td>
                    <td>2022</td>
                    <td>2023</td>
                    <td>2024</td>
                    </th>
                  </tr>
                
                <tr>
                    <tr>Target</tr>
                    <tr>Planted</tr>
                    <tr>Total Site</tr>
                    <tr>Area</tr>
                    <tr>One Day Planted</tr>
                </tr>
              </table> */}

              {/* <div className="map_info_detail">
                <p>
                 <h2>{data.title} Seedling Planted detail </h2>
                </p>
                <p>
                  Region:<span>{data.region}</span>
                </p>
                <p>Total no of Site:________</p>
                <p>Total no of planting:_______</p>

                <p>
                  Data Type:<span>{data.datatype}</span>
                </p>
               
                <p>
                  Catagory:<span>{data.catagory}</span>
                </p>
                <p>
                  Owner:<span>{data.owner}</span>
                </p>
               
              </div> */}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="sidenav_right">
              <hr />
              <h5>
                <a href="/metadata_detail" style={{ color: "white" }}>
                  {/* Metadata Detail */}
                </a>
              </h5>
              <hr />
              <h5>
                <a href="/metadata_detail" style={{ color: "white" }}>
                  {/* Download Metadata */}
                </a>{" "}
              </h5>
              <hr />
              <h4>Legend</h4>

              <div>
                <img id="legend1" />
              </div>

              <hr />
              <p style={{fontSize:"25px",backgroundColor:"#05376b",color:"#fff",fontWeight:"bold",textAlign:"center"}}>2019-2023</p>
              <p>
                <h4>
                  {data.title}
                </h4>
              </p>

              <p>Total Planted:<b>{data.total_planted}</b></p>

              <hr />
              <h4 style={{ fontWeight: "bold" }}>About</h4>
              <p>Owner,point of Contact,Metadata Author</p>
              <div className="map_info">
                <ul>
                  <p>
                    <img src={logo} className="circle-image" />
                  </p>
                </ul>
                <p> Green Legacy Initiative(GLI)</p>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>

      <div
        id="container_click_popup_selected_region"
        className="container_click_popup_selected_region"
      >
        <div
          className="close-icon"
          id="close-icon"
          onClick={container_click_popup_selected_region}
        >
          &times;
        </div>
        <div id="container_click_popup_selected_region-title"></div>

        <div id="container_click_popup_selected_region-content">
          <div
            id="container_click_popup_selected_region-region"
            className="container_list"
          ></div><hr/>
          <div
            id="container_click_popup_selected_region-zone"
            className="container_list"
          ></div>
          <div
            id="container_click_popup_selected_region-wereda"
            className="container_list"
          ></div>
          <div
            id="container_click_popup_selected_region-kebele"
            className="container_list"
          ></div>
          <div
            id="container_click_popup_selected_region-site"
            className="container_list"
          ></div><hr/>
          <div
            id="container_click_popup_selected_region-area"
            className="container_list"
          ></div>
        </div>
        <div id="container_click_popup_selected_region-image"></div>

        <hr />
        <div id="container_click_popup_selected_region-target"></div>
        <div id="container_click_popup_selected_region-planted"></div>
      </div>
    </>
  );
}
export default Region_map;
