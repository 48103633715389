import { LOGOUT, SET_VALUE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from '../../actions/auth';
const initialState = {
  value: { email: '', password: '' },
  loading: false,
  error: '',
  token: localStorage.getItem('token') || null,
  usertype: localStorage.getItem('usertype') || null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        usertype: action.payload.usertype,
        token: action.payload.token,
        error: null, // Clear any previous errors on success
      };
    case LOGIN_FAILURE:
        console.log('Reducer error payload:', action.payload);
        return { ...state, loading: false, error: action.payload };
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('usertype');
      return {
        ...state,
        token: null,
        usertype: null,
      };
    case SET_VALUE:
      return {
        ...state,
        value: { ...state.value, [action.payload.name]: action.payload.value },
      };
    default:
      return state;
  }
};

export default authReducer;
