import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, message, Popconfirm, Form, Input, Modal } from "antd";
import { fetchOneDayData, deleteUser, updateUser } from "../../actions/oneDay";

const OneDayList = () => {
  const dispatch = useDispatch();
  const { oneDay, loading, error } = useSelector((state) => state.oneDay);
  const [editingUser, setEditingUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const usersArray = oneDay || [];

  useEffect(() => {
      dispatch(fetchOneDayData());

  }, [dispatch]);

  const totalPlanted = usersArray.reduce((total, user) => total + (user.planted || 0), 0);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // }

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div style={{ textAlign: "center" }}>
            <table
              style={{
                borderCollapse: "collapse",
                width: "80%",
                margin: "auto",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Year
                  </th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Planted
                  </th>
                </tr>
              </thead>
              <tbody>
                {oneDay && oneDay.length > 0 ? (
                  oneDay.map((user, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        {user.year}
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        {user.planted.toLocaleString()}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" style={{ border: "1px solid black", padding: "8px" }}>
                      Loading ...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default OneDayList;
