import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, message, Popconfirm, Form, Input, Modal } from "antd";
import { fetchRegions, deleteRegion, updateRegion } from "../../actions/regionalAdmin";

const ITEMS_PER_PAGE = 10;

const Region_list = () => {
  const dispatch = useDispatch();
  const { regions, loading, error } = useSelector((state) => state.region);
  const [editingRegion, setEditingRegion] = useState(null);
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchRegions());
  }, [dispatch]);

  const showEditModal = (region) => {
    setEditingRegion(region);
    setVisible(true);
    form.setFieldsValue({
      id: region.id,
      region: region.region,
      year: region.year,
      target: region.target,
      planted: region.planted,
    });
  };

  const confirmDelete = (regionId) => {
    dispatch(deleteRegion(regionId));
  };

  const cancelDelete = () => {
    message.info("Deletion canceled");
  };

  const handleUpdate = async () => {
    try {
      const id = form.getFieldValue("id");
      if (!id) {
        console.error("No region ID provided for updating");
        return;
      }
      const values = await form.validateFields();
      const updatedRegion = { ...editingRegion, ...values };
      await dispatch(updateRegion(updatedRegion));
      message.success("Region updated successfully");
      setVisible(false);
      setEditingRegion(null);
      form.resetFields();
      dispatch(fetchRegions());

    } catch (error) {
      console.error("Error updating region:", error);
      message.error("Failed to update region");
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setEditingRegion(null);
    form.resetFields();
  };

  const handleClickNext = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(regions.length / ITEMS_PER_PAGE))
    );
  };

  const handleClickPrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const selectedRegions = regions.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div style={{ textAlign: "center" }}>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                margin: "auto",
                fontSize:"20px"
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Region
                  </th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Years
                  </th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Target
                  </th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Planted
                  </th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedRegions.map((region, index) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {region.region}
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {region.year}
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {region.target}
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {region.planted}
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      <Button
                        className="btn btn-primary"
                        onClick={() => showEditModal(region)}
                      >
                        Update
                      </Button>
                      <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => confirmDelete(region.id)}
                        onCancel={cancelDelete}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button className="btn btn-danger">Delete</Button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ margin: "20px 0", textAlign: "center" }}>
              <Button
                onClick={handleClickPrev}
                disabled={currentPage === 1 || loading}
              >
                Previous
              </Button>
              <span style={{ margin: "0 10px" }}>
                Page {currentPage} of{" "}
                {Math.ceil(regions.length / ITEMS_PER_PAGE)}
              </span>
              <Button
                onClick={handleClickNext}
                disabled={
                  currentPage === Math.ceil(regions.length / ITEMS_PER_PAGE) ||
                  loading
                }
              >
                Next
              </Button>
            </div>
          </div>

          <Modal
            title="Edit Region"
            visible={visible}
            onCancel={handleCancel}
            footer={[
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={handleUpdate}
                loading={loading}
              >
                Update
              </Button>,
            ]}
          >
            <Form form={form} layout="vertical">
              <Form.Item name="id" style={{ display: "none" }}>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                name="region"
                label="Region"
                rules={[
                  { required: true, message: "Please input the region!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="year"
                label="Year"
                rules={[{ required: true, message: "Please input the year!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="target"
                label="Target"
                rules={[
                  {
                    required: true,
                    message: "Please input the target number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="planted"
                label="Planted"
                rules={[
                  {
                    required: true,
                    message: "Please input the planted number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Region_list;
