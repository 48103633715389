import React, { useState, useEffect } from "react";
import { Table } from "antd";
import axios from "axios";
import "../../css/App.css"; // Import your CSS file where you define row colors
const Server_URL = process.env.REACT_APP_SERVER_URL;

const Table_data1 = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Server_URL}/regional_data`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const transformData = (data) => {
    const transformedData = {};
    data.forEach((entry) => {
      const { year, target, planted, region } = entry;
      function formatNumberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (!transformedData[region]) {
        transformedData[region] = { region };
      }
      if (
        target !== null &&
        target !== undefined &&
        planted !== null &&
        planted !== undefined
      ) {
        transformedData[region][`target_${year}`] =
          formatNumberWithCommas(target);
        transformedData[region][`planted_${year}`] =
          formatNumberWithCommas(planted);
      } else {
        transformedData[region][`target_${year}`] = "No_Data"; // Or any default value you prefer
        transformedData[region][`planted_${year}`] = "No_Data"; // Or any default value you prefer
      }
    });
    return Object.values(transformedData);
  };

  const columns = [
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
    // {
    //   title: '2019 Target',
    //   dataIndex: 'target_2019',
    //   key: 'target_2019',
    // },
    {
      title: '2019 Planted',
      dataIndex: 'planted_2019',
      key: 'planted_2019',
    },
 
    {
      title: "2020 Planted",
      dataIndex: "planted_2020",
      key: "planted_2020",
    },
   
    {
      title: "2021 Planted",
      dataIndex: "planted_2021",
      key: "planted_2021",
    },
    
    {
      title: "2022 Planted",
      dataIndex: "planted_2022",
      key: "planted_2022",
    },
    
    {
      title: "2023 Planted",
      dataIndex: "planted_2023",
      key: "planted_2023",
    },
    // {
    //   title: '2024 Target',
    //   dataIndex: 'target_2024',
    //   key: 'target_2024',
    // },
    {
      title: '2024 Planted',
      dataIndex: 'planted_2024',
      key: 'planted_2024',
    },
    // Add columns for other years
  ];

  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const getColumnClassName = (year) => {
    return `column-${year}`;
  };

  return (
    <>
      <h3 style={{textAlign:"center"}}>SEEDLING PLANTING DETAIL</h3>

    <div style={{border:"2px solid #d0cccc",borderRadius:"5px",marginTop:"30px",marginRight:"30px"
    
}}>
    <Table
      columns={columns.map((column) => ({
        ...column,
        className: getColumnClassName(column.key),
      }))}
      dataSource={transformData(data)}
      rowClassName={getRowClassName}
      pagination={false} // Remove pagination if not needed

      // pagination={true} // Remove pagination if not needed
      // pagination={{ pageSize: 5 }} // Pagination configuration
    />
    </div>
    </>

   
  );
};

export default Table_data1;
