// authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import {jwt_decode, jwtDecode} from 'jwt-decode';

const initialState = {
  token: localStorage.getItem('token') || null,
  usertype: localStorage.getItem('usertype') || null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action) {
      const token = action.payload;
      const isExpired = isTokenExpired(token);

      if (isExpired) {
        state.token = null;
        state.isAuthenticated = false;
        localStorage.removeItem('token');
        localStorage.removeItem('usertype');
      } else {
        state.token = token;
        state.isAuthenticated = true;
        localStorage.setItem('token', token);
        localStorage.setItem('usertype', jwtDecode(token).usertype);
      }
    },
    logout1(state) {
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem('token');
      localStorage.removeItem('usertype');
    },
  },
});

export const { setToken, logout1 } = authSlice.actions;
export default authSlice.reducer;


export const isTokenExpired = (token) => {
    if (!token || typeof token !== 'string') return true; // Validate that the token is a string
  
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error("Invalid token:", error);
      return true; // Treat as expired if there's an error in decoding
    }
  };
  
