import React from 'react';
import { Button, Result } from 'antd';
const No_found = () => (
  <Result
    status="403"
    title="403"
    subTitle="Sorry, no page."
    extra={<Button type="primary"><a href='./'>Back Home</a></Button>}
  />
);
export default No_found;