import axios from "axios";

// Action types
export const SET_VALUE = 'SET_VALUE';
export const SET_NAME = 'SET_NAME';
export const SET_ID = 'SET_ID';
export const SET_USERS = 'SET_USERS';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_SHOW_DATA = 'SET_SHOW_DATA';
export const SET_CLICKED_DATA = 'SET_CLICKED_DATA';
export const SET_EDITING_USER = 'SET_EDITING_USER';
export const SET_VISIBLE = 'SET_VISIBLE';
export const ADD_ANNUAL_SUCCESS = 'ADD_ANNUAL_SUCCESS';
export const ADD_ANNUAL_DATA_ERROR = 'ADD_ANNUAL_DATA_ERROR';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

const Server_URL = process.env.REACT_APP_SERVER_URL;

// Action creators
export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = (users) => ({
  type: FETCH_USERS_SUCCESS,
  payload: users,
});

export const fetchUsersFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  payload: error,
});

export const deleteUserSuccess = (userId) => ({
  type: DELETE_USER_SUCCESS,
  payload: userId,
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

// Thunks
export const fetchUsers = () => async (dispatch) => {
  dispatch(fetchUsersRequest());
  try {
    const response = await axios.get(`${Server_URL}/annual_data`);
    dispatch(fetchUsersSuccess(response.data));
  } catch (error) {
    dispatch(fetchUsersFailure(error.message));
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  try {
    await axios.delete(`${Server_URL}/annual_data/${userId}`);
    dispatch(deleteUserSuccess(userId));
  } catch (error) {
    console.error('Error deleting user:', error);
  }
};

export const updateUser = (user) => async (dispatch) => {
  try {
    const response = await axios.put(`${Server_URL}/annual_data/${user.id}`, user);
    dispatch(updateUserSuccess(response.data));
  } catch (error) {
    console.error('Error updating user:', error);
  }
};
export const setValue = (name, value) => ({
  type: SET_VALUE,
  payload: { name, value },
});
export const setLoggedIn = (login) => ({
    type: SET_LOGGED_IN,
    payload: login,
  });
export const setName = (name) => ({
  type: SET_NAME,
  payload: name,
});
export const setUserType = (usertype) => ({
    type: SET_USER_TYPE,
    payload: usertype,
  });
export const setId = (id) => ({
  type: SET_ID,
  payload: id,
});
export const setUsers = (users) => ({
  type: SET_USERS,
  payload: users,
});
export const setShowData = (showData) => ({
  type: SET_SHOW_DATA,
  payload: showData,
});
export const setClickedData = (clickedData) => ({
  type: SET_CLICKED_DATA,
  payload: clickedData,
});
export const setEditingUser = (editingUser) => ({
  type: SET_EDITING_USER,
  payload: editingUser,
});
export const setVisible = (visible) => ({
  type: SET_VISIBLE,
  payload: visible,
});
export const addUserSuccess = (message) => ({
    type: ADD_ANNUAL_SUCCESS,
    payload: message,
  });
export const addUserError = (message) => ({
    type: ADD_ANNUAL_DATA_ERROR,
    payload: message,
  });

  export const AddAnnual = (value) => async (dispatch) => {
    // Validate input
    if (!value || !value.year || !value.target || !value.planted) {
      console.error("Invalid or empty data provided:", value);
      dispatch(addUserError("Invalid or empty data provided"));
      return;
    }
  
    try {
      const res = await axios.post(`${Server_URL}/annual_data/`, value);
      // console.log("Response:", res);
      dispatch(addUserSuccess("Data added successfully!"));
    } catch (err) {
      // console.error("Error:", err);
      alert("Error adding data!");
    }
  };

export const fetchToken = () => async (dispatch) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const response = await axios.get(`${Server_URL}/verify`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const { valid, fname, id } = response.data;

      if (valid && fname && id) {
        dispatch(setName(fname));
        dispatch(setId(id));
      } else {
        window.location.href = "/login";
      }
    } catch (error) {
      console.error("Error verifying token:", error);
      dispatch(addUserSuccess("User added successfully!"));
      window.location.href = "/login";
    }
  } else {
    window.location.href = "/login";
  }
};
