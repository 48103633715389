import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie"; // Import the cookies library

import {
  Button,
  Menu,
  Layout,
  Dropdown,
  message,
  Form,
  Input,
  Modal,
} from "antd";
import ReactDOM from "react-dom";
import UploadRegionalData from "../regionalAdmin/upload_regional_data";



import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  DatabaseOutlined,
  CaretDownOutlined
} from "@ant-design/icons";
import Logo from "../../asset/images/green_legacy_logo.png";
import { useNavigate } from "react-router-dom";
import SiteAdmin from "./siteAdmin";
const Server_URL = process.env.REACT_APP_SERVER_URL;

const { Header, Sider, Content } = Layout;

const UploadSiteData = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [id, setId] = useState("");

  const [showdata, setdata] = useState(false);
  const [clickedData, setClickedData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [value, setValue] = useState({
    target: "",
    planted: "",
    area: "",
    region: "",
    siteName: "",
  });
  const [name, setName] = useState("");
  const Server_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchToken = async () => {
      const token = localStorage.getItem("token");
  
      if (token) {
        try {
          const response = await axios.get(`${Server_URL}/verify`, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          // console.log("Token response:", response.data);
          const { valid, fname ,id } = response.data;

          if (valid && fname && id) {
            setName(fname);
            setId(id);
          } else {
            window.location.href = "/login";
          }
        } catch (error) {
          console.error("Error verifying token:", error);
          window.location.href = "/login";
        }
      } else {
        window.location.href = "/login";
      }
    };
  
    fetchToken();
  }, []);
  const handleChange = (name, e) => {
    setValue({ ...value, [name]: e.target.value });
  };
  const handleClick = (item) => {
    let componentToRender;

    switch (item) {
    
      case "Upload Site Data":
        componentToRender = <SiteAdmin />;
        break;
      case "View site data":
        componentToRender = <SiteAdmin />;
        break;
      
      default:
        componentToRender = <SiteAdmin />;
        break;
    }

    ReactDOM.render(componentToRender, document.getElementById("upload_form"));
  };
  const handleLogout = () => {
    try {
      Cookies.remove("token");
      localStorage.removeItem("token");
      console.log("Token removed:", localStorage.getItem("token"));
      navigate("/login");
    } catch (error) {
      console.error("Error removing token:", error);
    }
  };
  const ModelhandleChangePassword = (user) => {
    console.log(name);
    setEditingUser(user);
    setVisible(true);
    form.setFieldsValue({
      name: name,
      Current_password: "Current_password",
      New_password: "New_password",
    });
  };
  const AddMetadata = (e) => {
    e.preventDefault();
    axios
      .put(`${Server_URL}/metadata/${value.region}`, value)
      .then((res) => {
        console.log(res);
        window.location.href = "/metadata";
        alert("User added successfully!");
      })
      .catch((err) => console.log(alert("User added error!", err)));
  };
  const handleChangePassword = async () => {
    console.log(name, id);
    try {
      const oldPassword = form.getFieldValue("oldPassword");

      const NewPassword = form.getFieldValue("newPassword");
      console.log(oldPassword,NewPassword);
      if (!NewPassword && oldPassword) {
        console.error("No password provided for updating");
        return;
      }
      const values = await form.validateFields();
      const updatedUser = { ...editingUser, ...values };
      console.log("ggggg",values);
      // Assuming your backend has an endpoint for updating user passwords
      const response = await axios.put(`${Server_URL}/getuserusersadmin@639adminUpdatePassword/${id}`,values);
      console.log("d",response);
      message.success("Password updated successfully");
      form.resetFields();
      setUsers(response.data);
    } catch (error) {
      console.error("Error updating password:", error);
      message.error("Failed to update password");
    }
  };
  const handleCancel = () => {
    setVisible(false);
    setEditingUser(null);
    form.resetFields();
  };
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={ModelhandleChangePassword}>
             {/* profile */}
             </Menu.Item>
      <Menu.Item key="2" onClick={ModelhandleChangePassword}>
        Change Password
      </Menu.Item>
      <Menu.Item key="3" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <>
       <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ width: "50px", backgroundColor: "white" }}
        >
          <div className="d-flex">
            <a href="/">
              {" "}
              <img src={Logo} style={{ width: "200px", float: "left" }} />
            </a>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
                float: "right",
              }}
            />
          </div>
          <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
            
          
            <Menu.Item
              key="1"
              onClick={(e) => handleClick("Upload Site Data")}
            >
              Upload Site Data
            </Menu.Item>
          
            <hr />
            <Menu.Item
              key="2"
              onClick={(e) => handleClick("Upload Site Data")}
            >
              View Site Data
              </Menu.Item>
           
          </Menu>
        </Sider>

        <Layout>
          <Header
            style={{
              padding: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button type="text" style={{ color: "white" }}>
                  {name}<CaretDownOutlined/>
                </Button>
              </Dropdown>
            </div>
          </Header>

          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
            }}
          >
            <div className="row">
              <div className="col-lg-12">
                <div id="upload_form">
                  <SiteAdmin />
                </div>
                {showdata && <UploadRegionalData data={clickedData} />}
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>

      <Modal
        title="Edit password"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleChangePassword}>
            Update
          </Button>,
        ]}
      >
        {name}
        <Form form={form} layout="vertical">
      <Form.Item name="oldPassword" label="Current Password">
        <Input
          value={value.old}
          onChange={(e) => setValue({ ...value, old: e.target.value })}
        />
      </Form.Item>
      <Form.Item name="newPassword" label="Nebbw Password">
        <Input
          value={value.new}
          onChange={(e) => setValue({ ...value, new: e.target.value })}
        />
      </Form.Item>
    </Form>
      </Modal>
    </>
   
  );
};

export default UploadSiteData;
