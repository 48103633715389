import React, { useState, useEffect } from 'react';
import { Modal, Upload, Button, message, Form, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setValue, addImage, fetchImage } from '../../actions/media';
import Image_list from './image_List';

const ImageUpload = () => {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);
    const { users, successMessage, errorMessage } = useSelector((state) => state.media);
    const [isModalVisible, setIsModalVisible] = useState(false);

    // This useEffect will run when successMessage changes
    useEffect(() => {
        if (successMessage) {
            // Set a timer to clear the successMessage after 3 seconds
            const timer = setTimeout(() => {
                // Clear the successMessage by dispatching an action to reset it
                dispatch(setValue({ ...users, successMessage: '' }));
            }, 3000);

            // Cleanup the timer if the component unmounts or successMessage changes
            return () => clearTimeout(timer);
        }
    }, [successMessage, dispatch, users]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChange = info => {
        let newFileList = [...info.fileList];
        newFileList = newFileList.slice(-1);
        setFileList(newFileList);

        if (newFileList.length > 0) {
            dispatch(setValue({ ...users, image: newFileList[0].originFileObj }));
        }
    };

    const beforeUpload = (file) => {
        const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isImage) {
            message.error('You can only upload JPG/PNG files!');
        }
        return isImage || Upload.LIST_IGNORE;
    };

    const onFinish = (values) => {
        if (fileList.length === 0) {
            message.error('Please select an image.');
            return;
        }

        const formData = new FormData();
        formData.append('filename', values.filename);
        formData.append('image', fileList[0].originFileObj);

        dispatch(addImage(formData)).then(() => {
            setIsModalVisible(false);
            dispatch(fetchImage());
        });
    };

    return (
        <>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            
            <Button type="primary" onClick={showModal}>
                Upload New Image
            </Button>

            <Modal
                title="Upload Image"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={users}
                >
                    <Form.Item
                        label="Title"
                        name="filename"
                        rules={[{ required: true, message: 'Please input Title!' }]}
                    >
                        <Input
                            placeholder="Title"
                            onChange={(e) => dispatch(setValue({ ...users, filename: e.target.value }))}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Image"
                        rules={[{ required: true, message: 'Please select an image!' }]}
                    >
                        <Upload
                            accept="image/jpeg,image/png" // Only accept JPEG and PNG formats
                            listType="picture"
                            fileList={fileList.map(file => ({ ...file, key: file.uid }))}
                            onChange={handleChange}
                            beforeUpload={beforeUpload} // Validate the file type before uploading
                        >
                            <Button icon={<UploadOutlined />}>Select Image</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={fileList.length === 0}
                        >
                            Upload
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            
            <Image_list />
        </>
    );
};

export default ImageUpload;
