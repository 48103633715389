import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import React from "react";
import ImageSlide from "../components/homePage/imageSlide";
import AnnualCard from "../components/homePage/annualCard";

const HomeIndex = () => {
 
  return (
    <>
     <ImageSlide/>
      <div className="text_container">
        <h2 className="text_header">#GreenLegacy</h2>
        <p className="text_paragraph">
        Since the launching of the GLI, more than 32 billion forest, agro-forest and ornamental seedlings are planted
        </p>
        <div className="text_divider"></div>
      </div>
    <AnnualCard/>

     
    </>
  );
};

export default HomeIndex;
