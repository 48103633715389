// reducers/users.js
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
} from "../../actions/users";

// Action Types
const SET_LOGGED_IN = "SET_LOGGED_IN";
const SET_NAME = "SET_NAME";
const SET_USER_TYPE = "SET_USER_TYPE";
const SET_VALUE = "SET_VALUE";
const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
const ADD_USER_ERROR = "ADD_USER_ERROR";

// Initial State
const initialState = {
  users: [],
  loading: false,
  error: null,
  isLoggedIn: false,
  name: "",
  userType: "",
  successMessage: "",
  errorMessage: "",
  value: {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    password: "",
    usertype: "",
    gender: "",
  },
};

// Reducer
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    case SET_VALUE:
      return {
        ...state,
        value: {
          ...state.value,
          [action.payload.name]: action.payload.value,
        },
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        successMessage: action.payload,
        errorMessage: "",
      };
    case ADD_USER_ERROR:
      return {
        ...state,
        successMessage: "",
        errorMessage: action.payload,
      };

    case FETCH_USERS_REQUEST:
      return { 
        ...state, 
        loading: true 
      };
    case FETCH_USERS_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        users: action.payload 
      };
    case FETCH_USERS_FAILURE:
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.id ? action.payload.user : user
        ),
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    default:
      return state;
  }
};

export default userReducer;
