import axios from "axios";

export const SET_VALUE = 'SET_VALUE';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
const Server_URL = process.env.REACT_APP_SERVER_URL;

const SET_USER_TYPE = 'SET_USER_TYPE';
const SET_NAME = 'SET_USER_TYPE';
const SET_LOGGED_IN = 'SET_LOGGED_IN';



export const fetchUsers = () => async (dispatch) => {
  dispatch({ type: FETCH_USERS_REQUEST });

  try {
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    const response = await axios.get(`${Server_URL}/user`, {
      headers: {
        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
      },
    });

    dispatch({ type: FETCH_USERS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_USERS_FAILURE, error });
  }
};


export const updateUser = (id, user) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage

    await axios.put(`${Server_URL}/user/${id}`, user,{
      headers: {
        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
      },
    });

    dispatch({ type: UPDATE_USER_SUCCESS, payload: { id, user } });
  } catch (error) {
    console.error('Error updating user:', error);
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    await axios.delete(`${Server_URL}/user/${id}`,{
      headers: {
        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
      },
    });

    dispatch({ type: DELETE_USER_SUCCESS, payload: id });
  } catch (error) {
    console.error('Error deleting user:', error);
  }
};

export const setLoggedIn = (isLoggedIn) => ({
  type: SET_LOGGED_IN,
  payload: isLoggedIn,
});
export const setValue = (name, value) => ({
  type: SET_VALUE,
  payload: { name, value },
});
export const setUserType = (userType) => ({
      type: SET_USER_TYPE,
      payload: userType,
    });
export const setName = (name) => ({
  type: SET_NAME,
  payload: name,
});
export const addUserSuccess = (message) => ({
  type: ADD_USER_SUCCESS,
  payload: message,
});
export const addUserError = (message) => ({
  type: ADD_USER_ERROR,
  payload: message,
});

export const addUser = (userData) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage

    const res = await axios.post(`${Server_URL}/user/`, userData, {
      headers: {
        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
      },
    });
    dispatch(addUserSuccess("User added successfully!"));
    // window.location.href = "/upload";
  } catch (err) {
    dispatch(addUserError("Invalid or empty data provided"));
    console.error("User added error!", err);
  }
};
