import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import React, { useEffect } from "react";
import { Space, Card } from "antd";
import tree from "../../asset/images/Tree-hands.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../actions/annualAdmin";

const AnnualCard = () => {
  const { users, loading, error } = useSelector((state) => state.annual);
  const dispatch = useDispatch();
  const target_2019 =users[5]?.target ? (users[5].target / 1000000000).toFixed(1) + " Billion": "N/A";
  const planted_2019 = users[5]?.planted ? (users[5].planted / 1000000000).toFixed(1) + " Billion": "N/A";
  const target_2020 = users[4]?.target ? (users[4].target / 1000000000).toFixed(1) + " Billion": "N/A";
  const planted_2020 = users[4]?.planted ? (users[4].planted / 1000000000).toFixed(1) + " Billion": "N/A";
  const target_2021 = users[3]?.target ? (users[3].target / 1000000000).toFixed(1) + " Billion": "N/A";
  const planted_2021 = users[3]?.planted ? (users[3].planted / 1000000000).toFixed(1) + " Billion": "N/A";
  const target_2022 = users[2]?.target ? (users[2].target / 1000000000).toFixed(1) + " Billion": "N/A";
  const planted_2022 = users[2]?.planted ? (users[2].planted / 1000000000).toFixed(1) + " Billion": "N/A";
  const target_2023 = users[1]?.target ? (users[1].target / 1000000000).toFixed(1) + " Billion": "N/A";
  const planted_2023 = users[1]?.planted ? (users[1].planted / 1000000000).toFixed(1) + " Billion": "N/A";
  
  const formatValue = (value) => {
    if (value != 0) {
      return (value / 1000000000).toFixed(1) + " Billion";

    }
    else {
      return "----";


  }
  };
   
  
  // Access the target and planted values for 2024
  const targetValue2024 = users[0]?.target; // Use 0 if undefined
  const plantedValue2024 = users[0]?.planted ?? 0; // Use 0 if undefined
  
  // Format the target and planted values
  const target_2024 = formatValue(targetValue2024);
  const planted_2024 = formatValue(plantedValue2024);
  


  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <>

   

      <div className="annualCard">
        <Space className="anualSpace">
            <div className="row home_card_container_row">
              <h2><span className="title_line"><span className="text_header_annual"> Annual Tree Targets and Planted</span></span>
              </h2>
              <Card
                className="cardOneDay"
                title={
                  <span className="annual_year">
                    <img src={tree} />
                    <a href="./annual">2019</a>
                  </span>
                }
                style={{
                  width: 305,
                  height: 280,
                  background: "linear-gradient(to right, #54873B, #94c93d)",
                  fontWeight: "bold",
                  border: "1px solid white",
                  borderRadius: "25px",
                  boxShadow: "0 2px 4px black",
                }}
              >
                <p className="annual_data">
                  Target: <span>{target_2019}</span>
                </p>
                <p className="annual_data">
                  Planted: <span>{planted_2019}</span>{" "}
                </p>
              </Card>
              <Card
                className="cardOneDay"
                title={
                  <span className="annual_year">
                    {" "}
                    <img src={tree} />
                    <a href="./annual">2020</a>{" "}
                  </span>
                }
                style={{
                  width: 305,
                  height: 280,
                  background: "linear-gradient(to right, #3c2e03, #a67c00)",
                  fontWeight: "bold",
                  borderRadius: "25px",
                  boxShadow: "0 2px 4px black",
                }}
              >
                <p className="annual_data">
                  Target: <span>{target_2020}</span>{" "}
                </p>
                <p className="annual_data">
                  Planted: <span>{planted_2020} </span>
                </p>
              </Card>
              <Card
                className="cardOneDay"
                title={
                  <span className="annual_year">
                    {" "}
                    <img src={tree} /> <a href="./annual">2021</a>
                  </span>
                }
                style={{
                  width: 305,
                  height: 280,
                  background: "linear-gradient(to right, #2980b9, #6dd5fa)",
                  fontWeight: "bold",
                  borderRadius: "25px",
                  boxShadow: "0 2px 4px black",
                }}
              >
                <p className="annual_data">
                  Target: <span>{target_2021}</span>{" "}
                </p>
                <p className="annual_data">
                  Planted: <span>{planted_2021} </span>
                </p>
              </Card>
              <Card
                className="cardOneDay"
                title={
                  <span className="annual_year">
                    <img src={tree} />
                    <a href="./annual">2022</a>
                  </span>
                }
                style={{
                  width: 305,
                  height: 280,
                  background: "linear-gradient(to right, #54873B, #94c93d)",
                  fontWeight: "bold",
                  borderRadius: "25px",
                  boxShadow: "0 2px 4px black",
                }}
              >
                <p className="annual_data">
                  Target: <span>{target_2022} </span>
                </p>
                <p className="annual_data">
                  Planted: <span>{planted_2022} </span>
                </p>
              </Card>
            </div>   
        </Space>
      </div>
      

      <div  className="annualCard">
        <Space className="anualSpace">
          <div className=" row home_card_container_row">
              <Card
                className="cardOneDay"
                title={
                  <span className="annual_year">
                    <img src={tree} />
                    <a href="./annual">2023</a>
                  </span>
                }
                style={{
                  width: 305,
                  height: 280,
                  background: "linear-gradient(to right, #2980b9, #6dd5fa)",
                  fontWeight: "bold",
                  borderRadius: "25px",
                  boxShadow: "0 2px 4px black",
                }}
              >
                <p className="annual_data">
                  Target: <span>{target_2023}</span>{" "}
                </p>
                <p className="annual_data">
                  Planted: <span>{planted_2023}</span>{" "}
                </p>
              </Card>
              <Card
                className="cardOneDay"
                title={
                  <span className="annual_year">
                    <img src={tree} />
                    <a href="./annual">2024</a>
                  </span>
                }
                style={{
                  width: 305,
                  height: 280,
                  background: "linear-gradient(to right, #3c2e03, #a67c00)",
                  fontWeight: "bold",
                  borderRadius: "25px",
                  boxShadow: "0 2px 4px black",
                }}
              >
                <p className="annual_data">
                  Target: <span>{target_2024}</span>{" "}
                </p>
                <p className="annual_data">
                  Planted: <span>{planted_2024}</span>{" "}
                </p>
              </Card>
          </div>
        </Space>
      </div>

     
    </>
  );
};

export default AnnualCard;
