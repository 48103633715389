// import axios from "axios";
// import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Button, Input, Modal } from "antd";
// import { AddParticipation } from "../../actions/participation";
// import ParticipationList from "./participationList";

// function Participation() {
//   const [value, setValue] = useState({
//     year: "2024",
//     male: "",
//     female: "",
//   });
//   const { successMessage, errorMessage } = useSelector((state) => state.participation);
//   const dispatch = useDispatch();
//   const [isModalVisible, setIsModalVisible] = useState(false);

//   const handleAddParticipation = (e) => {
//     e.preventDefault();
//     setIsModalVisible(true);
//   };

//   const handleConfirm = () => {
//     setIsModalVisible(false);
//     dispatch(AddParticipation(value));
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   return (
//     <>
//       <div className="row">
//         <div className="col-lg-6">
//           <div className="d-flex">
//             <div className="w-100" style={{ backgroundColor: "white" }}>
//               <div style={{ margin: "50px" }}>
//                 <h2 className="text-center mb-4">Add</h2>
//                 {successMessage && <div className="alert alert-success">{successMessage}</div>}
//                 {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
//                 <div className="form-group">
//                   <p>Year</p>
//                   <p
//                     type="number"
//                     className="form-control form-control-lg"
//                     name="year"
//                   >
//                     2024
//                   </p>
//                 </div>
//                 <div className="form-group">
//                   <p>Male</p>
//                   <Input
//                     type="number"
//                     className="form-control form-control-lg"
//                     name="male"
//                     value={value.male}
//                     onChange={(e) => setValue({ ...value, male: e.target.value })}
//                   />
//                 </div>
//                 <div className="form-group">
//                   <p>Female</p>
//                   <Input
//                     type="number"
//                     className="form-control form-control-lg"
//                     name="female"
//                     value={value.female}
//                     onChange={(e) => setValue({ ...value, female: e.target.value })}
//                   />
//                 </div>
//                 <div className="text-center">
//                   <Button type="primary" onClick={handleAddParticipation}>
//                     Add
//                   </Button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6">
//           {/* <ParticipationList /> */}
//         </div>
//       </div>

//       <Modal
//         title="Confirm Action"
//         visible={isModalVisible}
//         onOk={handleConfirm}
//         onCancel={handleCancel}
//         okText="Yes"
//         cancelText="No"
//       >
//         <p>Are you sure you want to add this data?</p>
//       </Modal>
//     </>
//   );
// }

// export default Participation;


import React, { useState } from 'react';
import axios from 'axios';
import { AddOneDay, UpdateOneDay } from '../../actions/oneDay';
import { useDispatch, useSelector } from "react-redux";
import { Button, Menu, Layout, Dropdown, message, Form, Input, Modal } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, CaretDownOutlined } from "@ant-design/icons";
import Logo from "../../asset/images/green_legacy_logo.png";
import { AddAnnual } from "../../actions/annualAdmin";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/auth";
import OneDayList from './oneDayList';
import ParticipationList from './participationList';
import { AddParticipation } from "../../actions/participation";
const Server_URL = process.env.REACT_APP_SERVER_URL;


const Participation = () => {
  const { Header, Sider, Content } = Layout;
  const { name } = useSelector((state) => state.user);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const [editingUser, setEditingUser] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState("Upload Site Data");

  const [users, setUsers] = useState([]);
  const [id, setId] = useState("");
  const [inputNumber, setInputNumber] = useState('');
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    planted: "",
  });
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const handleClick = (item) => {
    setSelectedComponent(item);
  };

  const { successMessage, errorMessage } = useSelector((state) => state.participation);

  const [isModalVisible, setIsModalVisible] = useState(false);
  
    const handleAddParticipation = (e) => {
      e.preventDefault();
      setIsModalVisible(true);
    };
    const handleConfirm = () => {
          setIsModalVisible(false);
          dispatch(AddParticipation(value));
        };
  const handleChange = (e) => {
    setInputNumber(e.target.value);
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
    window.location.reload();

  };
  const ModelhandleChangePassword = () => {
    setVisible(true);
    form.setFieldsValue({
      name: name,
      oldPassword: "",
      newPassword: "",
    });
  };


  const handleAddMetadata = (e) => {
    e.preventDefault();
    dispatch(AddAnnual(value));
  };

  const handleChangePassword = async () => {
    try {
      const oldPassword = form.getFieldValue("oldPassword");
      const newPassword = form.getFieldValue("newPassword");

      if (!newPassword || !oldPassword) {
        console.error("Both current and new password must be provided");
        return;
      }

      const values = await form.validateFields();
      const updatedUser = { ...editingUser, ...values };

      const response = await axios.put(`${Server_URL}/getuserusersadmin@639adminUpdatePassword/${id}`, values);
      message.success("Password updated successfully");
      form.resetFields();
      setUsers(response.data);
    } catch (error) {
      console.error("Error updating password:", error);
      message.error("Failed to update password");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalVisible(true);
  };

 

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={ModelhandleChangePassword}>
        Change Password
      </Menu.Item>
      <Menu.Item key="2" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <>
     

      <Modal
        title="Confirm Action"
        visible={isModalVisible}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to add this data?</p>
      </Modal>

      <Layout>
        {/* <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ width: "50px", backgroundColor: "white" }}
        >
          <div className="d-flex">
            <a href="/">
              <img src={Logo} style={{ width: "200px", float: "left" }} alt="Logo" />
            </a>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
                float: "right",
              }}
            />
          </div>
         
        </Sider> */}

        <Layout>
          {/* <Header style={{ padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button type="text" style={{ color: "white" }}>
                  {name} <CaretDownOutlined />
                </Button>
              </Dropdown>
            </div>
          </Header> */}

          <Content >
          <div className="row">
         <div className="col-lg-6">
          <div className="d-flex">
            <div className="w-100" style={{ backgroundColor: "white" }}>
              <div style={{ margin: "50px" }}>
                <h2 className="text-center mb-4">Add</h2>
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <div className="form-group">
                  <p>Year</p>
                  <p
                    type="number"
                    className="form-control form-control-lg"
                    name="year"
                  >
                    2024
                  </p>
                </div>
                <div className="form-group">
                  <p>Male</p>
                  <Input
                    type="number"
                    className="form-control form-control-lg"
                    name="male"
                    value={value.male}
                    onChange={(e) => setValue({ ...value, male: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <p>Female</p>
                  <Input
                    type="number"
                    className="form-control form-control-lg"
                    name="female"
                    value={value.female}
                    onChange={(e) => setValue({ ...value, female: e.target.value })}
                  />
                </div>
                <div className="text-center">
                  <Button type="primary" onClick={handleAddParticipation}>
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
       <div className="col-lg-6">
        <ParticipationList/>
{name}       </div>
     </div>
         
          </Content>
        </Layout>
      </Layout>

      <Modal
        title="Edit Password"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleChangePassword}>
            Update
          </Button>,
        ]}
      >
        {name}
        <Form form={form} layout="vertical">
          <Form.Item
            name="oldPassword"
            label="Current Password"
            rules={[{ required: true, message: "Please input your current password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[{ required: true, message: "Please input your new password!" }]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Participation;


