import {
  SET_VALUE,
  SET_NAME,
  SET_ID,
  SET_USERS,
  SET_SHOW_DATA,
  SET_CLICKED_DATA,
  SET_EDITING_USER,
  SET_VISIBLE,
  ADD_IMAGE_SUCCESS,
  ADD_IMAGE_ERROR,

  FETCH_IMAGES_REQUEST,
  FETCH_IMAGES_SUCCESS,
  FETCH_USERS_FAILURE,
  DELETE_IMAGE_SUCCESS,
  UPDATE_IMAGE_SUCCESS,
  
} from '../../actions/media';


const initialState = {
  loading: false,
  users: [],
  error: null,
  value: {
    filename: "",
    path: "",
  },
  name: "",
  id: "",
  users: [],
  showData: false,
  clickedData: null,
  editingUser: null,
  visible: false,
  successMessage: "",
  errorMessage: "",
};

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_IMAGES_REQUEST:
    return {
      ...state,
      loading: true,
    };
  case FETCH_IMAGES_SUCCESS:
    return {
      ...state,
      loading: false,
      users: action.payload,
    };
    
  case FETCH_USERS_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  case DELETE_IMAGE_SUCCESS:
    return {
      ...state,
      users: state.images.filter((user) => user.id !== action.payload),
      
    };
  case UPDATE_IMAGE_SUCCESS:
    return {
      ...state,
      users: state.users.map((user) =>
        user.id === action.payload.id ? action.payload : user
      ),
    };

    
    
    
    case SET_VALUE:
      return {
        ...state,
        value: { ...state.value, [action.payload.name]: action.payload.value },
      };
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_SHOW_DATA:
      return {
        ...state,
        showData: action.payload,
      };
    case SET_CLICKED_DATA:
      return {
        ...state,
        clickedData: action.payload,
      };
    case SET_EDITING_USER:
      return {
        ...state,
        editingUser: action.payload,
      };
    case SET_VISIBLE:
      return {
        ...state,
        visible: action.payload,
      };
      case ADD_IMAGE_SUCCESS:
          return {
            ...state,
            successMessage: action.payload,
            errorMessage: '',
          };
      case ADD_IMAGE_ERROR:
          return {
            ...state,
            successMessage: '',
            errorMessage: action.payload,
          };
        default:
          return state;
  }
};

export default mediaReducer;
