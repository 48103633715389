import { useDispatch, useSelector } from "react-redux";
import { fetchOneDayData } from "../actions/oneDay";
import OneDayMap from "../components/oneDay/onedayMap";
import { Table } from "antd";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import oneday_logo from "../asset/images/Green 22.jpg";

import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto"; // Import Chart.js
import axios from "axios";
import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import Map from "ol/Map";
import View from "ol/View";
import target from "../asset/images/target.png";
import planted_1 from "../asset/images/logog.jpg";
import seed from "../asset/images/green_legacy_logo.png";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import { XYZ } from "ol/source";
import LayerSwitcher from "ol-layerswitcher";
import { FullScreen, defaults as defaultControls } from "ol/control.js";
import TileWMS from "ol/source/TileWMS";
import "ol/ol.css";
import ImageWMS from "ol/source/ImageWMS.js";
import { RegionData } from "../data/RegionData";
import { OneDayData } from "../data/onedayData";


const mapURL = process.env.REACT_APP_MAP_URL;
const Server_URL = process.env.REACT_APP_SERVER_URL;

const wmsSource = new ImageWMS({
  url: `${mapURL}`,
  params: { LAYERS: "Green_Legacy:one day planted" },
  ratio: 1,
  serverType: "geoserver",
});
const GreenLegacyDay = () => {
  const [metadatas, setMetadatas] = useState([]);
  const [chartInstance, setChartInstance] = useState(null);
  const chartRef = useRef(null);
  const mapRef = useRef(null);
  useEffect(() => {
    if (!mapRef.current) return;

    const map = new Map({
      controls: defaultControls().extend([new FullScreen()]),

      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
          title: "OSM",
          visible: false,
        }),
        new TileLayer({
          source: new XYZ({
            url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          }),
          title: "Satelite",
          visible: true,
          title: "satilite",
        }),
        new TileLayer({
          source: new TileWMS({
            url: `${mapURL}`,
            params: { LAYERS: "Green_Legacy:ethiopia_new_2016_all_regions", TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: "Ethiopia",
          title: "Ethiopia",
        }),
        new TileLayer({
          source: new TileWMS({
            url: `${mapURL}`,
            params: { LAYERS: "Green_Legacy:one day planted", TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: "One Day Planted Site",
          title: "One Day Planted Site",
        }),
      ],
      view: new View({
        center: fromLonLat([38.972261469308, 8.748207216169536]),
        zoom: 6,
      }),
    });

    var layerSwitcher = new LayerSwitcher({
      reverse: true,
      activationMode: "click",
      groupSelectStyle: "children",
    });
    map.addControl(layerSwitcher);

    // var full_sc = new FullScreen({ label: 'F' });
    // map.addControl(full_sc);
    map.on("singleclick", function (evt) {
      const features = map.getFeaturesAtPixel(evt.pixel);
      const clickedCoordinate = evt.coordinate;
      clickedFeature(evt, map);
      map.getView().animate({
        center: clickedCoordinate,
        // zoom: 12, // Adjust zoom level as needed
        duration: 500 // Animation duration in milliseconds
      });
    });

    return () => map.dispose();
  }, []);
  const tt=OneDayData[0].title
  function clickedFeature(evt, map) {
    const viewResolution = map.getView().getResolution();
    const viewProjection = map.getView().getProjection();
    const coordinate = evt.coordinate;
    const url = wmsSource.getFeatureInfoUrl(
      coordinate,
      viewResolution,
      viewProjection,
      {
        INFO_FORMAT: "application/json",
        FEATURE_COUNT: 2,
      }
    );

    if (url) {
      fetch(url)
        .then((response) => response.json())
        .then(function (json) {
          // Parse JSON response
          const country = {};
          const zone = {};

          const region = {};
          const woreda = {};
          const kebele = {};
          const site_name = {};
          const area_ha = {};
          const seedling = {};
          const participan = {};
          const type = {};
          


          const id = {};
          console.log("ooooooo",json);
          // region[""] = json["features"][0]["properties"]["region"];
          // zone[""] = json["features"][0]["properties"]["zone"];
          // woreda[""] = json["features"][0]["properties"]["woreda"];
          // kebele[""] = json["features"][0]["properties"]["kebele"];
          // site_name[""] = json["features"][0]["properties"]["site_name"];
          // area_ha[""] = json["features"][0]["properties"]["area_ha"];
          // seedling[""] = json["features"][0]["properties"]["seedling"];
          // participan[""] = json["features"][0]["properties"]["participan"];
          // type[""] = json["features"][0]["properties"]["type"];


          region[""] = json["features"][0]["properties"]["Region"];
          zone[""] = json["features"][0]["properties"]["Zone"];
          woreda[""] = json["features"][0]["properties"]["Woreda"];
          kebele[""] = json["features"][0]["properties"]["Kebele"];
          site_name[""] = json["features"][0]["properties"]["Site_Name"];
          area_ha[""] = json["features"][0]["properties"]["Area_Ha"];
          seedling[""] = json["features"][0]["properties"]["Seedling"];
          participan[""] = json["features"][0]["properties"]["Participan"];
          type[""] = json["features"][0]["properties"]["Type"];

          const years = [""];
          const response1 = axios
            // .get(`${Server_URL}/regional_data/${id["id"]}`)
            // .then((response) => {
            //   years.forEach((year) => {
            //     const data_2019 = response.data[0];
              
            //     //2019
            //     document.getElementById("container_clicked_map-target-years_2019").innerHTML ='<b style="background-color:green;color:white;font-size:18px"> ' +data_2019["year"] +"</b>";
            //     document.getElementById("container_clicked_map-target_2019").innerHTML = "<b> Total Target:</b>   " + data_2019["target"];
            //     document.getElementById("container_clicked_map-planted_2019" ).innerHTML = "<b> Total Planted:</b>   " +data_2019["planted"];
            //     document.getElementById("container_clicked_map-area_covarage_2019" ).innerHTML = "Area Covarage in hectare:";
            //     document.getElementById( "container_clicked_map-achieved_2019").innerHTML = "Achieved in % :";
            //     });
            // })
            // .catch((error) => {
            //   console.error("Error fetching data:", error);
            // });

          //   <div id="container_clicked_map-title"></div>        
          //   <div id="container_clicked_map-region" className="container_list"   ></div>
          //   <div id="container_clicked_map-zone" className="container_list"></div>
          //   <div id="container_clicked_map-wereda"></div>
          //  <div id="container_clicked_map-kebele" ></div>
          //  <div id="container_clicked_map-site_name" ></div>
          //  <div id="container_clicked_map-area_ha" ></div>
          const container = document.getElementById( "container_clicked_map-region"
          );
          const region_name = region[""];

          const zone_name = zone[""];
          const wereda_name = woreda[""];
          const kebele_name = kebele[""];
          const site_name_name = site_name[""];
          const area_ha_name = area_ha[""];

          const seedling_name = seedling[""];
          const participan_name = participan[""];
          const type_name = type[""];



          // document.getElementById("container_clicked_map-region").innerHTML =  " " +  country_name + '</b>';
         
          document.getElementById("container_clicked_map-region").innerHTML = "<b> Region:</b>  " + '<span style="background-color:#209540;color:white;">'+ region_name + '</b>';
          document.getElementById("container_clicked_map-zone").innerHTML = "<b> Zone:</b>  " +  zone_name + '</span>';
          // document.getElementById("container_clicked_map-woreda").innerHTML = "<b> Wereda:</b>  " + wereda_name + '</b>';
          document.getElementById("container_clicked_map-kebele").innerHTML = "<b> Kebele:</b>  "  + kebele_name + '</span>';
          document.getElementById("container_clicked_map-site_name").innerHTML = "<b> Site Name:</b>  " + site_name_name + '</span>';
          document.getElementById("container_clicked_map-area").innerHTML = "<b> Area Coverage:</b>   " + area_ha_name + '</b>'+ 'Hectar'+'</span>';

          document.getElementById("container_clicked_map-seedling").innerHTML = "<b> Seedling:</b>  "  + seedling_name + '</span>';
          document.getElementById("container_clicked_map-participan").innerHTML = "<b> participant:</b>  " + participan_name + '</span>';
          document.getElementById("container_clicked_map-type").innerHTML = "<b> Seedling Type:</b>   "  + type_name + '</span>';
          document.getElementById( "container_clicked_map-image").innerHTML =`<img src='${planted_1}' width=50% />` + "<b><br/> Ethiopia Green Legacy Initiative</b>   ";

       
       
       
        })
        .catch((err) => {
          document.getElementById("container_clicked_map").style.display = "none";
          // console.log(err);
        });
    }

    var modal = document.getElementById("container_clicked_map");
    modal.style.display = "block";
    document.getElementById("container_clicked_map").style.width = "420px";
    document.getElementById("popup_oneday_content").style.width = "410px";


    document.getElementById("close-icon1").style.display ="inline";
    window.onclick = function (event) {
      var modal = document.getElementById("container_clicked_map");
      if (event.target == modal) {
        modal.style.display = "none";
      }
    };
  }
  function container_clicked_map() {
    document.getElementById("container_clicked_map").style.display = "none";
  }
  
    

  const dispatch = useDispatch();
  const { oneDay } = useSelector((state) => state.oneDay);
  const oneDayparticapationArray = oneDay || [];
  
  const columns = [
    {
      title: '',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: <span style={{ fontSize: '26px' }}>2019</span>,
      dataIndex: '2019',
      key: '2019',
    },
    {
      title: <span style={{ fontSize: '26px' }}>2023</span>,
      dataIndex: '2023',
      key: '2023',
    },
    {
      title: <span style={{ fontSize: '26px' }}>2024</span>,
      dataIndex: '2024',
      key: '2024',
    },
  ];

  const tableData = [
    {
      key: '1',
      category: <span style={{ fontSize: '26px' }}>Target</span>,
      '2019': <span style={{ fontWeight: 'bold', fontSize: '18px' }}>200 M</span>,
      '2023': <span style={{ fontWeight: 'bold', fontSize: '18px' }}>500 M</span>,
      '2024': <span style={{ fontWeight: 'bold', fontSize: '18px' }}>600 M</span>,

    },
    {
      key: '2',
      category: <span style={{ fontSize: '26px' }}>Planted</span>,
      '2019': <span style={{ fontWeight: 'bold', fontSize: '18px' }}>353 M</span>,
      '2023': <span style={{ fontWeight: 'bold', fontSize: '18px' }}>566 M</span>,
      '2024': <span style={{ fontWeight: 'bold', fontSize: '18px' }}>615.7 M</span>,

    },
    {
      key: '3',
      category: <span style={{ fontSize: '26px' }}>Participants</span>,
      '2019': <span style={{ fontWeight: 'bold', fontSize: '18px' }}>20 M</span>,
      '2023': <span style={{ fontWeight: 'bold', fontSize: '18px' }}>34 M</span>,
      '2024': <span style={{ fontWeight: 'bold', fontSize: '18px' }}>29.1 M</span>,
    },
  ];

  useEffect(() => {
      dispatch(fetchOneDayData());

   // Cleanup on unmount
  }, [dispatch]);
  const totalMale = oneDayparticapationArray.reduce((total, data) =>  (data.male/1000000 + " M"|| 0), 0);
  const totalFemale = oneDayparticapationArray.reduce((total, data) =>  (data.female/1000000 + " M"|| 0), 0);

  // const totalMale = oneDayparticapationArray.reduce((total, data) => total + (data.male || 0), 0);
  // const totalFemale = oneDayparticapationArray.reduce((total, data) => total + (data.female || 0), 0);
  // const totalParticipation = oneDayparticapationArray.reduce((total, data) => total + (data.totalParticipation || 0), 0);
  const totalParticipation = oneDayparticapationArray.reduce((total, data) =>  (data.totalParticipation/1000000 + " M"|| 0), 0);
  const totalPlanted = oneDayparticapationArray.reduce((total, data) => total + (data.planted || 0), 0);

  const Planted = oneDayparticapationArray.reduce((total, data) => {
    const plantedValue = data.planted / 1000000;
    return plantedValue > 0 ? `${plantedValue} M` : "";
  }, 0);

  // Calculate progress percentage
  const plantedTarget = 600000000; // Target value
  // const totalTarget = oneDayparticapationArray.reduce((total, data) =>  (600000000/1000000 + " M"|| 0), 0);

  const plantedProgress = (totalPlanted / plantedTarget) * 100;

  const totalArea = oneDayparticapationArray.reduce((total, data) => total + (317521|| 0), 0);
  const totalSite = oneDayparticapationArray.reduce((total, data) => total + (8453|| 0), 0);
  const totalPlantedLastHours = oneDayparticapationArray.reduce((total, data) => total + (data.planted || 0), 0);

  return (
    <>
    <div className="oneday">
      <div className="row">
        <div className="col-lg-3 mb-3">
          <div className="cavrd shadow p-3 text-center">
            <h3 style={{color:"#006400"}}>Total Target</h3>
            <div className="bg-success text-white p-2 mb-2 rounded" style={{ fontSize: "1.6em" }}>
            600 M
            </div>
          </div>

          {/* Updated Total Planted Section */}
          <div className="cavrd shadow p-3 text-center">
            <h3 style={{color:"#006400"}}>Total Planted</h3>
            <div style={{ width: 150, margin: "0 auto", position: "relative" }}>
              <CircularProgressbar
                value={plantedProgress}
                text={`${Math.round(plantedProgress)}%`}
                styles={buildStyles({
                  textSize: '26px',
                  pathColor: `#4CAF50`,
                  textColor: '#000',
                  trailColor: '#eee',
                })}
              />
            </div>
            {/* Display "thanks:=" if the target is met */}
            {/* {totalPlanted >= plantedTarget && (
              <div style={{ marginTop: "10px", fontSize: "1.4em", fontWeight: "bold" }}>
                Congragulation<br/>
                Thanks for your Participation<br/>
                
              </div>
            )} */}
            <div className="cavrd shadow p-3 text-center">
              <div className=" text-white p-2 mb-2 rounded" style={{ fontSize: "1.6em",backgroundColor:"#1fb82b" }}>
                {Planted} 
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
        <div
            ref={mapRef}
            style={{ width: "100%", height: "550px" }}
          ></div>        </div>
        
        <div className="col-lg-3">
          <div className="carvd shadow p-3 text-center">
            <h3 style={{color:"#006400"}}>Total Participation</h3>
            <h4 style={{color:"#006400"}}>Male</h4>
            <div className=" text-white p-2 mb-2 rounded" style={{ fontSize: "1.4em",backgroundColor:"#04d20a" }}>
              {totalMale.toLocaleString()}
            </div>
            <h4 style={{color:"#006400"}}>Female</h4>
            <div className=" text-white p-2 mb-2 rounded" style={{ fontSize: "1.4em",backgroundColor:"#04d20a" }}>
              {totalFemale.toLocaleString()}
            </div>
            <h4 style={{color:"#006400"}}>Total</h4>
            <div className="text-white p-2 mb-2 rounded" style={{ fontSize: "1.4em",backgroundColor:"#0faa35" }}>
              {totalParticipation.toLocaleString()}
            </div>
          </div>

          <div className="cbard shadow p-3 text-center">
              <h4 style={{color:"#006400"}}>Area Coverage</h4>
              <div className=" text-white p-2 mb-2 rounded"style={{fontSize:"1.4em",backgroundColor:"#1fb857"}}>317,521 Hectar</div>
              <h3 style={{color:"#006400"}}>Total Site</h3>
              <div className="bg-success text-white p-2 mb-2 rounded"style={{fontSize:"1.4em"}}>8,453</div>
          </div>
        </div>
      </div>
    
    </div>
    
      <div style={{ position:""}}>
      <Table className="container" columns={columns}pagination={false} dataSource={tableData}style={{ position:""}} />

      </div>
      <div id="container_clicked_map" className="container_clicked_map">
        <div
          className="close-icon1"
          id="close-icon1"
          onClick={container_clicked_map}
        >
          &times;
        </div>
        <div className="popupb_oneday">
  
     
          <div id="container_clicked_map-region" className="container_list map-region "   ></div>
          <hr/>
          <div className="popup_oneday_content" id="popup_oneday_content">
          <div id="container_clicked_map-zone" className="container_list map-zone"></div>
          <div id="container_clicked_map-woreda" className="container_list map-woreda"></div>
         <div id="container_clicked_map-kebele"  className="container_list map-kebele"></div>
         <div id="container_clicked_map-site_name" className="container_list map-site_name " ></div>
       
      </div>  
      <hr/>
      <div className="popup_oneday_content1" id="popup_oneday_content1">
         <div id="container_clicked_map-area" className="container_list map-area" ></div>
         <div id="container_clicked_map-seedling"  className="container_list map-seedling"></div>
         <div id="container_clicked_map-participan" className="container_list map-participant" ></div>
         <div id="container_clicked_map-type" className="container_list map-type" ></div><br/>
         <div id="container_clicked_map-image" className="container_list map-image" ></div>

      </div>

      </div>
      </div>
      </>
  );
};

export default GreenLegacyDay;
