import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, message, Popconfirm, Form, Input, Modal } from "antd";
import { fetchUsers, deleteUser, updateUser } from "../../actions/annualAdmin";

const Annual_list = () => {
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector((state) => state.annual);
  const [editingUser, setEditingUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const showEditModal = (user) => {
    setEditingUser(user);
    setVisible(true);
    form.setFieldsValue({
      id: user.id,
      year: user.year,
      target: user.target,
      planted: user.planted,
    });
  };

  const confirmDelete = async (userId) => {
    try {
      await dispatch(deleteUser(userId));
      message.success("User deleted successfully");
      dispatch(fetchUsers()); // Refetch data after deletion
    } catch (error) {
      message.error("Failed to delete user");
    }
  };

  const cancelDelete = () => {
    message.info("Deletion canceled");
  };

  const handleUpdate = async () => {
    try {
      const id = form.getFieldValue("id");
      if (!id) {
        console.error("No user ID provided for updating");
        return;
      }
      const values = await form.validateFields();
      const updatedUser = { ...editingUser, ...values };
      await dispatch(updateUser(updatedUser));
      message.success("User updated successfully");
      setVisible(false);
      setEditingUser(null);
      form.resetFields();
      dispatch(fetchUsers()); // Refetch data after update
    } catch (error) {
      console.error("Error updating user:", error);
      message.error("Failed to update user");
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setEditingUser(null);
    form.resetFields();
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div style={{ textAlign: "center" }}>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                margin: "auto",
                fontSize: "20px",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Years
                  </th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Target
                  </th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Planted
                  </th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {user.year}
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {user.target}
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {user.planted}
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      <Button
                        className="btn btn-primary"
                        onClick={() => showEditModal(user)}
                      >
                        Update
                      </Button>
                      <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => confirmDelete(user.id)}
                        onCancel={cancelDelete}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button className="btn btn-danger">Delete</Button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Modal
            title="Edit Data"
            visible={visible}
            onCancel={handleCancel}
            footer={[
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={handleUpdate}>
                Update
              </Button>,
            ]}
          >
            <Form form={form} layout="vertical">
              <Form.Item name="id" style={{ display: "none" }}>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                name="year"
                label="Year"
                rules={[{ required: true, message: "Please input the year!" }]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                name="target"
                label="Target"
                rules={[{ required: true, message: "Please input the target!" }]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                name="planted"
                label="Planted"
                rules={[{ required: true, message: "Please input the planted amount!" }]}
              >
                <Input type="number" />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Annual_list;
