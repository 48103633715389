import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, message, Popconfirm, Form, Input, Modal } from 'antd';
import { fetchUsers, updateUser, deleteUser } from '../../actions/users';

const Users_list = () => {
  const [editingUser, setEditingUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector((state) => state.user); // Access 'user' slice

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      message.error('Error fetching users');
    }
  }, [error]);

  const showEditModal = (user) => {
    setEditingUser(user);
    setVisible(true);
    form.setFieldsValue(user);
  };

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      dispatch(updateUser(editingUser.id, values));
      message.success('User updated successfully');
      setVisible(false);
      setEditingUser(null);
      form.resetFields();
      dispatch(fetchUsers());

    } catch (error) {
      console.error('Error updating user:', error);
      message.error('Failed to update user');
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setEditingUser(null);
    form.resetFields();
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <table style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px' }}>First Name</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Last Name</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Email</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Phone</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>User Type</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Gender</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.fname}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.lname}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.email}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.phone}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.usertype}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.gender}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  <Button className="btn btn-primary" onClick={() => showEditModal(user)}>
                    Update
                  </Button>
                  <Popconfirm
                    title="Are you sure to delete ?"
                    onConfirm={() => dispatch(deleteUser(user.id))}
                    onCancel={() => message.info('Deletion canceled')}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="btn btn-danger">Delete</Button>
                  </Popconfirm>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        title="Edit User"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleUpdate}>
            Update
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="fname" label="First Name" rules={[{ required: true, message: 'Please enter first name' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="lname" label="Last Name" rules={[{ required: true, message: 'Please enter last name' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please enter a valid password' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please enter phone number' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="usertype" label="User Type" rules={[{ required: true, message: 'Please select user type' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="gender" label="Gender" rules={[{ required: true, message: 'Please select gender' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Users_list;
