import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie"; // Import the cookies library

import {
  Button,
  Menu,
  Layout,
  Dropdown,
  message,
  Form,
  Input,
  Modal,
} from "antd";




function SiteAdmin() {
  const Server_URL = process.env.REACT_APP_SERVER_URL;

  const [showdata, setdata] = useState(false);
  const [clickedData, setClickedData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [value, setValue] = useState({
    target: "",
    planted: "",
    area: "",
    region: "",
    siteName: "",
  });
  const [name, setName] = useState("");
  const handleChange = (name, e) => {
    setValue({ ...value, [name]: e.target.value });
  };
  // console.log(value);
  const AddMetadata = (e) => {
    e.preventDefault();
    axios
      .put(`${Server_URL}/metadata/${value.region}`, value)
      .then((res) => {
        console.log(res);
        alert("User added successfully!");
      })
      .catch((err) => console.log(alert("User added error!", err)));
  };
    return(
        <>
           <div className="container">
      <div className="upload-form">
        <h2 className="upload-form__title">Upload Site Data</h2>
        <div className="form-group">
          <select
            className="form-control"
            value={value.region}
            onChange={(e) => handleChange("region", e)}
          >
            <option value="">Select Region</option>
            <option value="Addis Ababa">Addis Ababa</option>
            <option value="Afar">Afar</option>
            <option value="Amhara">Amhara</option>
            <option value="Benshangule">Benshangule</option>
            <option value="Diredawa">Diredawa</option>
            <option value="Genbala">Genbala</option>
            <option value="Hareri">Hareri</option>
            <option value="Oromia">Oromia</option>
            <option value="Snnp">Snnp</option>
            <option value="Somalia">Somalia</option>
            <option value="Tigray">Tigray</option>
          </select>
        </div>
        <div className="form-group">
          <select
            className="form-control"
            value={value.siteName}
            onChange={(e) => handleChange("siteName", e)}
          >
            <option value="">Select Site Name</option>
            <option value="Site 1">Site 1</option>
            <option value="Site 2">Site 2</option>
            <option value="Site 3">Site 3</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="form-group">
          <input
            type="number"
            className="form-control"
            placeholder="Target"
            value={value.target}
            onChange={(e) => handleChange("target", e)}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            className="form-control"
            placeholder="Planted"
            value={value.planted}
            onChange={(e) => handleChange("planted", e)}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            className="form-control"
            placeholder="Area in Hectares"
            value={value.area}
            onChange={(e) => handleChange("area", e)}
          />
        </div>
        <button className="btn btn-success" onClick={AddMetadata}>
          Add
        </button>
      </div>
    </div>
        </>
    )
    
}
 export default SiteAdmin;