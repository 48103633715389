import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto"; // Import Chart.js
import axios from "axios";
import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import "ol/ol.css";
import Map2024 from "../map2024";
const Server_URL = process.env.REACT_APP_SERVER_URL;

const Annual_chart = () => {
  const [metadatas, setMetadatas] = useState([]);
  const [chartInstance, setChartInstance] = useState(null);
  const chartRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const getMetadata = async () => {
      try {
        const response = await axios.get(`${Server_URL}/annual_data`);
        setMetadatas(response.data);
      } catch (error) {
        console.error("Error fetching annual data:", error);
      }
    
    };

    getMetadata();
  }, []);

  useEffect(() => {
    if (!metadatas || metadatas.length === 0) return;

    const eth_adm1_gid = metadatas.map((metadata) => metadata.year);

    const target = metadatas.map((metadatas) => metadatas.target);
    const planted = metadatas.map((metadata) => metadata.planted);
    // console.log(target);
    // console.log(target);
    if (chartInstance) {
      // If a chart instance already exists, destroy it first
      chartInstance.destroy();
    }

    if (chartRef.current) {
      // Create a new chart instance
      const ctx = chartRef.current.getContext("2d");
      const newChartInstance = new Chart(ctx, {
        type: "bar",
        data: {
          labels: eth_adm1_gid,
          datasets: [
            {
              label: "Target",
              data: target,
              backgroundColor: "#3c2e03",
              borderColor: "#34D350",
              borderWidth: 1,
            },
            {
              label: "Planted",
              data: planted,
              backgroundColor: "green",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });

      // Save the new chart instance to state
      setChartInstance(newChartInstance);
    }
  }, [metadatas]);

  const [selectedYear, setSelectedYear] = useState(""); // State to manage the selected year

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <h3 className="Target_title">Target and Planted 2019-2023</h3>
          <div style={{ textAlign: "center", marginTop: "25px" }}>
            <canvas ref={chartRef}></canvas>
          </div>
        </div>
        {/* <div className="col-lg-6">
         <Map2024/>
        </div> */}
      </div>
    </>
  );
};

export default Annual_chart;
