import * as FaIcons from "react-icons/fa";
const mapURL = process.env.REACT_APP_MAP_URL;

export const AnnualDtata=[
    
    {
        title:'2024',
        title_eth:'Ethiopia',

        title_eth:'Ethiopia',

        target:' No Data',
        planted:'No Data',
        motto:"",
        area:"No Data",
        sites:'sites',
        region:'Ethiopia ',
        datatype:'vector data',
        owner:"#GreenLegacy",
        language:"English",
        url:`${mapURL}`,
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        icon:<FaIcons.FaHome color="black"/>,
        params:'Site_2024',
        params1:'ethiopia_new_2016_all_regions',

        lng:'38.972261469308',
        lat:'8.748207216169536',
        zoom:5.5,
        forest_tree:'',
        bamboo:'',
        agro_forest:'33333333333333',
        omament:'',
        total_planted:'',
        total_coverage:'',

        zoom:5.5,
        forest_tree:'',
        bamboo:'',
        agro_forest:'33333333333333',
        omament:'',
        total_planted:'',
        total_coverage:''

       
      
    },
    {
        title:'2023',
        title_eth:'Ethiopia',
        title_eth:'Ethiopia',
        target:'6.5 Billion',
        planted:'7.5 Billion',
        motto:"Let's plant our future today",
        area:"No Data",

        sites:'sites',
        region:'Ethiopia ',
        datatype:'vector data',
        owner:"#GreenLegacy",
        language:"English",
        url:`${mapURL}`,
        catagory:'none',
        sitename:'All',
        publishDate:"0/0/0",
        icon:<FaIcons.FaHome color="black"/>,
        params:'Site_2023',
        params1:'ethiopia_new_2016_all_regions',

        lng:'38.972261469308',
        lat:'8.748207216169536',
        zoom:5.5,
        forest_tree:'',
        bamboo:'',
        agro_forest:'',
        omament:'',
        total_planted:'7,500,000,000',
        total_coverage:'',
        zoom:5.5,
        forest_tree:'',
        bamboo:'',
        agro_forest:'',
        omament:'',
        total_planted:'7,500,000,000',
        total_coverage:''
       
      
    }, 
  
//     {
//         title:'2022',
//         target:'6 Billion',
//         planted:'7.2 Billion',
//         motto:"Our legacy for our generation",
//         area:"No Data",
//         sites:'sites',
//         region:'Ethiopia ',
//         datatype:'vector data',
//         owner:"#GreenLegacy",
//         language:"English",
//         url:`${mapURL}`,
//         catagory:'none',
//         sitename:'All',
//         publishDate:"0/0/0",
//         icon:<FaIcons.FaHome color="black"/>,
//         params:'ethiopia_new_2016_all_regions',
//         lng:'38.972261469308',
//         lat:'8.748207216169536',
//         zoom:5.5
       
      
//     },
//     {
//         title:'2021',
//         target:'6 Billion',
//         planted:'6.8 Billion',
//         motto:"Let's cover Ethiopia",
//         area:"No Data",
//     },
//     {
//         title:'2021',
//         target:'6 Billion',
//         planted:'6.8 Billion',
//         motto:"Let's cover Ethiopia",
//         area:"No Data",

//         sites:'sites',
//         region:'Ethiopia ',
//         datatype:'vector data',
//         owner:"#GreenLegacy",
//         language:"English",
//         url:`${mapURL}`,
//         catagory:'none',
//         sitename:'All',
//         publishDate:"0/0/0",
//         icon:<FaIcons.FaHome color="black"/>,
//         params:'ethiopia_new_2016_all_regions',
//         lng:'38.972261469308',
//         lat:'8.748207216169536',
//         zoom:5.5
       
      
//     },
//     {
//         title:'2020',
//         target:'5 Billion',
//         planted:'5.9 Billion',
//         motto:"Let's plant carefully",
//         area:"1.18 Million Hectare",
//     },
//     {
//         title:'2020',
//         target:'5 Billion',
//         planted:'5.9 Billion',
//         motto:"Let's plant carefully",
//         area:"1.18 Million Hectare",

//         sites:'sites',
//         region:'Ethiopia ',
//         datatype:'vector data',
//         owner:"#GreenLegacy",
//         language:"English",
//         url:`${mapURL}`,
//         catagory:'none',
//         sitename:'All',
//         publishDate:"0/0/0",
//         icon:<FaIcons.FaHome color="black"/>,
//         params:'ethiopia_new_2016_all_regions',
//         lng:'38.972261469308',
//         lat:'8.748207216169536',
//         zoom:5.5
       
      
//     },
//     {
//         title:'2019',
//         target:'4 Billion',
//         planted:'4.7 Billion',
//         motto:"40 trees per capita",
//         area:"3,253 Million Hectare",
//         sites:'sites',
//         region:'Ethiopia ',
//         datatype:'vector data',
//         owner:"#GreenLegacy",
//         language:"English",
//         url:`${mapURL}`,
//         catagory:'none',
//         sitename:'All',
//         publishDate:"0/0/0",
//         icon:<FaIcons.FaHome color="black"/>,
//         params:'ethiopia_new_2016_all_regions',
//         lng:'38.972261469308',
//         lat:'8.748207216169536',
//         zoom:5.5

       
      
//     },
//     },
  
    
]