import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, message, Popconfirm, Form, Input, Modal } from "antd";
import { deleteImage, fetchImage, updateImageTitle } from '../../actions/media';

const Image_list = () => {
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector((state) => state.media);
  const [editingUser, setEditingUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchImage());
  }, [dispatch]);

  const showEditModal = (user) => {
    setEditingUser(user);
    setVisible(true);
    form.setFieldsValue({
      id: user.id,
      filename: user.filename,
     
    });
  };

  const confirmDelete = async (userId) => {
    try {
      await dispatch(deleteImage(userId));
      message.success("User deleted successfully");
      dispatch(fetchImage()); // Refetch data after deletion
    } catch (error) {
      message.error("Failed to delete user");
    }
  };

  const cancelDelete = () => {
    message.info("Deletion canceled");
  };

  const handleUpdate = async () => {
    try {
      const id = form.getFieldValue("id");
      // console.log("kkkkkk",id);
      
      if (!id) {
        console.error("No user ID provided for updating");
        return;
      }
      const values = await form.validateFields();
      const updatedUser = { ...editingUser, ...values };
      await dispatch(updateImageTitle(updatedUser));
      message.success("User updated successfully");
      setVisible(false);
      setEditingUser(null);
      form.resetFields();
      dispatch(fetchImage()); // Refetch data after update
    } catch (error) {
      console.error("Error updating user:", error);
      message.error("Failed to update user");
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setEditingUser(null);
    form.resetFields();
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div style={{ textAlign: "center" }}>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                margin: "auto",
                fontSize: "20px",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    file name
                  </th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    image
                  </th>
                 
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      {user.filename}
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                    <img 
                    src={user.path} 
                    alt={user.filename} 
                    style={{ maxWidth: '100%', maxHeight: '100px' }} 
                  />
                    </td>
                   
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      <Button
                        className="btn btn-primary"
                        onClick={() => showEditModal(user)}
                      >
                        Update
                      </Button>
                      <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => confirmDelete(user.id)}
                        onCancel={cancelDelete}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button className="btn btn-danger">Delete</Button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Modal
            title="Edit Data"
            visible={visible}
            onCancel={handleCancel}
            footer={[
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={handleUpdate}>
                Update
              </Button>,
            ]}
          >
            <Form form={form} layout="vertical">
              <Form.Item name="filename" style={{ display: "none" }}>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                name="filename"
                label="filename"
                rules={[
                  { required: true, message: "Please input the region!" },
                ]}
              >
                <Input />
              </Form.Item>
          
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Image_list;
