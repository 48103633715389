import React, { useEffect, useRef } from "react";
import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { XYZ } from "ol/source";
import logo from "../../asset/images/green_legacy_logo.png";
// 
import { fromLonLat } from "ol/proj";
import LayerSwitcher from "ol-layerswitcher";
import {  ScaleLine, defaults as defaultControls,  FullScreen,} from "ol/control.js";
import seed from "../../asset/images/green_legacy_logo.png";
import TileWMS from "ol/source/TileWMS";
import ImageWMS from "ol/source/ImageWMS.js";
import { Table } from "antd";
const mapURL = process.env.REACT_APP_MAP_URL;

const columns = [
  {
    title: '',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Forest tree',
    dataIndex: 'Forest tree',
    key: 'Forest tree',
  },
  {
    title: 'Bamboo',
    dataIndex: 'Bamboo',
    key: 'Bamboo',
  },
  {
    title: 'Agro Forest',
    dataIndex: 'Agro Forest',
    key: 'Agro Forest',
  },
  {
    title: 'Omament',
    dataIndex: 'Omament',
    key: 'Omament',
  },

];

const tableData = [
  {
    key: '1',
    category: 'Planted',
    'Forest tree': '',
    'Bamboo': '',
    'Agro Forest': '',
    'Omament': '',
   
  },
 
];
const wmsSource = new ImageWMS({
  url: `${mapURL}`,
  params: { LAYERS: "Green_Legacy:site_2023_and_2024" },
  ratio: 1,
  serverType: "geoserver",
});
const updateLegend = function (resolution) {
  const graphicUrl = wmsSource.getLegendUrl(resolution);
  const img = document.getElementById("legend");
  img.src = graphicUrl;
};

const Years_map = (data) => {
  const mapRef = useRef(null);
  useEffect(() => {
    if (!mapRef.current) return;

    const map = new Map({
      controls: defaultControls().extend([new FullScreen()]),

      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
          title: "OSM",
          visible: true,
        }),
        new TileLayer({
          source: new XYZ({
            url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          }),
          title: "Satelite",
          visible: false,
        }),
        // }),
        new TileLayer({
          source: new TileWMS({
            url: `${mapURL}`,
            params: { LAYERS: "Green_Legacy:ethiopia_new_2016_all_regions", TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: "Ethiopia",
          title: "Ethiopia",
        }),
        new TileLayer({
          source: new TileWMS({
            url: `${mapURL}`,
            params: { LAYERS: "Green_Legacy:Site_2023", TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title: "2023 Site",
          title: "2023 Site",
        }),
        new TileLayer({
          source: new TileWMS({
            url: `${mapURL}`,
            params: { LAYERS: "Green_Legacy:Site_2024", TILED: true },
            serverType: "geoserver",

            type: "base",
            group: "base",
            visible: true,
            transition: 0,
          }),
          title:"2024 site"
        }),
      ],
      view: new View({
        center: fromLonLat([38.972261469308, 8.748207216169536]),
        zoom:5.5,
      }),
    });

    var layerSwitcher = new LayerSwitcher({
      reverse: true,
      activationMode: "click",
      groupSelectStyle: "children",
    });
    map.addControl(layerSwitcher);
    const resolution = map.getView().getResolution();
    updateLegend(resolution);

    map.getView().on("change:resolution", function (event) {
      const resolution = event.target.getResolution();
      updateLegend(resolution);
    });

    var scaleLineMetric = new ScaleLine({
      units: "imperial",
      minWidth: 100,
      target: document.getElementById("scaleline-imperial"),
      text: true,
      steps: 4, 
      bar: true, 
      text: true,
      className: "ol-scale-line",
      minWidth: 120,
    });
    map.addControl(scaleLineMetric); 
    map.on("singleclick", function (evt) {
      const features = map.getFeaturesAtPixel(evt.pixel);
      const clickedCoordinate = evt.coordinate;
      clickedFeature(evt, map);
      map.getView().animate({
        center: clickedCoordinate,
        zoom: 12, // Adjust zoom level as needed
        zoom: 12, // Adjust zoom level as needed
        duration: 500 // Animation duration in milliseconds
      });
    });

    return () => map.dispose();
  }, [data]);
  function clickedFeature(evt, map) {
    const viewResolution = map.getView().getResolution();
    const viewProjection = map.getView().getProjection();
    const coordinate = evt.coordinate;
    const url = wmsSource.getFeatureInfoUrl(
      coordinate,
      viewResolution,
      viewProjection,
      {
        INFO_FORMAT: "application/json",
        FEATURE_COUNT: 2,
      }
    );

    if (url) {
      fetch(url)
        .then((response) => response.json())
        .then(function (json) {
          const region = {};
          const zone = {};
          const wereda = {};
          const kebele = {};
          const site = {};
          const Area = {};
          region[""] = json["features"][0]["properties"]["region"];
          zone[""] = json["features"][0]["properties"]["zone"];
          wereda[""] = json["features"][0]["properties"]["wereda"];
          kebele[""] = json["features"][0]["properties"]["kebele"];
          site[""] = json["features"][0]["properties"]["site_name"];
          Area[""] = json["features"][0]["properties"]["area_ha"];  
          const Name_region = region[""];
          const Name_zone = zone[""];
          const Name_wereda = wereda[""];
          const Name_kebele = kebele[""];
          const Name_site = site[""];
          const Name_Area = Area[""];
          document.getElementById("container_click_popup_selected_region-region").innerHTML = "<b> Region:</b>  " + '<span style="background-color:#209540;color:white;">'+ Name_region + '</b>';
          document.getElementById(
            "container_click_popup_selected_region-image"
          ).innerHTML = `<img src='${seed}'  width=50%/>`+ "<b><br/> Ethiopia Green Legacy Initiative</b>   ";

          document.getElementById("container_click_popup_selected_region-zone").innerHTML =  "<b> Zone:</b>   "  +Name_zone ;
          document.getElementById("container_click_popup_selected_region-wereda").innerHTML = "<b> Wereda:</b>   " + Name_wereda;
          document.getElementById("container_click_popup_selected_region-kebele").innerHTML = "<b> Kebele:</b>   " + Name_kebele;
          document.getElementById("container_click_popup_selected_region-site").innerHTML = "<b> Site:</b>   " + Name_site;
          document.getElementById( "container_click_popup_selected_region-area").innerHTML =  "<b> Total Area of coverage</b>   " +  "<br/>" +  " &nbsp;&nbsp;&nbsp; " +Name_Area +"   hectare ";
          document.getElementById("container_click_popup_selected_region-wereda").innerHTML = "<b> Wereda:</b>   " + Name_wereda;
          document.getElementById("container_click_popup_selected_region-kebele").innerHTML = "<b> Kebele:</b>   " + Name_kebele;
          document.getElementById("container_click_popup_selected_region-site").innerHTML = "<b> Site:</b>  " + Name_site;
          document.getElementById( "container_click_popup_selected_region-area").innerHTML =  "<b> Total Area of coverage:</b>   " +  "<br/>" +  " &nbsp;&nbsp;&nbsp; " +Name_Area +"   hectare ";
        //   document.getElementById("container_click_popup_selected_region-target" ).innerHTML = "<b> Total Target:</b>   " +"----";
        //   document.getElementById("container_click_popup_selected_region-planted").innerHTML ="<b> Total Planted:</b>   " +"-----";
        })
        .catch((err) => {
          document.getElementById("container_click_popup_selected_region").style.display = "none";
          // console.log(err);
        });
    }

    var modal = document.getElementById("container_click_popup_selected_region");
        modal.style.display = "block";
    document.getElementById("container_click_popup_selected_region" ).style.width = "420px";
    document.getElementById("container_click_popup_selected_region-content").style.width = "420px";
    document.getElementById("close-icon").style.display ="inline";
    window.onclick = function (event) {
      var modal = document.getElementById( "container_click_popup_selected_region");
      if (event.target == modal) {
        modal.style.display = "none";
      }
    };
  }
  function container_click_popup_selected_region() {
    document.getElementById("container_click_popup_selected_region").style.display = "none";
    document.getElementById("container_click_popup_selected_region-content").style.width = "0";
  }
  return (
    <>
      <div className="scaleline-metric"></div>
      <div className="row main_body title_region">
      <div className="row">
      <h3 style={{ marginLeft: "25px", marginTop:"10px",color: "black" }}>Seedling Sites<strong style={{ marginLeft: "5px" }}>2023-2024</strong></h3>
          <div className="col-lg-9 seedings_year_img">
            <div ref={mapRef}className="home-map map_container_region"style={{ width: "100%", height: "450px" }}></div>
            {/* <div className="map_info"> <hr />
              <div className="map_info_detail">
                 <p><h2>Seedling Planted detail 2019-2023 </h2></p>
               </div>
                <Table columns={columns} dataSource={tableData} pagination={false} bordered />
              </div> */}
          </div>
        <div className="col-lg-3">
          <div className="sidenav_right"><hr />
            <h5><a href="/metadata_detail" style={{ color: "white" }}> </a></h5><hr />
            <h5><a href="/metadata_detail" style={{ color: "white" }}></a>{" "}</h5><hr />
            <h4>Legend</h4>
            <div><img id="legend" /></div><hr />
              <p style={{fontSize:"25px",backgroundColor:"#05376b",color:"#fff",fontWeight:"bold",textAlign:"center"}}>2019-2023</p>
              <p><h4>Ethiopia</h4></p>
              <p>Total Planted:<b>{data.total}</b></p>
              <h4 style={{ fontWeight: "bold" }}>About</h4>
              <p>Owner,point of Contact,Metadata Author</p>
            <div className="map_info">
              <ul>
                <p><img src={logo} className="circle-image" /></p>
              </ul>
              <p> Green Legacy Initiative(GLI)</p>
            </div>
            <hr />
          </div>
        </div>
      </div>
      </div>
    
      <div id="container_click_popup_selected_region"className="container_click_popup_selected_region">
        <div className="close-icon" id="close-icon"onClick={container_click_popup_selected_region}> &times;</div>
        <div id="container_click_popup_selected_region-content">
          <div id="container_click_popup_selected_region-region"className="container_list"></div><hr/>
          <div id="container_click_popup_selected_region-zone" className="container_list"></div>
          <div id="container_click_popup_selected_region-wereda" className="container_list"></div>
          <div id="container_click_popup_selected_region-kebele"className="container_list"></div>
          <div id="container_click_popup_selected_region-site"className="container_list"></div><hr/>
          <div id="container_click_popup_selected_region-area"className="container_list"></div><br/>
          <div id="container_click_popup_selected_region-image"></div>

        </div><hr />
        {/* <div id="container_click_popup_selected_region-target"></div>
        <div id="container_click_popup_selected_region-planted"></div> */}

      </div>
    </>
  );
};

export default Years_map;
