import React, { useState } from "react";
import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import {createFromIconfontCN} from '@ant-design/icons'
import { AnnualDtata } from "../data/Annual";
import Annual_map from "../components/annual/Annual_map";
import Years_map from "../components/annual/years_map_data";

const Annual = () => {
  const [showdata, setdata] = useState(false);
  const [clickedData, setClickedData] = useState(null);
  const handleClick = (item) => {
    setdata(true);
    setClickedData(item);
    var coordinatesElement = document.getElementById("container_map");
    coordinatesElement.style.display ="none" ;
  };

  return (
    <>
      <div id="content">
        <div className="row" >
          <div className="col-lg-2">
          <h4>Years</h4>

            <div className="Region_sidebar">
              
              {AnnualDtata.map((item, index) => {
                // console.log("dddddd", item);
                return (
                  <div key={index}>
                     <ul>
                        <li  onClick={(e) => handleClick(item)}><span > {item.title}</span></li>
                      </ul>
                  </div>
                );
              })}
            </div>
          </div>
          <div className=" col-lg-10">
              <div id="container_map">
                 <Years_map/>
              </div>
            {showdata && <Annual_map data={clickedData} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Annual;
