import React, { useState } from "react";
import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import { RegionData } from "../data/RegionData";
import Region_map from "../components/region/Regional_map";
import Regions_map from "../components/region/Regions_map";

const Regional_data = () => {
  const [showdata, setdata] = useState(false);
  const [clickedData, setClickedData] = useState(null);
  const handleClick = (item) => {
    setdata(true);
    setClickedData(item);
    var coordinatesElement = document.getElementById("container_map");
    coordinatesElement.style.display = "none";
  };

  return (
    <>
      <div id="content" >
        <div className="row">
          <div className="col-lg-2 Region_sidebar_region">
          <div className="Region_sidebar_region">

          <h4 >Regions</h4>

            <div className="Region_sidebar" >
              <div className="Region_sidebar1">
              {RegionData.map((item, index) => {
                return (
                  <div key={index} >
                    <ul>
                      <li onClick={(e) => handleClick(item)}>
                        <span> {item.title}</span>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
            </div>
            </div>

          </div>
          <div className=" col-lg-10">
            <div id="container_map">
              <Regions_map />
            </div>

            {showdata && <Region_map data={clickedData} />}
          </div>
        </div>

      </div>
    </>
  );
};

export default Regional_data;
