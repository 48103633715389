import {
    ADD_REGIONAL_DATA_REQUEST,
    ADD_REGIONAL_DATA_SUCCESS,
    ADD_REGIONAL_DATA_FAILURE,

    FETCH_REGIONS_REQUEST,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_FAILURE,
    DELETE_REGION_SUCCESS,
    UPDATE_REGION_SUCCESS,
  } from '../../actions/regionalAdmin';
  
  const initialState = {
    regions: [],
    loading: false,
    error: null,
  };
  
  const regionReducer = (state = initialState, action) => {
    switch (action.type) {

      case FETCH_REGIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        regions: action.payload,
      };
    case FETCH_REGIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_REGION_SUCCESS:
      return {
        ...state,
        regions: state.regions.filter((region) => region.id !== action.payload),
      };
    case UPDATE_REGION_SUCCESS:
      return {
        ...state,
        regions: state.regions.map((region) =>
          region.id === action.payload.id ? action.payload : region
        ),
      };


      
      case ADD_REGIONAL_DATA_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
        case ADD_REGIONAL_DATA_SUCCESS:
            return {
              ...state,
              successMessage: action.payload,
              errorMessage: '',
            };
        case ADD_REGIONAL_DATA_FAILURE:
            return {
              ...state,
              successMessage: '',
              errorMessage: action.payload,
            };
      default:
        return state;
    }
  };
  
  export default regionReducer;
  