import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchImage } from "../actions/media";
import Login from "./Login";
import Logo from '../asset/images/green_legacy_logo.png';

const Unauthorized = () => {

  return (
    <div style={{ textAlign: 'center' }}>
      <h3>Unauthorized User</h3>
     <a href="./login"><img src={Logo} className="loginImageLogo" width={"250px"}/></a> 
    </div>
  );
};

export default Unauthorized;
