import {


  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
 
} from '../actions/oneDayLastHoure';

const initialState = {
  loading: false,
  lastHoure: [],

  error: null,
  value: {
    planted: "",
  },

  successMessage: "",
  errorMessage: "",
};

const OneDayLastHoureReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        lastHoure: action.payload,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      return {
        ...state,
        successMessage: '',
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default OneDayLastHoureReducer;
