import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Metadata_pie_chart from "../components/dataSet/metadata_pie_chart";
import ReactDOM from "react-dom";
import Annual_chart from "../components/annual/Annual_chart";
const Dataset = () => {
  const [showdata, setdata] = useState(false);
  const [clickedData, setClickedData] = useState(null);
  const [metadatas, setMetadatas] = useState([]);
  const handleYearChange = (item) => {
    let componentToRender;
    switch (item) {
      case "chart":
        componentToRender = <Annual_chart />;
        break;
      case "pie chart":
        componentToRender = <Metadata_pie_chart />;
        break;
        }

    ReactDOM.render(componentToRender, document.getElementById("metadata"));
  };

  return (
    <>
      <div className="row"  style={{marginTop:"100px"}}>
        <div className="col-lg-2">
        <h3 style={{ fontSize: "18px", fontWeight: "bold" }}> Select View</h3>

        <div className="Region_sidebar">
            <div className="upload_sideNav">
                <ul>
                   <li onClick={() => handleYearChange('chart')}><span>Annual Dataset</span></li><br/>
                   <li onClick={() => handleYearChange('pie chart')}><span>Regions Dataset</span></li>
                </ul>
            </div>
          </div>
        </div>
        <div className="col-10">
          <div id="metadata">
            <Metadata_pie_chart />
          </div>
          {showdata && <Annual_chart data={clickedData} />}
        </div>
      </div>
    </>
  );
};

export default Dataset;
