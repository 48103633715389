// src/redux/actions/metadataActions.js
import axios from "axios";
export const FETCH_METADATA_REQUEST = 'FETCH_METADATA_REQUEST';
export const FETCH_METADATA_SUCCESS = 'FETCH_METADATA_SUCCESS';
export const FETCH_METADATA_FAILURE = 'FETCH_METADATA_FAILURE';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
const Server_URL = process.env.REACT_APP_SERVER_URL;

export const fetchMetadataRequest = () => ({
  type: FETCH_METADATA_REQUEST,
});
export const fetchUsersRequest = () => ({
    type: FETCH_USERS_REQUEST,
  });
export const fetchMetadataSuccess = (data) => ({
  type: FETCH_METADATA_SUCCESS,
  payload: data,
});

export const fetchMetadataFailure = (error) => ({
  type: FETCH_METADATA_FAILURE,
  payload: error,
});
export const fetchUsersSuccess = (users) => ({
    type: FETCH_USERS_SUCCESS,
    payload: users,
  });
  
  export const fetchUsersFailure = (error) => ({
    type: FETCH_USERS_FAILURE,
    payload: error,
  });

export const fetchMetadata = () => {
  return async (dispatch) => {
    dispatch(fetchMetadataRequest());
    try {
      const response = await axios.get(`${Server_URL}/annual_data`);
      dispatch(fetchMetadataSuccess(response.data));
    } catch (error) {
      dispatch(fetchMetadataFailure(error.message));
    }
  };
};
export const fetchMetadata1 = () => async (dispatch) => {
    dispatch(fetchUsersRequest());
    try {
      const response = await axios.get(`${Server_URL}/annual_data`);
      dispatch(fetchUsersSuccess(response.data));
    } catch (error) {
      dispatch(fetchUsersFailure(error.message));
    }
  };

    export const getMetadata =()=> async (dispatch) => {
      try {
        const response = await axios.get(`${Server_URL}/annual_data`);
        dispatch(fetchMetadataSuccess(response.data));
      } catch (error) {
        dispatch(fetchUsersFailure(error.message));
      }
    
    };

    getMetadata();
